import React, { useEffect, useState } from "react";


import Header from "../../../../components/layout/header";
import Footer from "../../../../components/layout/footer";
import { Service } from "../../../../services/api";
import Hero from "../../../../components/TopBanner/Hero";
import FooterBanner from "../../../../components/FooterBanner/FooterBanner";
import Renderif from "../../../../components/helpers/renderif";
import SessionCard from "../../../../components/SessionCard/SessionCard";
import Advertisement from "../../../../components/Advertisement/Advertisement";
import ChatAiIcon from "../../../../components/chatai/ChatAiIcon";



export default function HomeVisualizationCenter() {


    const [state, setState] = useState({
        loading: true,
        cursos: [],
        show_chatia: false
    })

    useEffect(() => {
        init();
        return () => { }
    }, [])

    // function to jump straight to room.
    // async function joinRoom(session) {
    //     let activity = session.activities[0].activity
    //     if (activity?.webinars?.length > 0) {
    //         let room_name = activity.webinars[0].webinarUrlRoom.split("/")[3]
    //         let body = {
    //             roomId: room_name,
    //             webinarId: activity?.webinars[0].id
    //         }
    //         let _token = await Service('POST', `dailyvideo/generate-token`, body)
    //         navigate(`/room?roomUrl=${activity?.webinars[0].webinarUrlRoom}`, { state: { sessionData: session, token: _token.data.token } })
    //     } else {
    //         console.log(activity?.webinars)
    //     }
    // }


    const init = async () => {

        let _cursos = await Service('GET', `course`)
        if (_cursos.status === 200) {
            state.cursos = _cursos.data
        }
        setState({ ...state, loading: false })
    }

    // return (
    //     <EventsDummy></EventsDummy>
    // )

    return (
        <div className="container-fluid d-flex flex-column overflow-y-auto hide-scroll h-100">

            <Header />
            <Hero />

            <div className="container my-5 text-center f-26 text-gray opacity-50">
                Visualization Center
            </div>

            <div className="mt-4 container">
                <Renderif isTrue={state.loading}>
                    <div className="d-flex justify-content-center text-primary">
                        <div className="spinner">
                            <div className="spinner-border"></div>
                        </div>
                    </div>
                </Renderif>

                <Renderif isTrue={!state.loading}>
                    {state.cursos.map((curso, i) => {
                        if (curso.id === "7c3497c1-6b37-400c-a008-0bfa20049954") return (null) //Not displaying the events course.
                        return (
                            <div key={curso.id} className="row">
                                <div className="mb-4 ps-1">
                                    <div className="f-16 bold text-center login-font-color">{curso?.name}</div>
                                    {/* <div className="f-16 text-gray line-1">{curso?.description}</div> */}
                                </div>


                                {curso?.sessions.map((session, i) => {

                                    return (
                                        <SessionCard key={session.id} session={session} curso={curso} />
                                    )
                                })}

                                <div className="my-4"></div>
                            </div>

                        )
                    })}
                </Renderif>


            </div>

            <Advertisement />
            <FooterBanner />
            <Footer></Footer>


            <ChatAiIcon />

            {/* <ChatAI show={state.show_chatia} onClose={() => {
                setState({ ...state, show_chatia: false })
            }}></ChatAI>

            <div className="cta-chatai cursor-pointer" onClick={() => {
                setState({ ...state, show_chatia: true })
            }}>
                <ChatAIcon></ChatAIcon>
            </div> */}


            {/* <Popupchat />
            <Appfooter />  */}
        </div>
    );
}


export function checkIfWebinarLive(session) {
    const webinar = session.activities[0].activity.webinars[0]
    if(!webinar) return false
    const { startDate, endDate } = webinar
    const currentDate = new Date().getTime()
    if (currentDate < new Date(startDate).getTime() || currentDate > new Date(endDate).getTime()) {
        return false
    }
    return true
}


export function ChatAIcon() {
    return (
        <div className="rainbow-container">
            <div className="green"></div>
            <div className="pink"></div>
            <div className="blue"></div>
        </div>

    )
}



