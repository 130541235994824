import React from 'react'
import "./Cart.css"
import { useCart } from '../../context/cart_context'
import Renderif from '../helpers/renderif';
// import axios from 'axios';

const CartInner = (props) => {
    const { clearCartPrompt } = props
    const { getTotalCartValue, removeFromCart, cart, getTotalItems, checkOut, loading } = useCart();

    // const checkOut = async () => {
    //     // Creating an order on our backend
    //     const _token = localStorage.getItem('token')
    //     try {
    //         let res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/orders`, {}, {
    //             headers: {
    //                 Authorization: `Bearer ${_token}`
    //             }
    //         })
    //         console.log("order sccessfully created")
    //         const { orderId, products, amount, userId } = res.data

    //         // creating a checkout session
    //         res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/payment`, {
    //             orderId,
    //             userId,
    //             products: products.map((product) => {
    //                 return {
    //                     id: product.id,
    //                     price: product.price,
    //                     thumbnail : product.thumbnail,
    //                     quantity : 1,
    //                     name : product.name
    //                 }
    //             })
    //         }, {
    //             headers: {
    //                 Authorization: `Bearer ${_token}`
    //             }
    //         })
    //         const {url} = res.data
    //         window.location.href = url            
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }

    return (
        <div className=''>
            <div class="aside-content cart-drawer-items-list">
                <Renderif isTrue={getTotalItems() !== 0}>
                    <div onClick={() => {
                        clearCartPrompt();
                    }} className='d-flex justify-content-end me-2 text-gray cursor-pointer'>clear</div>
                </Renderif>
                <ul class="offcanvas-cart">
                    {cart.map((item) => {
                        return (
                            <li key={item.id}> <a className='images-cart' href="#"> <img src={`${item.thumbnail}`} alt="" /></a>
                                <div className='flex-grow-1'>
                                    <h6 class="mb-0">{item.name}</h6>
                                    <p>$ {item.price ? item.price : 0}</p>

                                </div><i class="iconsax delete-icon cursor-pointer" data-icon="trash" onClick={() => {
                                    removeFromCart(item)
                                }}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M18.85 9.14001L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79002C6.00002 22 5.91002 20.78 5.80002 19.21L5.15002 9.14001" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M10.33 16.5H13.66" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                        <path d="M9.5 12.5H14.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    </svg>
                                </i>
                            </li>
                        )
                    })}

                    {/* <li> <a className='images-cart' href="#"> <img src="https://coaching2100.com/images/25.jpg" alt="" /></a>
                        <div>
                            <h6 class="mb-0">Product Name</h6>
                            <p>$35</p>

                        </div><i class="iconsax delete-icon" data-icon="trash"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M18.85 9.14001L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79002C6.00002 22 5.91002 20.78 5.80002 19.21L5.15002 9.14001" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M10.33 16.5H13.66" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M9.5 12.5H14.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        </i>
                    </li>

                    <li> <a className='images-cart' href="#"> <img src="https://coaching2100.com/images/25.jpg" alt="" /></a>
                        <div>
                            <h6 class="mb-0">Product Name</h6>
                            <p>$35</p>

                        </div><i class="iconsax delete-icon" data-icon="trash"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M18.85 9.14001L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79002C6.00002 22 5.91002 20.78 5.80002 19.21L5.15002 9.14001" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M10.33 16.5H13.66" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M9.5 12.5H14.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        </i>
                    </li> */}
                </ul>

            </div>

            <div class="offcanvas-footer">
                <div class="price-box">
                    <h6>Total :</h6>
                    <p>$ {getTotalCartValue()} USD</p>
                </div>
                <button class="cart-button w-100" disabled={loading} onClick={checkOut}> <span class="btn btn_black" > {loading ? '...':'Checkout'}</span></button>
            </div>

        </div>
    )
}

function Cart(props) {
    const { clearCart } = props
    return (
        <>
            <CartInner clearCartPrompt={clearCart} />
        </>
    )
}

export default Cart