import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Renderif from "../helpers/renderif";
import ReplyBox from "./ReplyBox";
import { AuthContext } from "../../context/auth_context";
import { AiOutlineDelete } from "react-icons/ai";
import axios from "axios";
import { AiTwotoneEdit } from "react-icons/ai";
import { format } from "@formkit/tempo";
import { BsReply } from "react-icons/bs";

const LikeCounter = React.lazy(() => import("./Like/Like"));

const Comment = ({ comment, sessionId, refreshComments }) => {
  const {author} = comment
  // const [isInView, setIsInView] = useState(false);
  const likeCounterRef = useRef(null);
  const [replies, setReplies] = useState(comment.replies || []);
  // console.log(comment)

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting) {
  //         setIsInView(true);
  //         observer.disconnect(); // Stop observing after it's in view
  //       }
  //     },
  //     { threshold: 0.1 } // Trigger when 10% of the component is in view
  //   );

  //   if (likeCounterRef.current) {
  //     observer.observe(likeCounterRef.current);
  //   }

  //   return () => observer.disconnect();
  // }, []);

  const [showReplyBox, setShowReplyBox] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newContent, setNewContent] = useState(comment.content);
  const { user } = useContext(AuthContext);

  const fetchReplies = useCallback(async () => {
    // Fetch replies for the comment
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/comment/${comment.id}/replies`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setReplies(res.data);
    } catch (err) {
      console.log("Error fetching replies: ", err);
    }
  }, [comment.id]);

  const refreshReplies = () => {
    fetchReplies();
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const updateComment = async () => {
    // Update comment in the database
    const token = localStorage.getItem("token");
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/v1/comment/${comment.id}`,
        { content: newContent },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      console.log("Error updating comment: ", err);
    } finally {
      refreshComments(); // Refresh comments after update
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setNewContent(comment.content); // Reset to original content
  };

  const handleSaveClick = () => {
    updateComment(); // Call update function from parent
    setIsEditing(false); // Exit edit mode
  };

  const handleDeleteComment = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/comment/${comment.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      console.log("Error deleting comment: ", err);
    } finally {
      refreshComments(); // Refresh comments after delete
    }
  };

  return (
    <div className="d-flex mt-3 page-load">
      <div className="avatar comment-avatar">
        <div className="participant-avatar">
          {/* <img
            src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=100&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            style={{
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
              height: "100%",
            }}
          /> */}

          {/* we dont have an avatar right now */}
          <div className="d-flex w-100 h-100 justify-content-center align-items-center">{author.firstName.slice(0, 1)}</div>
        </div>
      </div>
      <div className="col mt-2 px-2">
        <div className="d-flex align-items-center">
          <div className="bold me-2">{`${author.firstName} ${author.lastName}`}</div>
          <div className="f-14">
            {format(comment.updatedAt, "HH:mm A, DD MMM")}
          </div>
        </div>
        {isEditing ? (
          <div>
            <textarea
              value={newContent}
              onChange={(e) => setNewContent(e.target.value)}
              rows={3}
              className="w-100 py-1 px-2 bg-gray-00 rounded-2 hide-scroll"
              style={{ border: "0px", outline: "0px", minHeight: "4rem" }}
            />
            <div>
              <button onClick={handleSaveClick}>Save</button>
              <button onClick={handleCancelClick}>Cancel</button>
            </div>
          </div>
        ) : (
          <div>{comment.content}</div>
        )}

        {/* Reply Box */}

        <div className="d-flex align-items-center">
          <div ref={likeCounterRef}>
            <Suspense fallback={<div>---</div>}>
              <LikeCounter commentId={comment.id} />
            </Suspense>
          </div>

          <div className="mx-3">
            <div
              className="cursor-pointer text-dark-gray bold"
              onClick={() => {
                fetchReplies();
              }}
            >
              {`Replies(${comment._count.replies})`}
            </div>
          </div>
          <div
            onClick={() => {
              setShowReplyBox(!showReplyBox);
            }}
            className="bv-icon bv-icon--mid login-font-color cursor-pointer rounded-1"
          >
            <BsReply />
          </div>
          <Renderif isTrue={author.id === user.id}>
          <div
            onClick={handleEditClick}
            className="bv-icon bv-icon--mid login-font-color cursor-pointer rounded-1"
          >
            <AiTwotoneEdit />
          </div>
          <div
            onClick={handleDeleteComment}
            className="bv-icon bv-icon--mid login-font-color cursor-pointer rounded-1"
          >
            <AiOutlineDelete />
          </div>
          </Renderif>
          
        </div>
        <Renderif isTrue={showReplyBox}>
          <ReplyBox
            parentId={comment.id}
            sessionId={sessionId}
            userId={user.id}
            onReplyPosted={() => {
              setShowReplyBox(false);
              refreshReplies();
            }}
          />
        </Renderif>

        {/* Rendering replies */}
        <Renderif isTrue={replies}>
          {replies.map((reply) => {
            return (
              // <div key={reply.id} className="d-flex mt-3">
              //   <div className="avatar comment-avatar">
              //     <div className="participant-avatar">
              //       <img
              //         src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=100&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              //         style={{
              //           objectFit: "cover",
              //           objectPosition: "center",
              //           width: "100%",
              //           height: "100%",
              //         }}
              //       />
              //     </div>
              //   </div>
              //   <div className="col mt-2 px-2">
              //     <div className="d-flex align-items-center">
              //       <div className="bold me-2">Nikki Cloe</div>
              //       <div>08:10 AM</div>
              //     </div>

              //     <div>{reply.content}</div>
              //     {/* Reply Box */}
              //     {/* <div className="d-flex align-items-center">
              //   <div className="bv-icon bv-icon--mid login-font-color cursor-pointer rounded-1">
              //     <IoMdHeart />
              //   </div>
              //   <div className="mx-3">
              //     <div className="cursor-pointer text-decoration-underline text-dark-gray bold">
              //       Reply
              //     </div>
              //   </div>
              // </div> */}
              //     {/* Rendering replies */}
              //     {/* <Renderif isTrue={comment.replies}>
              //   {comment.replies.map((reply) => {})}
              // </Renderif> */}
              //   </div>
              // </div>
              <Comment
                comment={reply}
                key={reply.id}
                sessionId={sessionId}
                refreshComments={refreshReplies}
              />
            );
          })}
        </Renderif>
      </div>
    </div>
  );
};

export default Comment;
