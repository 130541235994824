import React from "react";
import { FaRegCircle } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";
import Renderif from "../helpers/renderif";

const FeedbackOptions = ({ selectedOptions, onChange }) => {
  const options = [
    "Loading speed",
    "Ease of use",
    "Browsing experience",
    "Product price",
    "Other, please specify",
  ];

  const toggleOption = (option) => {
    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((opt) => opt !== option)
      : [...selectedOptions, option];
    onChange(updatedOptions);
  };

  return (
    <div>
      {options.map((item, i) => (
        <div
          key={i}
          className={`tile-opt mt-3 ${selectedOptions.includes(item) ? "" : ""} bg-light border-0 tag`}
          onClick={() => toggleOption(item)}
        >
          <div className="col">
            <div className="f-16 text-gray ps-2 bold">{item}</div>
          </div>
          <div className="bv-icon bv-icon--mid">
            <Renderif isTrue={!selectedOptions.includes(item)}>
            <div style={{border : '1px solid gray', width : '22px', height : '22px', borderRadius : '999px'}}></div>
            </Renderif>
            <Renderif isTrue={selectedOptions.includes(item)}>
              {/* <FaCircleCheck size={30} color="#00b1bb" /> */}
              <div style={{backgroundColor : '#0bb1bb', width : '17px', height : '17px', borderRadius : '999px', outline : '1px solid gray', outlineOffset : '2px'}}></div>
            </Renderif>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeedbackOptions;
