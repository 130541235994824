import React, {  useEffect, useState } from "react";


import { useNavigate } from "react-router-dom";



import Header from "../../../../components/layout/header";
import Footer from "../../../../components/layout/footer";
import Renderif from "../../../../components/helpers/renderif";
import { BsCheckSquareFill, BsSquare } from "react-icons/bs";
import {  MdKeyboardArrowDown } from "react-icons/md";
import { Service } from "../../../../services/api";
import { useCart } from "../../../../context/cart_context";
import FooterBanner from "../../../../components/FooterBanner/FooterBanner";
import Advertisement from "../../../../components/Advertisement/Advertisement";




export default function HomeTalenscope() {

    const navigate = useNavigate()
    const { addToCart } = useCart()

    const [state, setState] = useState({
        loading: true,
        open_filter: false,
        products: [],
        tab_active: 0
    })

    useEffect(() => {
        init()
        return () => { }
    }, [])

    const init = async () => {

        setState({ ...state, loading: true })
        let _products = await Service('GET', 'products')
        if (_products.status === 200) {
            setState({ ...state, products: _products.data, loading: false })
        }
    }

    const filter = () => {

        switch (state.tab_active) {
            case 0:
                return state.products.filter((product) => product.category.some((cat) => cat.name === 'AI Assistants'))
            case 1:
                return state.products.filter((product) => product.category.some((cat) => cat.name === 'Agencies'))
            case 2:
                return state.products.filter((product) => product.category.some((cat) => cat.name === 'AI Native Products'))
            case 3:
                return state.products.filter((product) => product.category.some((cat) => cat.name === 'Coaches'))
            case 4:
                return state.products.filter((product) => product.category.some((cat) => cat.name === 'Vision Labs'))
            default:
                return state.products;
        }


    }


    // const values_1 = [
    //     { id: 0, name: 'AI Assistant' },
    //     { id: 1, name: 'Human Capital' },
    //     { id: 2, name: 'Webinars' },
    // ]
    // const values_2 = [
    //     { id: 0, name: 'Coaching' },
    //     { id: 1, name: 'Speakers' },
    //     { id: 2, name: 'Consulting & Advisory' },
    // ]
    // const values_3 = [
    //     { id: 0, name: '5 Stars' },
    //     { id: 1, name: '4 Stars' },
    //     { id: 2, name: '3 Stars' },

    // ]


    return (
        <div className="container-fluid d-flex flex-column overflow-y-auto hide-scroll h-100">

            {/* <div className={`page-loader ${!state.loading ? 'opacity-0':''}`}></div> */}

            {/* <Header />
            <Leftnav /> */}
            {/* <Rightchat /> */}

            <Header />

            {/* <Hero /> */}

            <div className="container-fluid container-xl mx-auto">


                {/* <div className="d-flex d-sm-none border">
                    <div className="col"></div>
                    <div className="bv-icon bv-icon--md cursor-pointer" onClick={() => {
                        setState({ ...state, open_filter: true })
                    }}>
                        <MdFilterList size={30}></MdFilterList>
                    </div>
                </div> */}



                <div className="d-flex mt-0 mt-sm-5">

                    <Renderif isTrue={state.open_filter}>

                        {/* <div className="talentscope-filters p-3 mb-3 d-block d-sm-none">

                            <div className="d-flex align-items-center cursor-pointer">
                                <div className="bv-icon bv-icon--md" onClick={() => {
                                    setState({ ...state, open_filter: false })
                                }}>
                                    <CgClose color="#294C53" size={30}></CgClose>
                                </div>
                                <div className="col"></div>
                                <div className="f-14 text-dark-green cursor-pointer">{'Clear all'}</div>

                            </div>

                            <Filter text="Categories" values={values_1}></Filter>


                            <Filter text="Partner Network" values={values_2}></Filter>


                            <Filter text="Rating" values={values_3}></Filter>
                        </div> */}

                    </Renderif>
                    {/* <div className="talentscope-filters p-3 mb-3 d-none d-sm-block">

                        <div className="d-flex align-items-center cursor-pointer">
                            <div className="f-14 bolder text-dark-green">{'Filters'}</div>
                            <div className="col"></div>
                            <div className="f-14 text-dark-green cursor-pointer">{'Clear all'}</div>

                        </div>

                        <Filter text="Categories" values={values_1}></Filter>


                        <Filter text="Partner Network" values={values_2}></Filter>


                        <Filter text="Rating" values={values_3}></Filter>
                    </div> */}


                    <div className="col ps-3">

                        <Renderif isTrue={state.loading && filter().length === 0}>
                            <div className='spinner d-flex w-100 mt-4 justify-content-center'><div className="spinner-border"></div></div>
                        </Renderif>

                        <Renderif isTrue={filter().length === 0 && !state.loading}>
                            <div className="px-2 mt-3 f-14 text-gray" >No results found</div>
                        </Renderif>
                        <Renderif isTrue={!state.loading}>
                            <div className="mt-3 mb-5">
                                <div className={`bv-tab  ${state.tab_active === 0 ? 'cta-bg' : ''} tag`} onClick={() => { setState({ ...state, tab_active: 0 }) }}>AI Assistants</div>
                                <div className={`bv-tab  ${state.tab_active === 1 ? 'cta-bg' : ''} tag`} onClick={() => { setState({ ...state, tab_active: 1 }) }}>Agencies</div>
                                <div className={`bv-tab  ${state.tab_active === 2 ? 'cta-bg' : ''} tag`} onClick={() => { setState({ ...state, tab_active: 2 }) }}>AI Native Products</div>
                                <div className={`bv-tab  ${state.tab_active === 3 ? 'cta-bg' : ''} tag`} onClick={() => { setState({ ...state, tab_active: 3 }) }}>Coaches</div>
                                <div className={`bv-tab  ${state.tab_active === 4 ? 'cta-bg' : ''} tag`} onClick={() => { setState({ ...state, tab_active: 4 }) }}>Vision Labs</div>
                                <div className={`bv-tab  ${state.tab_active === 5 ? 'cta-bg' : ''} tag`} onClick={() => { setState({ ...state, tab_active: 5 }) }}>All</div>
                            </div>
                        </Renderif>
                        <div className="d-flex flex-wrap mt-3">
                            {filter().map((item, i) => {
                                // let _color = rcolor()
                                return (
                                    <div key={item.id} className="col-12 col-md-6 col-xl-4 pe-3 pb-3">
                                        <div className="product-card">
                                            <div className="d-flex">
                                                <div className="bv-icon bv-icon--big product-card-image overflow-hidden">
                                                    {/* <SiSinglestore size={40} color={_color}></SiSinglestore> */}
                                                    <img width="100%" className="img-fit-content" src={item?.thumbnail}></img>
                                                </div>
                                            </div>
                                            <div className="d-flex ps-1">
                                                <div className="f-20 text-dark-green bolder ">{item.name}</div>
                                            </div>
                                            {/* <div className="d-flex align-items-center">
                                                <div className="bv-icon">
                                                    <FiMapPin></FiMapPin>
                                                </div>
                                                <div className="f-14 ">Location</div>
                                            </div> */}

                                            <div className="d-flex flex-wrap my-3 gap-2">
                                                {item?.category.map((cat, i) => {
                                                    return (
                                                        <div key={cat.id} className="login-cta-border tag f-12 px-2 py-0.5">{cat.name}</div>
                                                    )
                                                })}
                                            </div>
                                            <div className="d-flex ps-1 card-desc">
                                                <div className="f-14 text-gray">{item.description}</div>
                                            </div>
                                            <div className="d-flex ps-1 mt-2">
                                                <div className="f-16 text-gray ">Price : </div>
                                                <div className="f-16 ms-2">$ {item.price}</div>
                                            </div>
                                            <div className="d-flex mt-4">


                                                <div className='' onClick={() => { navigate(`/product/${item.id}`) }}>
                                                    <div className='tag login-cta-border cursor-pointer px-3 py-1 f-14' onClick={() => {
                                                        // navigate('/home')
                                                    }}>
                                                        <div>Details</div>
                                                    </div>
                                                </div>

                                                <div className='ms-2'>
                                                    <div className='tag login-cta-border cta-bg cursor-pointer px-3 py-1 f-14' onClick={() => {
                                                        // navigate('/home')
                                                        addToCart(item);
                                                    }}>
                                                        <div>Add to Cart</div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                )
                            })}



                        </div>
                    </div>
                </div>


            </div>

            <div className="my-5"></div>

            <Advertisement />
            <FooterBanner />
            <Footer></Footer>

            {/* <Renderif isTrue={user}>
                <ChatAI show={state.show_chatia} onClose={() => {
                    setState({ ...state, show_chatia: false })
                }}></ChatAI>

                <div className="cta-chatai cursor-pointer" onClick={() => {
                    setState({ ...state, show_chatia: true })
                }}>
                    <ChatAIcon></ChatAIcon>
                </div>
            </Renderif> */}
        </div>
    );
}

const Checkbox = ({ checked = false, text = "", onChange = () => { } }) => {

    const [state, setSatate] = useState({
        checked
    })

    const _onChange = () => {
        setSatate({ ...state, checked: !state.checked })
        onChange(state.checked)
    }

    return (
        <div className='d-flex cursor-pointer align-items-start flex-row w-content me-3 ' onClick={() => {
            _onChange()
        }}>

            <div>
                <div className='bv-icon'>
                    <Renderif isTrue={state.checked}>
                        <BsCheckSquareFill color='#294C53' size={30}></BsCheckSquareFill>
                    </Renderif>
                    <Renderif isTrue={!state.checked}>
                        <BsSquare color="gray" size={30}></BsSquare>
                    </Renderif>

                </div>
            </div>
            <div>
                <div className='text-gray f-16 line-1 ps-1'>{text}</div>
            </div>
        </div>

    )
}

const Filter = ({ text = "asd", values = [] }) => {



    const [state, setState] = useState({
        open: true,
        selected: []

        // values:[]
    })



    return (
        <div className="d-flex flex-column b-top pt-2 mt-3">

            <div className="d-flex align-items-center cursor-pointer" onClick={() => {
                setState({ ...state, open: !state.open })
            }}>
                <div className="f-14 bolder text-dark-green">{text}</div>
                <div className="col"></div>
                <div className="bv-icon bv-icon--mid" >
                    <MdKeyboardArrowDown size={30}></MdKeyboardArrowDown>
                </div>
            </div>
            <Renderif isTrue={state.open}>
                <div>
                    {values.map((item, i) => {
                        // console.log(item)
                        return (
                            <div key={item.id} className="mt-3">
                                <Checkbox text={item.name}></Checkbox>
                            </div>
                        )
                    })}
                </div>
            </Renderif>


        </div>
    )
}
