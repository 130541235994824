import React from "react";

const FeedbackTextarea = ({ value, onChange, maxLength = 500 }) => (
  <div className="mt-3">
    <textarea
      className="input--light py-2"
      rows={5}
      maxLength={maxLength}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Enter additional feedback here..."
    />
  </div>
);

export default FeedbackTextarea;
