import React, { useCallback, useState } from "react";
import FeedbackOptions from "./FeedbackOptions";
import RatingSelector from "./RatingSelector";
import FeedbackTextarea from "./FeedbackTextArea";
import axios from "axios";
import Renderif from "../helpers/renderif";

const CollaborationForm = () => {
  const [feedbackOptions, setFeedbackOptions] = useState([]);
  const [additionalFeedback, setAdditionalFeedback] = useState("");
  const [userFriendlyRating, setUserFriendlyRating] = useState(-1);
  const [stage, setStage] = useState(-1);

  const submitData = useCallback(async (data) => {
    const _token = localStorage.getItem("token");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/collaboration`,
        {
          feedbackOptions: data.feedbackOptions,
          additionalFeedback: data.additionalFeedback,
          userFriendlyRating: data.userFriendlyRating,
        },
        {
          headers: {
            Authorization: `Bearer ${_token}`,
          },
        }
      );
      setStage(0)
    } catch (err) {
      console.error("Error submitting collaboration data:", err);
    }
  }, []);

  const handleSubmit = () => {
    if (
      !userFriendlyRating ||
      userFriendlyRating < 0 ||
      userFriendlyRating > 10
    ) {
      alert("Please select a rating between 0 and 10.");
      return;
    }
    if (additionalFeedback.length > 500) {
      alert("Feedback must be 500 characters or fewer.");
      return;
    }

    const collaborationData = {
      feedbackOptions,
      additionalFeedback,
      userFriendlyRating,
    };
    submitData(collaborationData);
  };

  return (
    <>
      <Renderif isTrue={stage === -1}>
        <div className="my-5 container">
          {/* <div className="f-20 bold mb-3">What's the main reason for your score ?</div> */}
          <div>
            It would be lovely to know what you thought of your experience with
            the app.
          </div>
          <FeedbackOptions
            selectedOptions={feedbackOptions}
            onChange={setFeedbackOptions}
          />
          <FeedbackTextarea
            value={additionalFeedback}
            onChange={setAdditionalFeedback}
          />
          <RatingSelector
            value={userFriendlyRating}
            onChange={setUserFriendlyRating}
          />

          <div className="mt-2 text-center">
            <button
              className="cta-bg tag px-5 py-2 f-14"
              onClick={handleSubmit}
            >
              Next
            </button>
          </div>
        </div>
      </Renderif>
      <Renderif isTrue={stage === 0}>
        <div className="my-5 container text-center">
          <div>Thanks for sharing your thoughts!</div>
        </div>
      </Renderif>
    </>
  );
};

export default CollaborationForm;
