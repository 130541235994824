import React from 'react'

const ApplicationFormButton = (props) => {
    return (
        <div className='v-cta bg-gray-10 application-cta cursor-pointer p-4 backdrop-cta'
            onClick={() => {
                props.handleForm(props.formId)
            }}
            style={{ width: '100%', padding : '0.4rem' }} >
            <div className='text-center'>{props.formName}</div>
        </div>
    )
}

export default ApplicationFormButton