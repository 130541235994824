import axios from "axios";

// const api_base = process.env.REACT_APP_API_URL;
const api_base = `${process.env.REACT_APP_API_URL}/api/v1/`;


const configMedia = () =>{
    let token = localStorage.getItem('token')
    return { headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer '+token
      }}
}

const config = () =>{
    let token = localStorage.getItem('token')
    return { headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer '+token
      }}
}


export const Login = async ({email, password}) => {
    var url  = `${api_base}auth/login`

    try {
        const res = await axios.post(url, { email, password }, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return res;
    } catch (err) {
        return err;
    }
}



export const Register = (user) => {
    var url  = `${api_base}auth/signup`

    return axios.post(url,user).then((res)=>{
        //return response(res)
        return res
    }).catch((err)=>{
        return err
    });
  
}


export const Service = (method, service, body) => {
    var url  = `${api_base}${service}`
    switch (method) {
        case "GET":
      
            return axios.get(url,config()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });

            

        case "POST":
            return axios.post(url,body,config()).then((res)=>{
                return res
            }).catch((err)=>{
                return err
            });
        
        case "POSTMEDIA":

            return axios.post(url,body,configMedia()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });


           
        case "PUT":

            return axios.put(url,body,config()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });
           
            
        case "DELETE":
            
            let token = localStorage.getItem('token')
            return axios.delete(url,{
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer '+token
                },
                data:body
            }).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            });

        case "PATCH":

            return axios.patch(url,body,config()).then((res)=>{
                //return response(res)
                return res
            }).catch((err)=>{
                return err
            }); 

        default:
            break;
    }
}
