import React, { useEffect, useState } from "react";
import { FaRegCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LiveEventBanner from "./LiveEventBanner";

const LiveEvent = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const init = async () => {
      try {
        const token = localStorage.getItem("token");
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/live-event`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        // console.log(res)
        setData(res.data[0]);
      } catch (err) {
        console.log(err);
      }
    };
    init();
  }, []);

  if (!data) {
    return <></>;
  }
  return (
    <div className="bg-white rounded-3 pb-3">
      <div className="position-relative">
        <LiveEventBanner
          height={300}
          radius={2}
          mediaSource={data.backgroundLink}
          mediaType={data.contentType}
        />
        <div className="tag cta-bg position-absolute m-3 d-flex align-items-center end-0 top-0 f-12 px-3 py-1">
          <FaRegCircle className="me-2" />
          LIVE NOW
        </div>
      </div>

      <div className="position-relative p-3">
        <div>
          <div className="f-18 bold text-black text-center">{data.title}</div>
        </div>
        <div className="my-2">
          <div className="f-12 text-gray text-center">{data.description}</div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div>
          <div
            className="tag cta-bg py-2 px-1 cursor-pointer px-3 f-14"
            onClick={() => {
              window.location.href = data.url;
            }}
          >
            <div>JOIN LIVE SESSION </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveEvent;
