import React from 'react'
import Header from '../layout/header'

const Cancel = () => {
  return (
    <div className='h-100 d-flex flex-column'>
      <Header />
      <div className='flex-grow-1 d-flex justify-content-center align-items-center'>
        <div className='f-22'>
          Something went wrong
        </div>
      </div>
    </div>
  )
}

export default Cancel