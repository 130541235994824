import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../layout/header';
import axios from 'axios';
import "./orders.css"
import { ProductCard } from './orders';
import { useCart } from '../../context/cart_context';

const Success = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { clearCart } = useCart();

  const [state, setState] = useState({
    order: null,
    loading: false
  })

  if (!id) {
    navigate('/', { replace: true })
  }

  useEffect(() => {
    const fetchOrder = async () => {
      setState((prevState) => ({
        ...prevState,
        loading: true
      }))
      try {
        let token = localStorage.getItem('token')
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/orders/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        setState((prevState) => ({
          ...prevState,
          order: res.data
        }))
        if (res.data.paymentStatus === 'SUCCESS') {
          clearCart();
        }

      } catch (err) {
        console.log("err fetching product", err)
        navigate('/orders', { replace: true })
      }
      finally {
        setState((prevState) => ({
          ...prevState,
          loading: false
        }))
      }
    }
    fetchOrder();

  }, [id])


  if (state.loading) {
    return (
      <div className='h-100 container d-flex align-items-center justify-content-center mx-auto'>
        <div className='spinner'><div className="spinner-border"></div></div>
      </div>
    )
  }

  if (state.order && !state.loading) {
    const date = new Date(state.order.updatedAt).toLocaleDateString()
    const time = new Date(state.order.updatedAt).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    })

    if (state.order.paymentStatus !== 'SUCCESS') {
      navigate('/failed', { replace: true })
      return
    }

    return (
      <>
        <Header />
        <div className='container mx-auto d-flex hide-scroll py-4'>
          {/* <div className='f-22'>
            <strong className='text-gray'>orderid</strong>: <strong className='text-dark'>{state.order.orderId}</strong>
            <div className='d-flex justify-content-between'>
              <span>{new Date(state.order.updatedAt).toLocaleDateString()}</span>
              <span>{new Date(state.order.updatedAt).toLocaleTimeString()}</span>
            </div>
            <div className='text-center'>
              payment successful
            </div>
          </div> */}
          <div className='order-container container'>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='mb-3'>Your order was placed successfully</div>
              <span className={`v-tag ${state.order.paymentStatus === 'SUCCESS' ? 'bg-green text-white' : state.order.paymentStatus === 'FAILED' ? 'bg-red text-white' : ''}`}>{state.order.paymentStatus}</span>            </div>
            <div>orderId : {state.order.orderId}</div>
            <div>Total : $ {state.order.amount}</div>
            <div>Date : {date}</div>
            <div>Time : {time}</div>
            <div>{state.order.products.map((product) => <ProductCard item={product} key={product.id} status={state.order.paymentStatus}/>)}</div>
          </div>

        </div>
      </>
    )
  }


}

export default Success