import React, { useState } from "react";
import axios from "axios";

const ReplyBox = ({ parentId, sessionId, userId, onReplyPosted }) => {
  const [content, setContent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  const handleReplySubmit = async () => {
    if (!content.trim()) return; // Don't allow empty replies

    setIsSubmitting(true);

    const _token = localStorage.getItem("token");

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/comment`,
        {
          content,
          sessionId,
          authorId: userId,
          parentId, // Set the parentId to the comment being replied to from params
        },
        {
          headers: {
            Authorization: `Bearer ${_token}`,
          },
        }
      );

      setContent(""); // Clear the input field
      onReplyPosted(res.data); // Trigger a callback to refresh comments
    } catch (err) {
      setError("Failed to post reply");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div style={{ marginLeft: "20px", marginTop: "10px" }}>
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Write a reply..."
        rows={3}
        className="w-100 py-1 px-2 bg-gray-00 rounded-2 hide-scroll"
        style={{ border: "0px", outline: "0px", minHeight: "4rem" }}
      />
      {error && <div style={{ color: "red" }}>{error}</div>}
      <button
        className="cta-bg py-1 px-3 tag"
        onClick={handleReplySubmit}
        disabled={isSubmitting}
      >
        {isSubmitting ? "Posting..." : "Post Reply"}
      </button>
    </div>
  );
};

export default ReplyBox;
