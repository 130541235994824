import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Header from '../layout/header';
import "./orders.css"
import Renderif from '../helpers/renderif';
import { useNavigate } from 'react-router-dom';
import { IoMdDownload } from "react-icons/io";
import Footer from '../layout/footer';


export const ProductCard = ({ item, status }) => {
    const { name, price, thumbnail, url } = item
    return (
        <div className='product-container d-flex justify-content-between'>
            <div className='img-container'>
                <img src={thumbnail} alt={`${thumbnail}_image`} />
            </div>
            <div>
                {name}
            </div>
            <div>
                <span className='me-4'>$ {price}</span>
                <Renderif isTrue={url && status === 'SUCCESS'}>
                    <span className='me-2 cursor-pointer' onClick={() => window.open(url, "_blank")}>
                        <IoMdDownload className='h-100' />
                        Download
                    </span>
                </Renderif>
            </div>
        </div>
    )
}


export const OrderCard = ({ item }) => {
    const { amount, orderId, products, paymentStatus, updatedAt } = item
    const navigate = useNavigate();
    const date = new Date(updatedAt).toLocaleDateString()
    const time = new Date(updatedAt).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
    })
    return (
        <div className='order-container container'>
            <div className='d-flex justify-content-between align-items-center'><div className='cursor-pointer' onClick={(e) => {
                navigate(`/order/${orderId}`)
            }}>orderId : {orderId}</div><span className={`v-tag v-tag--small f-12 ${paymentStatus === 'SUCCESS' ? 'bg-green text-white' : paymentStatus === 'FAILED' ? 'bg-red text-white' : ''}`}>{paymentStatus}</span></div>
            <div>Total : $ {amount}</div>
            <div className='d-flex justify-content-between'>
                <div>Date : {date}</div>
                <div>Time : {time}</div></div>
            <div>{products.map((product) => <ProductCard item={product} key={product.id} status={paymentStatus} />)}</div>
        </div>
    )
}


const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const _token = localStorage.getItem('token');
        const fetchData = async () => {
            setLoading(true)
            try {
                const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/orders`, {
                    headers: {
                        Authorization: `Bearer ${_token}`
                    }
                })
                setOrders(res.data)
            } catch (err) {
                console.log(err)
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [])


    return (
        <div className='hide-scroll'>
            <Header />
            <div className='py-4 d-flex w-100 justify-content-center container'>
                <Renderif isTrue={loading}>
                    <div className=''>
                        <div className='spinner'><div className="spinner-border"></div></div>
                    </div>
                </Renderif>
                <Renderif isTrue={!loading}>
                    <div className='d-flex flex-column w-100'>
                        <Renderif isTrue={orders.length === 0 }>
                            <div className='text-center my-5'>
                                No orders yet.
                            </div>
                        </Renderif>
                        {orders.map((item) => <OrderCard item={item} key={item.orderId} />)}
                    </div>
                </Renderif>
            </div>
            <Footer />
        </div>
    )
}

export default Orders