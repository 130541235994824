import React, { useCallback, useEffect, useState } from "react";
import {
  useParticipant,
  useParticipantIds,
  useDailyEvent,
  useLocalParticipant,
} from "@daily-co/daily-react-hooks";
import AlternateTile from "./AlternateTile";
import './alternatecall.css'


const AlternateCall2 = (props) => {
  const { tray } = props;
  const [getUserMediaError, setGetUserMediaError] = useState(false);
  const ownerId = useParticipantIds({ filter: "owner" })[0];
  const owner = useParticipant(ownerId);
  const localParticipant = useLocalParticipant();

  const [showTray, setShowTray] = useState(false);

  const  handleMouseEnter = () => {
    setShowTray(true);
  }

  const handleMouseLeave = () => {
    setShowTray(false);
  }

  const [isScreenSharing, setIsScreenSharing] = useState(false);

  // Handle media error
  useDailyEvent(
    "camera-error",
    useCallback(() => {
      setGetUserMediaError(true);
    }, [])
  );

  useEffect(() => {
    if (owner) {
      const screenVideo = owner.tracks.screenVideo;
      const video = owner.tracks.video;

      if (screenVideo && screenVideo.state === "playable") {
        setIsScreenSharing(true);
      } else if (video && video.state === "playable") {
        setIsScreenSharing(false);
      }
    }
  }, [owner]);

  if (getUserMediaError) {
    return <div>Error accessing media devices</div>; // Handle error UI here
  }

  return (
    <div className="owner-video-container w-100" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {owner && <AlternateTile id={ownerId} isScreenShare={isScreenSharing} isLocal={ownerId === localParticipant.session_id}/>}
      {!owner && <p>Waiting for webinar to start</p>}
      {showTray && localParticipant.owner && tray}
    </div>
  );
};

export default AlternateCall2;
