import React, { useContext, useEffect, useState } from 'react'
import { createContext } from "react";
import { useNavigate } from 'react-router-dom';
import { Service } from '../services/api';
import { AlertContext } from './alert_context';
export const AuthContext = createContext();

export default function AuthContextProvider({ children }) {

  const navigate = useNavigate();
  const { notify } = useContext(AlertContext);
  const [googleSSO, setGoogleSSO] = useState({
    enabled: false,
    data: {}
  })

  const [state, setState] = useState({
    user: null,
    loading: false,
  })

  let _token = localStorage.getItem('token');

  useEffect(() => {

    const init = async () => {
      if (_token) {
        await auth({ token: _token });
      }
    };
    init();

    return () => { };

  }, [_token])



  const setUser = ({ user }) => {
    user !== null ? localStorage.setItem('token', user.token) : localStorage.removeItem('token');

    setState((prevState) => {
      return ({ ...prevState, user })});
  }


  const auth = async ({ token }) => {
    setState({ ...state, loading: true });
    let user = await Service('POST', 'auth/me', { token })

    const userData = {...user.data}
    userData.token = _token

    if (user.status == 201) {
      // let profiles = localStorage.getItem('tokens')
      // profiles = JSON.parse(profiles);
      // user.data.profiles = profiles
      setUser({ user: userData });
      // if(user.data.roles.includes("Admin")){
      //   console.log("1")
      //   navigate('/administrador');
      //   return;
      // }
      // if(user.data.roles.includes("Psicologist")){
      //   console.log("2")
      //   navigate('/admin');
      //   return
      // }
      // navigate('/client');

    } else {
      notify({ title: 'error', message: 'Tu sesión ha expirado', type: 'error' })
      localStorage.removeItem('token');
      localStorage.removeItem('tokens');
      navigate('/');
    }

    setState(prevState => ({ ...prevState, loading: false }));

  }

  const changeUser = async ({ profile }) => {

    localStorage.setItem('token', profile.api_token);
    let token = localStorage.getItem('token');

    let user = await Service('POST', 'Auth/verify_token', { 'api_token': token })

    if (user.status === 200) {
      let profiles = localStorage.getItem('tokens');

      user.data.profiles = JSON.parse(profiles);

      setUser({ user: user.data })
      return true;
    }

    return false;
  }



  const logout = () => {
    setUser({ user: null });
    localStorage.removeItem('token');
    // localStorage.removeItem('tokens');
    // localStorage.removeItem('onboarding');
  }


  return (
    <AuthContext.Provider value={
      {
        ...state,
        logout,
        setUser,
        changeUser,
        auth,
        googleSSO, setGoogleSSO
      }
    }>
      {children}
    </AuthContext.Provider>
  )
}
