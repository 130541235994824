import React, { useEffect, useRef, useCallback, useState, useContext } from 'react';
import {
  useVideoTrack,
  useDevices,
  useDaily,
  useDailyEvent,
  useLocalSessionId,
  useParticipantProperty,
} from '@daily-co/daily-react-hooks';
import UserMediaError from '../UserMediaError/UserMediaError';

import './HairCheck.css';
import { AuthContext } from '../../../../context/auth_context';
import { Service } from '../../../../services/api';

export default function HairCheck({ joinCall, cancelCall }) {
  const localSessionId = useLocalSessionId();
  const initialUsername = useParticipantProperty(localSessionId, 'user_name');
  const videoTrack = useVideoTrack(localSessionId);
  const { microphones, speakers, cameras, setMicrophone, setCamera, setSpeaker, camState, micState, hasCamError } = useDevices();
  const callObject = useDaily();
  const videoElement = useRef();

  const mutedVideo = videoTrack.isOff;

  const { user } = useContext(AuthContext)
  const [name, setName] = useState(initialUsername)
  const [anonymous, setAnonymous] = useState(false);

  const [getUserMediaError, setGetUserMediaError] = useState(false);

  // console.log("videoTrack: ",videoTrack)
  // console.log(micState)

  useEffect(() => {
    setName(initialUsername);
  }, [initialUsername]);

  const toggleVideo = useCallback(() => {
    callObject.setLocalVideo(mutedVideo);
  }, [callObject, mutedVideo]);

  useDailyEvent(
    'camera-error',
    useCallback(() => {
      setGetUserMediaError(true);
    }, []),
  );

  const handleUsername = (e) => {
    setName(e.target.value)
    callObject.setUserName(e.target.value);
  };


  const join = async (e) => {
    e.preventDefault();
    // if(anonymous){
    //   callObject.setUserName('');
    // }else{
    //   callObject.setUserName(name);
    // }

    // TODO: generate token according to options selected, then join the meeting.
    joinCall();
  };

  // useEffect(()=>{
  //   console.log(cameras)
  // },[cameras,camState, micState])

  useEffect(() => {
    if (!videoTrack.persistentTrack) return;
    if (videoElement?.current) {
      videoElement.current.srcObject =
        videoTrack.persistentTrack && new MediaStream([videoTrack?.persistentTrack]);
    }
  }, [videoTrack.persistentTrack]);

  const updateMicrophone = (e) => {
    setMicrophone(e.target.value);
  };

  const updateSpeakers = (e) => {
    setSpeaker(e.target.value);
  };

  const updateCamera = (e) => {
    setCamera(e.target.value);
  };

  return getUserMediaError ? (
    <UserMediaError />
  ) : (
    <div className="container login-container">
      <div className="v-card d-flex flex-row opacity-transition">
        <div className='col-12 login-card-padding'>
          <form className="hair-check" onSubmit={join}>

            {/* <div>Audio {videoTrack.isOff ? "off": "on"}</div> */}
            {/* Video preview */}
            {videoTrack?.persistentTrack && <video autoPlay muted playsInline ref={videoElement} />}

            {/* Username */}
            <div>
              <label htmlFor="username" className="f-14">Your name:</label>
              <input
                className='bg-light f-14'
                name="username"
                type="text"
                placeholder="Enter username"
                onChange={handleUsername}
                // value={localParticipant?.user_name || ' '}
                value={name || ''}
              />
            </div>

            {/* Username checkbox */}
            {/* <div className='d-flex gap-3'>
        <input name='showName' checked={anonymous} onChange={(e) => {
          setAnonymous(e.target.checked)
        }} type="checkbox" style={{maxWidth : '5%'}}></input>
        <span htmlFor="showName">
        Join Anonymously
        </span>
      </div> */}

            {/* Microphone select */}
            <div>
              <label htmlFor="micOptions" className="f-14">Microphone:</label>
              <select className="bg-light f-14" name="micOptions" id="micSelect" onChange={updateMicrophone}>
                {microphones?.map((mic) => (
                  <option key={`mic-${mic.device.deviceId}`} value={mic.device.deviceId}>
                    {mic.device.label}
                  </option>
                ))}
              </select>
            </div>

            {/* Speakers select */}
            <div>
              <label htmlFor="speakersOptions" className="f-14">Speakers:</label>
              <select className="bg-light f-14" name="speakersOptions" id="speakersSelect" onChange={updateSpeakers}>
                {speakers?.map((speaker) => (
                  <option key={`speaker-${speaker.device.deviceId}`} value={speaker.device.deviceId}>
                    {speaker.device.label}
                  </option>
                ))}
              </select>
            </div>

            {/* Camera select */}
            <div>
              <label htmlFor="cameraOptions" className="f-14">Camera:</label>
              <select className='bg-light f-14' name="cameraOptions" id="cameraSelect" onChange={updateCamera}>
                {cameras?.map((camera) => (
                  <option key={`cam-${camera.device.deviceId}`} value={camera.device.deviceId}>
                    {camera.device.label}
                  </option>
                ))}
              </select>
            </div>
            <div className='d-flex justify-content-center'>
              <button onClick={join} type="submit" className='tag cta-bg px-3'>
                Join call
              </button>
            </div>
            {/* <button onClick={cancelCall} className="cancel-call" type="button">
        Back to start
      </button>
      <button onClick={toggleVideo} className="cancel-call" type="button">
        Refresh
      </button> */}
          </form>
        </div>
      </div>
    </div>
  );
}
