import React, { useContext, useState } from 'react'
import Background from '../../components/background/Background.jsx'
import { Widget } from '@typeform/embed-react'
import './application.css'
import Renderif from '../../../components/helpers/renderif.js'
import ApplicationFormButton from '../../components/ApplicationFormButton.jsx'
import { AuthContext } from '../../../context/auth_context.js'
import { Service } from '../../../services/api.js'
import Header from '../../../components/layout/header.js'
import { useNavigate } from 'react-router-dom'
import Footer from '../../../components/layout/footer.js'

const ApplicationView = () => {
  const [state, setState] = useState({ loading: false, step: -1, currentFormId: '' })
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleformSelect = (value) => {
    setState((prevState) => ({
      ...prevState,
      step: 0,
      currentFormId: value
    }))
  }


  return (
    <div className='container-fluid h-100 hide-scroll'>
      <Header />
      {/* <div className='position-fixed h-100 w-100'>
        <div className='position-relative h-100'>
          <Background />
        </div>
      </div> */}
      <div className='d-flex my-5 container-fluid justify-content-center align-items-center page-load hide-scroll' >
        <Renderif isTrue={state.step === -1}>
          <div className='d-flex align-items-center flex-column container gap-3 p-2' style={{ maxWidth: 'max-content' }} >
            <h3 className='login-font-color mb-2'>Apply Now</h3>
            <ApplicationFormButton formName={'AI Qualified Professional Speaker Application'} formId={'SeuOIANl'} handleForm={handleformSelect} />
            <ApplicationFormButton formName={'AI Visionary Thought Leadership Scholarship Candidate Application'} formId={'ENctx8x3'} handleForm={handleformSelect} />
            <ApplicationFormButton formName={'C-Suite Application'} formId={'tuNtEqv2'} handleForm={handleformSelect} />
            <ApplicationFormButton formName={'CEO’S Application'} formId={'NYU7myEf'} handleForm={handleformSelect} />
            <ApplicationFormButton formName={'Certified Professional Coach Application'} formId={'LgcsAUfW'} handleForm={handleformSelect} />
            <ApplicationFormButton formName={'AI Speakers & Executive Coaches Visionary Voices Application'} formId={'dRL3UeeY'} handleForm={handleformSelect} />
            <ApplicationFormButton formName={'Learning & Development Professional Application'} formId={'YJxMmh0R'} handleForm={handleformSelect} />
            <ApplicationFormButton formName={'General Application'} formId={'SCV4Wjsc'} handleForm={handleformSelect} />
          </div>
        </Renderif>
        <Renderif isTrue={state.step === 0}>
          {/* TODO : use hidden fields here for redundant data forms */}
          <div className='position-relative h-100 w-100'>
          <Widget id={state.currentFormId}  hideFooter={true} hideHeaders={true} className='h-100 w-100 position-absolute top-0 start-0' noScrollbars={true} displayAsFullScreenModal={true} onSubmit={(event) => {
            // {responseId: 'xyt4tjwvla98hsge0qaoxyt4t605onlx', formId: 'LgcsAUfW', response_id: 'xyt4tjwvla98hsge0qaoxyt4t605onlx'}
            const body = {
              userId: user.id,
              formId: event.formId,
              responseId: event.responseId
            }
            setUser({user : {
              ...user,
              formId : event.formId,
              responseId : event.responseId
            }})
            const res = Service("POST", "form", body);
            if(res){
              navigate(`/application-success`)
            }
          }} />
          </div>
        </Renderif>
      </div>
      <Footer />
    </div>
  )
}

export default ApplicationView