import React, { useState } from 'react'

const RateNumber = ({value, onChange}) => {
    const [state, setState] = useState(value);
    return (
      <div>
        <div className="d-flex">
          <div
            className={` px-3 py-1 r-5 me-1 cursor-pointer ${
              state.value === 0 ? "cta-bg" : "bg-light text-gray"
            } `}
            onClick={() => {
              setState({ ...state, value: 0 });
              onChange(0)
            }}
          >
            0
          </div>
          <div
            className={` px-3 py-1 r-5 me-1 cursor-pointer ${
              state.value === 1 ? "cta-bg" : "bg-light text-gray"
            } `}
            onClick={() => {
              setState({ ...state, value: 1 });
              onChange(1)
            }}
          >
            1
          </div>
          <div
            className={` px-3 py-1 r-5 me-1 cursor-pointer ${
              state.value === 2 ? "cta-bg" : "bg-light text-gray"
            } `}
            onClick={() => {
              setState({ ...state, value: 2 });
              onChange(2)
            }}
          >
            2
          </div>
          <div
            className={` px-3 py-1 r-5 me-1 cursor-pointer ${
              state.value === 3 ? "cta-bg" : "bg-light text-gray"
            } `}
            onClick={() => {
              setState({ ...state, value: 3 });
              onChange(3)
            }}
          >
            3
          </div>
          <div
            className={` px-3 py-1 r-5 me-1 cursor-pointer ${
              state.value === 4 ? "cta-bg" : "bg-light text-gray"
            } `}
            onClick={() => {
              setState({ ...state, value: 4 });
              onChange(4)
            }}
          >
            4
          </div>
          <div
            className={` px-3 py-1 r-5 me-1 cursor-pointer ${
              state.value === 5 ? "cta-bg" : "bg-light text-gray"
            } `}
            onClick={() => {
              setState({ ...state, value: 5 });
              onChange(5)
            }}
          >
            5
          </div>
          <div
            className={` px-3 py-1 r-5 me-1 cursor-pointer ${
              state.value === 6 ? "cta-bg" : "bg-light text-gray"
            } `}
            onClick={() => {
              setState({ ...state, value: 6 });
              onChange(6)
            }}
          >
            6
          </div>
          <div
            className={` px-3 py-1 r-5 me-1 cursor-pointer ${
              state.value === 7 ? "cta-bg" : "bg-light text-gray"
            } `}
            onClick={() => {
              setState({ ...state, value: 7 });
              onChange(7)
            }}
          >
            7
          </div>
          <div
            className={` px-3 py-1 r-5 me-1 cursor-pointer ${
              state.value === 8 ? "cta-bg" : "bg-light text-gray"
            } `}
            onClick={() => {
              setState({ ...state, value: 8 });
              onChange(8)
            }}
          >
            8
          </div>
          <div
            className={` px-3 py-1 r-5 me-1 cursor-pointer ${
              state.value === 9 ? "cta-bg" : "bg-light text-gray"
            } `}
            onClick={() => {
              setState({ ...state, value: 9 });
              onChange(9)
            }}
          >
            9
          </div>
          <div
            className={` px-3 py-1 r-5 me-1 cursor-pointer ${
              state.value === 10 ? "cta-bg" : "bg-light text-gray"
            } `}
            onClick={() => {
              setState({ ...state, value: 10 });
              onChange(10)
            }}
          >
            10
          </div>
        </div>
        <div className="d-flex px-3 mt-1">
          <div className="f-14 text-gray">0 = Not likely</div>
          <div className="col"></div>
          <div className="f-14 text-gray">10 = Extremely likely</div>
        </div>
      </div>
    );
  }
  

export default RateNumber