import { useParticipant } from "@daily-co/daily-react-hooks";
import React from "react";

const CallParticipant = ({ sessionId }) => {
  const participant = useParticipant(sessionId);

  return (
    <div className="col-12 col-md-4 d-flex gap-2 my-3 px-1">
      <div className="participant-avatar bg-gray-10">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          {participant.user_name.slice(0, 1)}
        </div>
        {/* <img
          src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?q=80&w=64&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          style={{
            objectFit: "cover",
            objectPosition: "center",
            width: "100%",
          }}
        /> */}
      </div>
      <div>
        <div className="login-font-color bold">{participant.user_name}</div>
        <div className="f-14">{participant.userData?.occupation}</div>
        <div className="f-14">{participant.userData?.industry}</div>
      </div>
    </div>
  );
};

export default CallParticipant;
