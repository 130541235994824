import React, { useContext, useState } from 'react'
import { CgLogIn, CgLogOut } from "react-icons/cg";
import { AuthContext } from '../../context/auth_context';
import { useNavigate } from 'react-router-dom';
import Cart from '../cart/Cart';
import "../cart/Cart.css"
import { FaShoppingCart } from 'react-icons/fa';
import { useCart } from '../../context/cart_context';
import Renderif from '../helpers/renderif';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import Offcanvas from 'react-bootstrap/Offcanvas';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Avatar from '../Avatar/Avatar';
import SubHeader from './subheader';


export default function Header() {
    const { logout, user } = useContext(AuthContext);
    const { saveCart, clearCart } = useCart();
    const { getTotalItems } = useCart();
    const [cartOpen, setCartOpen] = useState(false);

    const handleClose = () => setCartOpen(false);
    const handleShow = () => setCartOpen(true);
    // console.log(user);
    const [modalOpen, setModalOpen] = useState(false);
    const handleModalClose = () => setModalOpen(false);
    const handleModalShow = () => setModalOpen(true);

    const navigate = useNavigate()


    return (<>
        <Offcanvas show={cartOpen} onHide={handleClose} placement={'end'}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Shopping Cart</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Cart clearCart={handleModalShow} />
            </Offcanvas.Body>
        </Offcanvas>

        <Modal
            show={modalOpen}
            onHide={handleModalClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Clear Cart</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Do you wish to clear the cart ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                    Close
                </Button>
                <Button onClick={() => {
                    clearCart();
                    handleModalClose();
                }} variant="danger">Yes</Button>
            </Modal.Footer>
        </Modal>


        <Navbar expand="lg" className="bg-body-tertiary" >
            <Container>
                <Navbar.Brand className='cursor-pointer' onClick={() => {
                    navigate('/')
                }} >
                    <div className='logo-wrapper'>
                        <img src='/assets/images/logo.svg' className='image-fluid' alt='coaching2100-logo' />
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link onClick={() => {
                            navigate('/')
                        }}>
                            <div className={`header-item `} >
                                <div className='f-18 bold text-dark-green py-1'>Home</div>
                            </div>
                        </Nav.Link>
                        <Nav.Link onClick={() => {
                            navigate('/events')
                        }}>
                            <div className={`header-item `}>
                                <div className='f-18 bold text-dark-green py-1'>Events</div>
                            </div>
                        </Nav.Link>
                        <Nav.Link>
                            <div className={`header-item `}>
                                <div className='f-18 bold text-dark-green py-1'>Products</div>
                            </div>
                        </Nav.Link>
                        <Nav.Link>
                            <div className={`header-item `}>
                                <div className='f-18 bold text-dark-green py-1'>About Us</div>
                            </div>
                        </Nav.Link>
                        {/* <Nav.Link><div className={`header-item ${page === 'marketplace' ? 'header-item--active' : ''}`} onClick={() => {
                            navigate('/marketplace')
                        }}>
                            <div className='f-18 bold text-dark-green py-1'>Marketplace</div>
                        </div></Nav.Link>

                        <Nav.Link><div className={`header-item ${page === 'collaboration' ? 'header-item--active' : ''}`} onClick={() => {
                            navigate('/collaboration')
                        }}>
                            <div className='f-18 bold text-dark-green py-1'>Human Collaboration</div>
                        </div></Nav.Link>
                        <Nav.Link> <div className={`header-item ${page === 'insights' ? 'header-item--active' : ''}`} onClick={() => {
                            navigate('/insights')
                        }}>
                            <div className='f-18 bold text-dark-green py-1'>Intelligence & Insights</div>
                        </div></Nav.Link> */}
                    </Nav>
                </Navbar.Collapse>
                <div className="right-side-items">
                    <div className='d-flex align-items-center'>
                        <div className='me-2 bv-icon bv-icon--mid cursor-pointer' onClick={() => {
                            handleShow();
                        }}>
                            <div className='position-relative'>
                                <span className='position-absolute cart-badge end-0 top-0'>{getTotalItems()}</span>
                                <FaShoppingCart />
                            </div>
                        </div>
                        <Renderif isTrue={user}>
                            <Avatar userData={user} />
                        </Renderif>
                        <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {
                            saveCart()
                            logout()
                            navigate('/login')
                        }}>
                            <Renderif isTrue={user}>
                                <CgLogOut onClick={() => {
                                    clearCart()
                                }} size={30}></CgLogOut>
                            </Renderif>
                            <Renderif isTrue={!user}>
                                <CgLogIn size={30}></CgLogIn>
                            </Renderif>
                        </div>
                    </div>
                </div>
            </Container>
        </Navbar>
        <SubHeader />
    </>


        // <div className='v-header'>

        //     <div className='container d-flex h-100'>
        //         <Drawer open={cartOpen} onClose={toggleDrawer} direction='right' className='right-side-cart'>
        //             <Cart />
        //         </Drawer>
        //         <div className='d-flex align-items-end'>
        //             <div className={`header-item ${page === 'visualization_center' ? 'header-item--active' : ''}`} onClick={() => {
        //                 navigate('/center')
        //             }}>
        //                 <div className='f-18 bold text-dark-green py-1'>Visualization Center</div>
        //             </div>
        //             <div className={`header-item ${page === 'marketplace' ? 'header-item--active' : ''}`} onClick={() => {
        //                 navigate('/marketplace')
        //             }}>
        //                 <div className='f-18 bold text-dark-green py-1'>Marketplace</div>
        //             </div>
        //             <div className={`header-item ${page === 'collaboration' ? 'header-item--active' : ''}`} onClick={() => {
        //                 navigate('/collaboration')
        //             }}>
        //                 <div className='f-18 bold text-dark-green py-1'>Human Collaboration</div>
        //             </div>
        //             <div className={`header-item ${page === 'insights' ? 'header-item--active' : ''}`} onClick={() => {
        //                 navigate('/insights')
        //             }}>
        //                 <div className='f-18 bold text-dark-green py-1'>Intelligence & Insights</div>
        //             </div>

        //             {/* <div className='header-item'>
        //             <div className='f-18 bold text-dark-green py-1'>Intelligence & Insights</div>
        //         </div> */}
        //         </div>
        //         <div className='col'></div>
        //         {/* {`${user?.nombre}`} */}
        //         <div className='d-flex align-items-center'>
        //             {/* <div className='avatar mb-2'></div> */}
        //             <div className='me-2 bv-icon bv-icon--mid cursor-pointer' onClick={() => {
        //                 toggleDrawer()
        //             }}>
        //                 <div className='position-relative'>
        //                     <span className='position-absolute cart-badge end-0 top-0'>{getTotalItems()}</span>
        //                     <FaShoppingCart />
        //                 </div>
        //             </div>
        //             <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {
        //                 logout()
        //                 navigate('/login')

        //             }}>
        //                 <Renderif isTrue={user}>
        //                     <CgLogOut size={30}></CgLogOut>
        //                 </Renderif>
        //                 <Renderif isTrue={!user}>
        //                     <CgLogIn size={30}></CgLogIn>
        //                 </Renderif>
        //             </div>
        //         </div>


        //     </div>

        // </div>
    )
}