import axios from "axios";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Renderif from "../helpers/renderif";
import Comment from "./Comment";

const CommentList = forwardRef((props, ref) => {
  const { sessionId } =  props
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const _token = localStorage.getItem("token");

  const fetchComments = async () => {
    if (!_token) {
      setLoading(false);
      return;
    }
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/comment/${sessionId}`,
        {
          headers: {
            Authorization: `Bearer ${_token}`,
          },
        }
      );
      setData(res.data);
    } catch (err) {
      console.log("Err fetching comments", err);
    }
  };

  const refreshComments = () => {
    fetchComments();
  }

  useImperativeHandle(ref, () => ({
    refreshComments,
  }));

  useEffect(() => {
    setLoading(true);
    fetchComments().then(() => {
      setLoading(false);
    });
  }, [sessionId]);

  return (
    <div className="r-10 py-3">
      <Renderif isTrue={!loading}>
        {data &&
          data.map((comment) => {
            return (
              <Comment
                key={comment.id}
                comment={comment}
                sessionId={sessionId}
                refreshComments={refreshComments}
              />
            );
          })}
      </Renderif>
    </div>
  );
});

export default CommentList;
