import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import ChatAI from "./ChatAi";
import './chatai.css'

const ChatAiIcon = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Offcanvas className="offcanvas-chatai" show={show} onHide={handleClose} placement={'end'}>
        
        <Offcanvas.Body>
          <ChatAI show={true}/>
        </Offcanvas.Body>
      </Offcanvas>
      <div className="cta-chatai cursor-pointer" onClick={handleShow}>
        <div className="rainbow-container">
          <div className="green"></div>
          <div className="pink"></div>
          <div className="blue"></div>
        </div>
      </div>
    </>
  );
};

export default ChatAiIcon;
