import React from "react";

const GRADIENT = Object.freeze({
  up: "top",
  down: "bottom",
  left: "left",
  right: "right",
});

const Banner = ({ height, imageSrc, radius, gradient }) => {
  if (gradient in GRADIENT) {
    const direction = GRADIENT[gradient]
    const gradientStyle = {
      background: `linear-gradient(to ${direction}, rgba(0, 178, 187, 0.06) 30%, rgba(0, 178, 187, 0.63) 70%)`,
      position: 'absolute', // Ensure it overlays the image
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
    };
    return (
      <div
        className="w-100"
        style={{
          height,
          overflow: "hidden",
          borderRadius: `${radius}rem`,
          position : 'relative',
        }}
      >
        <div style={gradientStyle}>

        </div>
        <img
          src={imageSrc}
          alt="banner"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
            zIndex : -1
          }}
        />
      </div>
    );
  }

  return (
    <div
      className="w-100"
      style={{ height, overflow: "hidden", borderRadius: `${radius}rem` }}
    >
      <img
        src={imageSrc}
        alt="banner"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
    </div>
  );
};

export default Banner;
