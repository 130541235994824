import React from 'react';

const GRADIENT = Object.freeze({
  up: "top",
  down: "bottom",
  left: "left",
  right: "right",
});

const LiveEventBanner = ({ height, mediaType, mediaSource, radius, gradient }) => {
  const renderMedia = () => {
    switch (mediaType) {
      case 'image':
        return (
          <img
            src={mediaSource}
            alt="banner"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
              zIndex: -1
            }}
          />
        );
      case 'video':
        return (
          <video
            className="w-100 h-100"
            autoPlay
            style={{
              objectFit: "cover",
              objectPosition: "center",
              zIndex: -1
            }}
          >
            <source src={mediaSource} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      case 'livestream':
        return (
         
          <iframe
            className="w-100 h-100"
            src={`${mediaSource}&autoplay=1&mute=1&controls=0&modestBranding=1&rel=0&showInfo=0&iv_load_policy=3`}
            title="Live Stream"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen
            style={{
              border: 0,
              zIndex: -1
            }}
          ></iframe>
        );
      default:
        return null;
    }
  };

  const gradientStyle = gradient && {
    background: `linear-gradient(to ${GRADIENT[gradient]}, rgba(0, 178, 187, 0.06) 30%, rgba(0, 178, 187, 0.63) 70%)`,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1
  };

  return (
    <div
      className="w-100"
      style={{
        height,
        overflow: "hidden",
        borderRadius: `${radius}rem`,
        position: 'relative'
      }}
    >
      {gradientStyle && <div style={gradientStyle}></div>}
      {renderMedia()}
    </div>
  );
};

export default LiveEventBanner;
