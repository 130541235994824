import React, { useRef } from "react";
import CreateComment from "./CreateComment";
import CommentList from "./CommentList";

const CommentBox = ({ sessionId }) => {
  const commenListRef = useRef();
  const refreshComments = () => {
    commenListRef.current.refreshComments();
  }
  return (
    <div className="my-5 py-5 ">
      <div className="f-18">Community</div>
      <CreateComment sessionId={sessionId} refreshComments={refreshComments}/>
      <div className="hide-scroll mt-3" style={{ maxHeight: "75vh" }}>
        <CommentList sessionId={sessionId} ref={commenListRef}/>
      </div>
    </div>
  );
};

export default CommentBox;
