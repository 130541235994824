import React, { useEffect, useState } from "react";
import Banner from "../Banner/banner";
import "./advertisement.css";
import axios from "axios";

const Advertisement = () => {
  const [primary, setPrimary] = useState(null);
  const [secondary, setSecondary] = useState(null);
  useEffect(() => {
    const fetchAdvertisements = async () => {
      try {
        let res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/sections/primary`
        );
        setPrimary(res.data);
        res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/sections/secondary`)
        setSecondary(res.data)
      } catch (err) {
        console.log(err);
      }
    };
    fetchAdvertisements();
  }, []);

  return (
    <div className="my-5 container">
      <div className="row px-0">
        {primary && <div className="col-md-7 position-relative mt-2">
          <div
            className="position-absolute w-100 big-ad-content bottom-0 p-5"
            style={{ zIndex: 2 }}
          >
      
              <div className="text-white">
                <div className="text-light-gray f-12">Recent</div>
                <div className="f-22">{primary.title}</div>
                <div className="f-22">{primary.description}</div>
              </div>
            
            <div
              className="tag cta-bg end-0 bottom-0 px-3 py-1 cursor-pointer f-14"
              style={{ height: "max-content" }} onClick={() => {
                window.location.href = primary.url
              }}
            >
              Join Live Session
            </div>
          </div>
          {/* <div className="position-absolute m-5  bottom-0 start-0 text-white" style={{ zIndex: 2 }}>
                            <div className="text-light-gray f-12">Recent</div>
                            <div className="f-22">Visionary Voices:</div>
                            <div className="f-22">Shaping Tomorrow</div>
                        </div> */}
          <Banner
            height={350}
            radius={3}
            gradient={"down"}
            imageSrc={primary.backgroundImg}
          />
          {/* <div className="tag cta-bg position-absolute end-0 bottom-0 m-5 px-3 py-1 cursor-pointer f-14" style={{ zIndex: 2 }}>
                            Join Live Session
                        </div> */}
        </div>}
        {secondary && <div className="col-md-5 position-relative mt-2">
          <div
            className="position-absolute top-0 m-5 text-white"
            style={{ zIndex: 2 }}
          >
            <div className="f-12">Recent</div>
            <div className="f-22">{secondary.title}</div>
            <div className="f-22">{secondary.description}</div>
          </div>
          <Banner
            height={350}
            radius={3}
            gradient={"up"}
            imageSrc={secondary.backgroundImg}
          />
          <div
            className="tag cta-bg position-absolute start-0 bottom-0 m-5 px-3 py-1 cursor-pointer f-14"
            style={{ zIndex: 2 }}
            onClick={() => {
                window.location.href = secondary.url
            }}
          >
            Join Live Session
          </div>
        </div>}
      </div>
    </div>
  );
};

export default Advertisement;
