import React from "react";
import Banner from "../Banner/banner";
import './footerbanner.css'
import { useNavigate } from "react-router-dom";

const FooterBanner = () => {
  const navigate = useNavigate();
  // const [data, setData] = useState(null);

  // useEffect(() => {
  //   const _token = localStorage.getItem('token');
  //   axios.get(`${process.env.REACT_APP_API_URL}/api/v1/sections/footer-banner`, {
  //     headers: {
  //       Authorization: `Bearer ${_token}`
  //     }
  //   }).then(res => {
  //     setData(res.data);
  //   })
  // }, [])

  
  return (
    <div className="position-relative container px-2">
      <div className="position-absolute d-flex footer-banner-content text-white p-5" style={{zIndex : 5}}>
      <div>
      <div className="f-22 bold">Ready for the next Step?</div>
        <div className="mt-4 f-18">
          Join us for exclusive insights from thought leaders who are redefining
          the future.
        </div>
      </div>
      <div
        className="tag cta-bg px-3 py-1 cursor-pointer f-14"
        style={{ zIndex: 2 }}
        onClick={() => {
          navigate(`/application`)
        }}
      >
        Join Now
      </div>
      </div>
      {/* <div
        className="position-absolute text-white start-0 bottom-0 top-0 m-5 d-flex flex-column justify-content-center"
        style={{ zIndex: 2 }}
      >
        <div className="f-22 bold">Ready for the next Step?</div>
        <div className="mt-4 f-18">
          Join us for exclusive insights from thought leaders who are redefining
          the future.
        </div>
      </div> */}
      <Banner
        height={250}
        radius={3}
        gradient={"left"}
        imageSrc={"/assets/images/login_bg.jpg"}
      />
      {/* <div
        className="tag cta-bg position-absolute end-0 top-50 me-5 px-3 py-1 cursor-pointer f-14"
        style={{ zIndex: 2 }}
      >
        Join Now
      </div> */}
    </div>
  );
};

export default FooterBanner;
