import React from "react";
import { useNavigate } from "react-router-dom";
import { checkIfWebinarLive } from "../../views/user/pages/visualization_center/home_visualization_center";
import { format } from "@formkit/tempo";
import { Service } from "../../services/api";

const SessionCard = ({ session, curso }) => {
  const navigate = useNavigate();
  const {startDate, endDate} = session
  const currentTime = new Date().getTime()
  const sessionStartTime = new Date(startDate).getTime()
  const sessionEndTime = new Date(endDate).getTime()

  let webinar = false;
  if (session.activities.length) {
    webinar =
      session.activities[0].activity?.activityType.type === "LIVE_WEBINARS";
  }

  async function joinRoom(session) {
    let activity = session.activities[0].activity;
    if (activity?.webinars?.length > 0) {
      let room_name = activity.webinars[0].webinarUrlRoom.split("/")[3];
      let body = {
        roomId: room_name,
        webinarId: activity?.webinars[0].id,
      };
      let _token = await Service("POST", `dailyvideo/generate-token`, body);
      navigate(`/room?roomUrl=${activity?.webinars[0].webinarUrlRoom}`, {
        state: { sessionData: session, token: _token.data.token },
      });
    } else {
      // console.log(activity?.webinars);
    }
  }
  const isActive = (sessionStartTime < currentTime && currentTime < sessionEndTime)
  const isScheduled = sessionStartTime > currentTime
  return (
    <div key={session.id} className="col-lg-3 col-md-6 my-4">
      <div className="w-100 h-100 hover-card border-0 mb-3 overflow-hidden d-flex flex-column">
        <div className="card-image w-100 rounded-4 position-relative session-card-image-container">
          <div
            className={`position-absolute f-10 tag ${isActive || isScheduled ? 'py-1 px-2' : ''} ${isActive ? 'cta-bg' : ''} ${isScheduled ? 'bg-light' : ''}`}
            style={{ zIndex: 1, right: 8, top: 8 }}
          >
            {isActive && 'Active'}
            {isScheduled && 'Scheduled'}
          </div>
          <img src={`${session?.thumbnail}`} alt="event" className="w-100 h-100 object-fit-cover" />
        </div>
        <div className="card-body d-flex flex-column">
          <div className="d-flex flex-column flex-grow-1 ps-0 pe-0 pb-0">
            {/* <div className="f-12 text-gray upper">{format(session.startDate, "MMM D, YYYY h:mm a")}</div> */}
            {/* <div className="f-12 text-gray">{session.startDate}</div> */}
            {/* <div className="bg-greylight me-3 p-3 border-light-md rounded-xxl theme-dark-bg"><h4 className="fw-700 font-lg ls-3 text-grey-900 mb-0"><span className="ls-3 d-block font-xsss text-grey-500 fw-500">{value.month}</span>{value.date}</h4></div> */}
            <div className="f-18 mt-3 bold">{session?.name}</div>
            {/* <div className="f-14 text-gray">{session?.description}</div> */}
          </div>
          <div className="d-flex mt-3 justify-content-between align-items-center p-0">
            <div style={{fontSize : '0.88rem'}}>{format(session.startDate, "DD, MMM - H:mm")}</div>
            <div className="col"></div>
            {!webinar || !checkIfWebinarLive(session) ? (
              <div>
                <div
                  className="ms-1 f-14 tag login-cta-border cursor-pointer"
                  onClick={() => {
                    navigate(`/event/${session.id}`, {
                      state: { curso, session },
                    });
                  }}
                >
                  <div className="px-4 py-1">Details</div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {webinar && checkIfWebinarLive(session) && (
              <div>
                <div
                  className="ms-1 f-14 tag cta-bg cursor-pointer"
                  onClick={() => {
                    // navigate("/event/1", { state: { curso, session } })
                    joinRoom(session);
                  }}
                >
                  <div className="px-4 py-1">Join Live Session</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionCard;
