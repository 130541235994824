
import './App.css';
import './Virtual.css';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';


import React, { useContext } from 'react';
import World from './views/commun/world_commun';

import Home from './components/theme/pages/Home';


import Email from './components/theme/pages/Email';
import Live from './components/theme/pages/Live';


import Group from './components/theme/pages/Group';
import HomeCommun from './views/commun/home_commun';
import Daily from './components/daily/App';
import DailyChat from './components/daily/App';
import LoginView from './views/commun/login_commun';
import RegisterView from './views/commun/register_commun';
import EventDetailView from './views/user/event_detail_view';
import AuthContextProvider, { AuthContext } from './context/auth_context';
import AlertNotification from './components/main/alert_notification';
import HomeTalenscope from './views/user/pages/talenscope/home_talenscope';
import HomeVisualizationCenter from './views/user/pages/visualization_center/home_visualization_center';
import ApplicationView from './views/commun/application/Application.jsx';
import Welcome from './views/commun/welcome/Welcome.jsx';
import EventsPage from './views/user/pages/events/eventsPage.jsx';
import CallView from './components/daily/components/Alternate_view/CallView.jsx';
import ProductDetails from './components/productdetails/Productdetails.jsx';
import { CartProvider } from './context/cart_context';
import Cancel from './components/payment/cancel.js';
import Success from './components/payment/success.js';
import Orders from './components/payment/orders.js';
import EventsDummy from './views/user/pages/events/eventsDummy.jsx';
import VisualizationDummy from './views/user/pages/visualization_center/visualizationDummy.jsx';
import New from './views/components/New.jsx';
import Collaboration from './views/user/pages/collaboration/Collaboration.jsx';
import ApplicationSuccess from './views/commun/application/ApplicationSuccess/ApplicationSuccess.jsx';



function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <CartProvider>

          <AlertNotification></AlertNotification>

          <Routes>
            <Route path="/" element={
              <PrivateRoute>
                <HomeVisualizationCenter />
              </PrivateRoute>
            } />
            <Route path="/login" element={<LoginView></LoginView>} />
            <Route path="/register" element={<RegisterView></RegisterView>} />
            <Route path="/event/:id" element={
              <PrivateRoute>
                <EventDetailView></EventDetailView>
              </PrivateRoute>} />
            {/* <Route path="/" element={<HomeCommun></HomeCommun>}/> */}
            <Route path="/world" element={<World></World>} />
            <Route path="/feed" element={
              <PrivateRoute>
                <HomeVisualizationCenter></HomeVisualizationCenter>
              </PrivateRoute>
            } />
            <Route exact path="/home/*" element={
              <PrivateRoute>
                <HomeVisualizationCenter />
              </PrivateRoute>
            } />
            <Route path="*" element={<Navigate to="/" />} />

            <Route exact path="/marketplace" element={
              <HomeTalenscope />
            } />
            <Route exact path="/center" element={
              <PrivateRoute>
                <HomeVisualizationCenter />
              </PrivateRoute>
            } />
            <Route exact path="/live" element={<Live></Live>} />
            <Route exact path="/events" element={
              <PrivateRoute>
                <EventsPage></EventsPage>
              </PrivateRoute>
            } />
            <Route exact path="/room" element={
              <PrivateRoute>
                <DailyChat />
              </PrivateRoute>
            } />
            <Route exact path='/product/:id' element={<ProductDetails></ProductDetails>}></Route>
            <Route exact path='/failed' element={<PrivateRoute><Cancel /></PrivateRoute>} />
            <Route exact path='/order/:id' element={<PrivateRoute><Success /></PrivateRoute>} />
            <Route exact path='/orders' element={<PrivateRoute><Orders /></PrivateRoute>} />
            <Route exact path='/human-collaboration' element={<PrivateRoute><Collaboration /></PrivateRoute>}></Route>
            <Route exact path='/application' element={<PrivateRoute><ApplicationView /></PrivateRoute>}></Route>
            <Route exact path='/application-success' element={<PrivateRoute><ApplicationSuccess /></PrivateRoute>}></Route>
          </Routes>
        </CartProvider>
      </AuthContextProvider>
    </BrowserRouter>

  )
};

function UserVerifiedRoute({ children }) {
  const { user, loading } = useContext(AuthContext)

  if (!loading && user) {
    // This will restrict the user according to their state.

    if (!user.formId || !user.responseId) {
      return <ApplicationView />
    }
    
    return children

  } else {
    return (
      <div className='h-100 container d-flex align-items-center justify-content-center mx-auto'>
        <div className='spinner'><div className="spinner-border"></div></div>
      </div>
    )
  }
}


function PrivateRoute({ children }) {
  let token = localStorage.getItem('token');
  const location = useLocation();

  if (token) {
    return (<UserVerifiedRoute>{children}</UserVerifiedRoute>)
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />
  }
  // const { user } = useContext(AuthContext);

  // return user ? children : <Navigate to="/" />;
}


export default App;
