import React, { useEffect, useState } from "react";
import Banner from "../Banner/banner";
import axios from "axios";
import './hero.css'

const Hero = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const _token = localStorage.getItem('token');
    axios.get(`${process.env.REACT_APP_API_URL}/api/v1/sections/hero-section`, {
      headers: {
        Authorization: `Bearer ${_token}`
      }
    }).then(res => {
      setData(res.data);
    })
  }, [])

  if(!data){
    return (
      <></>
    )
  }

  return (
    <div className="mt-5 hero-container position-relative px-2 ">
      <div className="position-absolute w-100 bottom-0 hero-content" style={{zIndex : 10}}>
      <div
        className="text-white"
        style={{ zIndex: 2, maxWidth: 444 }}
      >
        <div className="f-24 bold">{data.title}</div>
        <div className="f-14">
          {data.description}
        </div>
      </div>
      <img
        src="/assets/images/man.png" 
        className="end-0 bottom-0 hero-content-image"
      />
      <div
      >
        <span className="tag cta-bg px-4 py-2 cursor-pointer f-20" onClick={() => {
          window.location.href = data.url
          return null
        }}>Redeem</span>
      </div>
      </div>


    
      
      <Banner
        height={500}
        imageSrc={data.backgroundImg}
        radius={5}
        gradient={"left"}
      />
      
    </div>
  );
};

export default Hero;
