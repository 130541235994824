import React, { useContext, useState } from 'react'
import { BsArrowLeft, BsArrowRight, BsEye, BsEyeSlash } from 'react-icons/bs'
import Renderif from '../../components/helpers/renderif'
import { FaBullseye, FaMusic } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { Login, Service } from '../../services/api'
import { AlertContext } from '../../context/alert_context'
import { AuthContext } from '../../context/auth_context'
import GoogleSSO from '../components/google_sso'
import Background from '../components/background/Background.jsx'

export default function LoginView() {

    const navigate = useNavigate()
    const { notify } = useContext(AlertContext)
    const { setUser } = useContext(AuthContext)

    const location = useLocation();
    const from = location.state?.from?.pathname || '/';

    const [state, setState] = useState({
        step: 0,
        loading: false,
        email: '',
        password: '',
        show_password: false
    })

    const onLogin = async () => {

        setState({ ...state, loading: true })
        try {
            let _login = await Login({ email: state.email.toLowerCase(), password: state.password })
            let user;
            let body = {
                token: _login.data.token
            }
            let _me = await Service('POST', 'auth/me', body);
            user = _me.data;
            user.token = _login.data.token
            navigate(from, { replace: true });
            setUser({ user })
            notify({ title: 'Login!', message: `Welcome! ${user.nombre} `, type: 'success' })

        } catch (err) {
            notify({ title: 'Login!', message: `Something went wrong!`, type: 'error' })
            setState({ ...state, loading: false })
            return
        }

        // console.log("login-res", _login)
    }


    return (
        <div className='position-relative h-100 w-100 overflow-hidden' style={{backgroundImage : `url(${'/assets/images/login_bg.jpg'})`, backgroundPosition : 'center', backgroundSize : 'cover'}}>
            {/* <Background /> */}
            {/* <div className='position-absolute'>
                <img src='/assets/images/login_bg.jpg' className='image-fluid' />
            </div> */}
            <div className='login_view'>

                <div className={`backdrop-login ${state.step === 0 ? 'd-center' : ''}`}>

                    <Renderif isTrue={state.step === -1}>
                        <div className={`position-relative block-1 w-100 d-flex justify-content-center`} style={{ height: 'calc(100vh - 50px)' }}>

                            <div className='h-100 w-fit scale-2'>

                                <div className={`item-center item button-pulse pulse-login ${!state.visible ? 'opacity-1' : ''}`}>
                                    <div className="button__wrapper ">
                                        <div className="pulsing"></div>
                                        <button className='button' onClick={() => {
                                            // setState({...state, visible:false})
                                            // props.onClick()
                                            setState({ ...state, step: 0 })

                                        }}>
                                            <FaBullseye size={30} color='#666'></FaBullseye>
                                        </button>

                                    </div>
                                    <div
                                        className='btn-title'
                                        tyle={{
                                            width: 200,
                                            background: 'red'
                                        }}>
                                        Visualization Center
                                    </div>
                                </div>

                                <img className='d-block mx-auto build' src='assets/images/loginbg.jpeg'></img>

                                <div className='login_footer'>
                                    <div className='f-18 bold text-center'>Visualization Center</div>
                                    <div className='f-14 text-center text-gray line-1'>Mexico City</div>
                                </div>

                            </div>

                        </div>
                    </Renderif>
                    <Renderif isTrue={state.step === 0}>
                        <div className={`container login-container`}>
                            <div className={`v-card d-flex flex-row opacity-transition`}>
                                <div className='col-12 login-card-padding'>

                                    <div className="logo-wrapper">
                                        <img src="/assets/images/logo.svg" className="image-fluid" />
                                    </div>

                                    <div className='d-flex flex-column justify-content-center w-100 mt-5 p-2'>
                                        <div className='f-26 bol login-font-color'>Elevate Your Leadership,</div>
                                        <div className='f-22 bol login-font-color'>Transform Your Organization</div>

                                        <div className='mt-2 mb-4'>Welcome back! Please login to your account</div>

                                        <div className='textbox ps-0 mt-3'>
                                            <div className='f-12 bold line-1'>E-mail</div>
                                            <input value={state.email} onChange={(evt) => {
                                                setState({ ...state, email: evt.currentTarget.value })
                                            }} placeholder='jonh@email.com' className='text'></input>
                                        </div>
                                        <div className='textbox ps-0 mt-3'>
                                            <div className='f-12 bold line-1'>Password</div>
                                            <div className='d-flex'>
                                                <input type={state.show_password ? 'text' : 'password'} value={state.password} onChange={(evt) => {
                                                    setState({ ...state, password: evt.currentTarget.value })
                                                }} placeholder='Enter at least 8 characters' className='text'></input>
                                                <div className='bv-icon bv-icon--small cursor-pointer' onClick={() => {
                                                    setState({ ...state, show_password: !state.show_password })
                                                }}>
                                                    <Renderif isTrue={state.show_password}>
                                                        <BsEye size={30} color='#000'></BsEye>
                                                    </Renderif>
                                                    <Renderif isTrue={!state.show_password}>
                                                        <BsEyeSlash size={30} color='#000'></BsEyeSlash>
                                                    </Renderif>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='mt-3'></div>
                                        <Renderif isTrue={state.loading}>
                                            <div className='spinner'><div className="spinner-border spinner--small"></div></div>
                                        </Renderif>


                                        <Renderif isTrue={!state.loading}>
                                            <div className='d-flex gap-3'>
                                                <div className='cursor-pointer login-cta cta-bg' onClick={() => {
                                                    onLogin()
                                                }}>
                                                    <div>Log In</div>
                                                </div>
                                                <div className='cursor-pointer login-cta login-cta-border' onClick={() => {
                                                    navigate('/register')
                                                }}>
                                                    <div>Sign Up</div>
                                                </div>
                                            </div>
                                        </Renderif>

                                        <div className='d-flex gap-3 f-13 bold mt-5'>
                                            <div>
                                                Login with
                                            </div>

                                            <GoogleSSO />
                                        </div>

                                        {/* <div className='mt-3'>
                                            <div className='text-dark-green text-decoration-underline cursor-pointer' onClick={() => {
                                                navigate('/register')
                                            }}>Create new account</div>
                                        </div> */}
                                    </div>

                                </div>
                            </div>

                            {/* <Renderif isTrue={state.step === 1}>
                <div className='v-card d-flex flex-row'>
                        <div className='col-6'>

                            <div className='d-flex flex-column justify-content-center align-items-center w-100 h-100 px-5'>

                                <div className='f-32 bolder text-black'>Step 1 Persona</div>

                                <div className='tile-opt mt-3'>
                                    <div className='me-2'>
                                        <div className='avatar'></div>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 text-black'>Text</div>
                                    </div>
                                    <div>
                                        <div className='bv-icon bv-icon--mid'>
                                            <BsArrowRight size={30} color='#294C53'></BsArrowRight>
                                        </div>
                                    </div>
                                </div>

                                <div className='tile-opt mt-3'>
                                    <div className='me-2'>
                                        <div className='avatar'></div>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 text-black'>Text</div>
                                    </div>
                                    <div>
                                        <div className='bv-icon bv-icon--mid'>
                                            <BsArrowRight size={30} color='#294C53'></BsArrowRight>
                                        </div>
                                    </div>
                                </div>

                                <div className='tile-opt mt-3'>
                                    <div className='me-2'>
                                        <div className='avatar'></div>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 text-black'>Text</div>
                                    </div>
                                    <div>
                                        <div className='bv-icon bv-icon--mid'>
                                            <BsArrowRight size={30} color='#294C53'></BsArrowRight>
                                        </div>
                                    </div>
                                </div>

                                <div className='tile-opt mt-3'>
                                    <div className='me-2'>
                                        <div className='avatar'></div>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 text-black'>Text</div>
                                    </div>
                                    <div>
                                        <div className='bv-icon bv-icon--mid'>
                                            <BsArrowRight size={30} color='#294C53'></BsArrowRight>
                                        </div>
                                    </div>
                                </div>

                                <div className='tile-opt mt-3'>
                                    <div className='me-2'>
                                        <div className='avatar'></div>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 text-black'>Text</div>
                                    </div>
                                    <div>
                                        <div className='bv-icon bv-icon--mid'>
                                            <BsArrowRight size={30} color='#294C53'></BsArrowRight>
                                        </div>
                                    </div>
                                </div>

                               <div className='d-flex align-items-center w-100 mt-3'>

                                    <div className=''>
                                        <div className='v-cta v-cta--light cursor-pointer rounded-3  px-3' style={{width:'fit-content'}} onClick={()=>{
                                            setState({...state, step:0})
                                        }}>
                                            <div className='bv-icon bv-icon--mid'>
                                                <BsArrowLeft size={20} color='#000'></BsArrowLeft>
                                            </div>
                                            <div className='text-black'>Back</div>
                                        </div>
                                    </div>

                                    <div className='col'></div>

                                   <div className=''>
                                        <div className='v-cta v-cta--primary cursor-pointer rounded-3' onClick={()=>{
                                            setState({...state, step:2})
                                        }}>
                                            <div className='ps-3 f-14'>Next</div>
                                            <div className='bv-icon bv-icon--mid'>
                                                <BsArrowRight></BsArrowRight>
                                            </div>
                                        </div>
                                   </div>

                               </div>

                                
                            </div>


                            
                        </div>
                        <div className='col-6 d-flex justify-content-center align-items-center bg-light'>
                            <img className='img-fluid py-5 px-5' src='/assets/images/image_60.png'></img>
                        </div>
                </div>
            </Renderif>
            <Renderif isTrue={state.step === 2}>

                <div className='v-card d-flex flex-row' style={{paddingBottom:'4rem'}}>
                        <div className='col-6 d-flex flex-column justify-content-center align-items-center bg-light'>
                            <div className='d-flex flex-column justify-content-lg-start align-items-start'>
                                <div className='f-14 text-dark-green line-1'>Tell us</div>
                                <div className='f-32 bolder text-black line-1'>Step 2 Itereses</div>
                            </div>
                            <img width="60%" className='img-fluid py-5 px-5' src='/assets/images/image_62.png'></img>
                        </div>

                        <div className='col-6'>

                            <div className='d-flex flex-column  w-100 h-100 px-5 overflow-auto hide-scroll' >

                               
                               
                                
                               

                                {[1,1,1,1].map((item, i)=>{
                                    return (
                                        <div className='d-flex flex-column sh r-5 p-3 mt-3'>

                                        <div className='d-flex justify-content-start align-items-center'>
                                            <div className='bv-icon bv-icon--mid'>
                                                <FaMusic></FaMusic>
                                            </div>
                                            <div className='f-14 bold'>Music</div>
                                        </div>
    
                                        <div className='d-flex flex-wrap py-3'>
                                            <div className='v-tag v-tag--active'>Tag</div>
                                            <div className='v-tag'>Tag</div>
                                            <div className='v-tag'>Tag</div>
                                            <div className='v-tag'>Tag</div>
                                            <div className='v-tag'>Tag</div>
                                            <div className='v-tag'>Tag</div>
                                            <div className='v-tag'>Tag</div>
                                            <div className='v-tag'>Tag</div>
                                            <div className='v-tag'>Tag</div>
                                            <div className='v-tag'>Tag</div>
                                            <div className='v-tag'>Tag</div>
                                            <div className='v-tag'>Tag</div>
                                            <div className='v-tag'>Tag</div>
                                            <div className='v-tag'>Tag</div>
                                            <div className='v-tag'>Tag</div>
                                        </div>
                                    </div>
                                    )
                                })}

                                <div className='my-5'></div>

                                
                            </div>


                            
                        </div>

                        <div className='step-controls'>

                            <div className='d-flex align-items-center w-100'>
                                <div className='cursor-pointer' onClick={()=>{
                                    navigate('/home')
                                }}>Skip</div>
                                <div className='col'></div>

                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className='v-cta v-cta--primary cursor-pointer rounded-3' onClick={()=>{
                                    navigate('/home')
                                }}>
                                        <div className='ps-3 f-14'>Next</div>
                                        <div className='bv-icon bv-icon--mid'>
                                            <BsArrowRight></BsArrowRight>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                       
                </div>
            </Renderif> */}


                        </div>
                    </Renderif>
                </div>
            </div>
        </div>
    )
}
