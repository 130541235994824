import React, { useRef, useState } from 'react'
import { Html, useGLTF, useTexture } from '@react-three/drei'
import { MeshStandardMaterial, TextureLoader } from 'three'
import { useLoader } from '@react-three/fiber'
import { FaBullseye } from "react-icons/fa";

export function Octahedron(props) {

  const [state, setState] = useState({
    visible:true,
  })

  
  return (
    <group {...props} dispose={null}>
      <mesh
        // castShadow
        // receiveShadow
        // geometry={nodes.Object_2.geometry}
        // material={ materials['Scene_-_Root']}
        // rotation={[-Math.PI / 2, 0, 0]}
      >
        <Html  zIndexRange={[1, 0]} style={{backgroundColor:'transparent', zIndex:1, width:240, flexDirection:'column', display:'flex', justifyContent:'center', alignItems:'center'}}>

          

          <div  className={`item-center item button-pulse ${!state.visible ? 'opacity-0':''}`}>
            

            <div className="button__wrapper ">
              <div className="pulsing"></div>
              <button className='button' onClick={()=>{
                setState({...state, visible:false})
                props.onClick()
              }}>
                <FaBullseye size={30} color='#3c77a1'></FaBullseye>
              </button>
              
            </div>
            <div 
              className='btn-title'
              tyle={{
                width:200, 
                background:'red'
              }}>
              Education Center
            </div>
          </div> 
        </Html>
      </mesh>

      {/* <mesh> */}
      {/* <sphereGeometry args={[1, 32, 32]} /> */}
      {/* <meshStandardMaterial
        displacementScale={0.2}
        map={colorMap}
        displacementMap={displacementMap}
        normalMap={normalMap}
        roughnessMap={roughnessMap}
        aoMap={aoMap}
      /> */}
    {/* </mesh> */}
    </group>
  )
}

useGLTF.preload('/models/octahedron/scene.gltf')