import React, { useEffect, useMemo, useState } from "react";
import {
  BsArrowBarDown,
  BsCheck,
  BsHeart,
  BsPlay,
  BsPlus,
  BsSend,
  BsStar,
  BsStarFill,
} from "react-icons/bs";

import { HiPhoto } from "react-icons/hi2";
import { IoAttach, IoThumbsUp } from "react-icons/io5";
import { MdOutlineThumbUpAlt } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import Renderif from "../../../helpers/renderif.js";
import { FaRegCircle } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";
import { RxAvatar } from "react-icons/rx";
import Header from "../../../layout/header.js";
import Footer from "../../../layout/footer.js";
import ChatAI from "../../../chatai/ChatAi.jsx";
import { ChatAIcon } from "../../../../views/user/pages/visualization_center/home_visualization_center.js";
import Banner from "../../../Banner/banner.jsx";
import Accordion from "react-bootstrap/Accordion";
import { FaStar } from "react-icons/fa6";
import { IoPlayOutline } from "react-icons/io5";
import FooterBanner from "../../../FooterBanner/FooterBanner.jsx";
import axios from "axios";
import { IoMdHeart } from "react-icons/io";
import { useParticipantIds } from "@daily-co/daily-react-hooks";
import CallParticipant from "./CallParticipant/CallParticipant.jsx";
import LiveEvent from "../../../LiveEvent/LiveEvent.jsx";
import { format } from "@formkit/tempo";
import { getWeekNumberFromDay } from "../../../../views/user/event_detail_view.js";
import CustomToggle from "../../../CustomAccordionToggle/CustomToggle.jsx";
import CommentBox from "../../../Comment/CommentBox.jsx";
import CollaborationForm from "../../../Collaboration/CollaborationForm.jsx";

export default function CallView(props) {
  const { chat, call, session } = props;
  let participants = useParticipantIds();
  const { courseId } = session;
  // const navigate = useNavigate();
  // const location = useLocation();

  const [state, setState] = useState({
    tab_active: 0,
    opt_selected: [],
    activity_selected: null,
    activity_index: 0,
    show_chatia: false,
    course: null,
    participants: [],
  });

  const [progress, setProgress] = useState(0);

  const updateProgress = () => {
    const { startDate, endDate } = session;
    const currentTime = new Date().getTime();
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();

    if (currentTime < start) {
      setProgress(0); // Progress hasn't started
      return;
    }
    if (currentTime > end) {
      setProgress(100); // Progress is complete
      return;
    }

    const totalTime = end - start;
    const elapsedTime = currentTime - start;
    const newValue = (elapsedTime / totalTime) * 100;
    setProgress(newValue);
  };

  useEffect(() => {
    // Call updateProgress immediately and then set up an interval
    updateProgress();
    const interval = setInterval(updateProgress, 1000); // Update every second

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, [session]);

  useEffect(() => {
    const fetchCourse = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/course/${courseId}`
      );
      setState({ ...state, course: res.data });
    };
    fetchCourse();
  }, []);

  return (
    <div className="container-fluid overflow-y-auto hide-scroll">
      <Header></Header>

      <div className="container pt-3">
        <div className="f-14 bold px-2">
          {session.name} {"> in Progress"}
        </div>
        <h3 className="px-2">{session.activities[0].activity.name}</h3>
        <div className="d-flex align-items-center gap-3 f-14 my-2 px-2">
          <div>
            W{getWeekNumberFromDay(format(new Date(session.startDate), "D"))} -{" "}
            {format(new Date(session.startDate), "MMMM YY")}
          </div>
          {/* <div className="d-flex login-font-color align-items-center">
            <FaStar />
            <FaStar />
            <FaStar />
            <FaStar />
          </div>
          <div>4.1(223 reviews)</div> */}
        </div>
        <div className="d-flex flex-wrap">
          <div className="col-12 col-md-9 px-3 px-md-0">
            <div className="mt-4">
              <div className="d-flex overflow-hidden mb-4 video-container-event mt-4">
                {/* <video src='/assets/video/demo_video.mp4' loop muted autoPlay>
                                </video> */}
                {call}
              </div>
            </div>

            {/* Call Tray */}

            {/* Interactive Tabs */}
            <div className="d-flex flex-wrap mt-5 gx-5">
              <div className="col-12 order-1 order-md-0 px-2">
                <div className="bg-white rounded-3">
                  <div className="d-flex gap-4 hide-scroll">
                    <div
                      className={`px-3 py-1 f-14 cursor-pointer ${
                        state.tab_active === 0 ? "cta-bg" : ""
                      } tag`}
                      onClick={() => {
                        setState({ ...state, tab_active: 0 });
                      }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Your AI 5.0 Skills
                    </div>
                    <div
                      className={`px-3 py-1 f-14 cursor-pointer ${
                        state.tab_active === 1 ? "cta-bg" : ""
                      } tag`}
                      onClick={() => {
                        setState({ ...state, tab_active: 1 });
                      }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Collaboration
                    </div>
                    <div
                      className={`px-3 py-1 f-14 cursor-pointer ${
                        state.tab_active === 2 ? "cta-bg" : ""
                      } tag`}
                      onClick={() => {
                        setState({ ...state, tab_active: 2 });
                      }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Chat
                    </div>
                    <div
                      className={`px-3 py-1 f-14 cursor-pointer ${
                        state.tab_active === 3 ? "cta-bg" : ""
                      } tag login-cta-border`}
                      onClick={() => {
                        setState({ ...state, tab_active: 3 });
                      }}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Intelligence Brief
                    </div>
                  </div>
                  <Renderif isTrue={state.tab_active === 0}>
                    <div className="w-100 mt-3">
                      <div className="text-center f-22 bold">AI 5.0 Skills</div>
                      <div className="mt-3 text-center w-75 mx-auto">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Incidunt sed sunt doloribus laudantium ullam qui
                        quas aspernatur vero minus totam?
                      </div>
                      {state.course && (
                        <Accordion className="mt-3">
                          {state.course.skills.map((skill, index) => (
                            <Accordion.Item
                              key={skill.id}
                              className="border-0"
                              eventKey={skill.id}
                            >
                              <CustomToggle eventKey={skill.id}>
                                {skill.name}
                              </CustomToggle>
                              {/* <Accordion.Header
                                className="bg-light mb-3"
                                style={{ borderRadius: "3rem" }}
                              >
                                {skill.name}
                              </Accordion.Header> */}
                              <Accordion.Body>
                                {skill.description}
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      )}
                    </div>
                  </Renderif>
                  <Renderif isTrue={state.tab_active === 1}>
                    <CollaborationForm />
                  </Renderif>
                  <Renderif isTrue={state.tab_active === 2}>
                    <div className="py-3">{chat}</div>
                  </Renderif>

                  {/* Community */}
                  <CommentBox sessionId={session.id} />

                  {/* Participants */}
                  <div className="my-5 py-5">
                    <div className="f-18 mb-4">Participants</div>
                    <div className="row">
                      {participants.map((sessionId) => (
                        <CallParticipant
                          key={sessionId}
                          sessionId={sessionId}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* sidebar */}
          <div className="col-12 col-md-3 px-4">
            <Renderif isTrue={state.course?.sessions}>
              <Renderif
                isTrue={courseId !== "7c3497c1-6b37-400c-a008-0bfa20049954"}
              >
                {/* Activity tab */}
                <div className="text-center f-14 bold mb-3">Sessions</div>
                <div className=" bg-light p-3 rounded-5">
                  {state.course &&
                    state.course.sessions.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className={`d-flex f-12 align-items-center mt-1 px-3 py-1 tag cursor-pointer text-gray opacity-75 ${
                            session.id === item.id ? " cta-bg" : ""
                          }`}
                        >
                          <div className="bold">{item.name}</div>

                          <div className="col"></div>

                          <div className="bv-icon bv-icon--mid">
                            {session.id === item.id ? (
                              <IoPlayOutline size={30} />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Renderif>

              {/* Progress bar */}
              {state.course && (
                <div className="mt-3">
                  <div className="f-16 text-center">Progress</div>
                  <div
                    className="w-100 tag bg-gray-10 position-relative overflow-hidden mt-2"
                    style={{ height: "12px" }}
                  >
                    <div
                      className="h-100 cta-bg start-0 tag"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                  <div className="f-14 mt-1 text-center">
                    {progress}% completed
                  </div>
                </div>
              )}
            </Renderif>
            {/* Live event banner */}
            <div className="mt-3">
              <LiveEvent />
            </div>

            {/* Calendar speaker's hub */}
            {/* <div className="my-5">
              <div className="text-center f-14 bold">Speaker's Hub</div>
              <div className="mt-4">
                <div className="f-12 mb-2">Sept 22</div>
                <div className="d-flex bg-gray-10 rounded-5 justify-content-around py-3 gap-3">
                  <div className="d-flex flex-column justify-content-center align-items-center w-50">
                    <div className="f-20 login-font-color">09:00</div>
                    <div className="f-12 text-gray">Live Webinar</div>
                  </div>
                  <div className="flex-grow-1">
                    <div className="f-12 bold">Social Impact of AI</div>
                    <div className="f-12 text-gray">John Davis</div>
                    <div className="f-12 text-gray">60 mins</div>
                    <div className="f-12 text-gray">URL</div>
                  </div>
                </div>
                <div className="d-flex bg-gray-10 rounded-5 justify-content-around py-3 gap-3 mt-3">
                  <div className="d-flex flex-column justify-content-center w-50 align-items-center">
                    <div className="f-20 login-font-color">12:15</div>
                    <div className="f-12 text-gray">Live Webinar</div>
                  </div>
                  <div className="flex-grow-1">
                    <div className="f-12 bold">Legal AI</div>
                    <div className="f-12 text-gray">Darinka Wallace</div>
                    <div className="f-12 text-gray">60 mins</div>
                    <div className="f-12 text-gray">URL</div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="f-12 mb-2">Sept 23</div>
                <div className="d-flex bg-gray-10 rounded-5 justify-content-center py-3 gap-3">
                  <div className="d-flex flex-column justify-content-center w-50 align-items-center">
                    <div className="f-20 login-font-color">09:00</div>
                    <div className="f-12 text-gray">Live Webinar</div>
                  </div>
                  <div className="flex-grow-1">
                    <div className="f-12 bold">Social Impact of AI</div>
                    <div className="f-12 text-gray">John Davis</div>
                    <div className="f-12 text-gray">60 mins</div>
                    <div className="f-12 text-gray">URL</div>
                  </div>
                </div>
                <div className="d-flex bg-gray-10 rounded-5 justify-content-around py-3 gap-3 mt-3">
                  <div className="d-flex flex-column justify-content-center w-50 align-items-center">
                    <div className="f-20 login-font-color">13:30</div>
                    <div className="f-12 text-gray">Live Webinar</div>
                  </div>
                  <div className="flex-grow-1">
                    <div className="f-12 bold">The Radiate</div>
                    <div className="f-12 text-gray">Alberado Gonzalez</div>
                    <div className="f-12 text-gray">60 mins</div>
                    <div className="f-12 text-gray">URL</div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <FooterBanner />

      <Footer></Footer>
    </div>
  );
}
