
import { Environment, OrbitControls, Html, useProgress, useHelper, useGLTF } from '@react-three/drei';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber'
import { Suspense, useEffect, useMemo, useRef, useState } from 'react';

import * as THREE from 'three'
import CameraControls from 'camera-controls'
import { Vector3 } from 'three';
import { Octahedron } from '../../components/models/octahedron';
import { DirectionalLightHelper } from 'three';
import { useNavigate } from 'react-router-dom';
import CameraPositionLogger from '../../components/helpers/camera_position_logger';
import { Island } from '../../components/models/island';

function Scene() {
 
  console.log('models/island/scene.gltf')
//   const gltf = useLoader(GLTFLoader, 'models/island/scene.gltf')
// const { gltf } = useGLTF(
//     "https://virtual-school-models-bucket.s3.amazonaws.com/island/scene.gltf"
//   );
    // const gltf = useLoader(GLTFLoader, 'https://virtual-school-models-bucket.s3.amazonaws.com/island/scene.gltf')
    const gltf = useLoader(GLTFLoader, '/models/island/scene.gltf')
  
  return <primitive object={gltf.scene} scale={[1,1,1]} rotation={[0,0,0]} />
  
}

function Overlay (props){

    console.log(props.show)
    const navigate = useNavigate()

    useEffect(()=>{
        console.log(props.show)
        if(!props.show){
            setTimeout(()=>{
                navigate('/home')
            },3000)
        }
    },[props.show])

    return (
      <div className={`entry-overlay ${props.show ? 'opacity-0':''}`}>

    </div>
    )


}

function Loader() {
  const { progress } = useProgress()

  return (
    <Html >
        
        <div className='world-overlay'>
        <div className='d-flex flex-column align-items-center'>
            <div className='f-32 text-blue'>{progress.toFixed(0)}%</div>
            <div className='_progress'>
              <div className='_progress-bar' style={{width:progress+'%'}}></div>
            </div>
        </div>
        </div>
    </Html>
  )
}

CameraControls.install({ THREE })

function Cloud({ momentsData, zoomToView }) {
  return  <>

  {/* <mesh position={[-22, 20, 19]} onClick={(e) => {
    // zoomToView(e.object.position)
  }}>
    <boxGeometry args={[5,5,5]} />
    <octahedronGeometry  radius={10} />

    <meshStandardMaterial color={'red'} />
  </mesh> */}
  <Octahedron scale={[2,2,2]}  position={[0, 38, 30]} onClick={(evt)=>{
   
    zoomToView(new Vector3(-40, 2, 40), new Vector3(-45, 2, 37))


  }}></Octahedron>

  <mesh position={[-47, 2, 36]} rotation={[0,1,1.6]} onClick={(e) => {
    <div></div>
    // zoomToView(e.object.position)
    // zoomToView(new Vector3(-35, 2, 42))
  }}>
    <boxGeometry args={[6,6,6]}  />
    <meshStandardMaterial color={'white'} />
  </mesh>
  </>
}

function Controls({ zoom, focus, target, pos = new THREE.Vector3(), look = new THREE.Vector3(), init }) {

    const camera = useThree((state) => state.camera)

    const gl = useThree((state) => state.gl)
    const controls = useMemo(() => new CameraControls(camera, gl.domElement), [])
    controls.enabled = false;

    // console.log(controls.camera.position)
    // controls.camera.position.set(230, 200,0)
    controls.camera.lookAt(new THREE.Vector3(0, 130, 0))


    // controls.setLookAt(230, 200,0, 0, 130, 0, false)
    // controls.update()

    // console.log(zoom)

    // if(zoom){
    //     controls.setLookAt(-40, 2, 40, -45, 2, 37, true)
    //     controls.update()
    // }

    let deltaCurrent = 0
    
    let clock = new THREE.Clock();
    let _delta = 0;
    // 30 fps
    let interval = 1 / 15;

    return useFrame((state, delta) => {
        
      

        _delta += clock.getDelta();

       if (_delta  > interval) {
           // The draw or time dependent code are here
       
            zoom ? pos.set(focus.x, focus.y, focus.z) : pos.set(230, 120, 0)
            zoom ? look.set(target.x, target.y, target.z ) : look.set(0, 0, 0)
        
            state.camera.position.lerp(pos, 0.4)

            state.camera.updateProjectionMatrix()
        
            controls.setLookAt(state.camera.position.x, state.camera.position.y, state.camera.position.z, look.x, look.y, look.z, true)
           _delta = _delta % interval;
           
       }
       return controls.update(delta)
        
        
        // zoom ? pos.set(focus.x, focus.y, focus.z) : pos.set(230, 120, 0)
        // zoom ? look.set(target.x, target.y, target.z ) : look.set(0, 0, 0)
    
        // state.camera.position.lerp(pos, init ? 0.2 : 0.4)

        // state.camera.updateProjectionMatrix()
    
        // controls.setLookAt(state.camera.position.x, state.camera.position.y, state.camera.position.z, look.x, look.y, look.z, true)

        // return controls.update(delta)
    })


}


function Light  () {
  const dirLight = useRef(null);
//   useHelper(dirLight, DirectionalLightHelper,1, "red");

  return (
    <>
       <directionalLight position={[0, 100, -100]} color={"white"} castShadow={true} intensity={15}  ref={dirLight} />
    </>
  );
};

function World() {

  const [zoom, setZoom] = useState(false)
  const [focus, setFocus] = useState({})
  const [target, setTarget] = useState({})
  const [_init, _setInit] = useState(true)
  const [loading, setLoading] = useState(true)

  const { progress } = useProgress()
  console.log(progress)



  
  const init = [230, 120, 0]
    useEffect(()=>{
        if(loading === true && progress == 100 ){
            setTimeout(()=>{
                setLoading(false)
            },100)
        }

    },[progress])

  return (
    <div className="App">

      <Overlay show={_init}></Overlay>
     
      <div className={`entry-overlay-2 ${!loading? 'opacity-0':''}`}>
        
        <div className={`flex-column justify-content-center align-items-center h-100 ${progress === 100 ? 'd-none':'d-flex'}`}>
            <div className='f-32 text-blue lighter'>{progress.toFixed(0)}%</div>
            <div className='_progress'>
              <div className='_progress-bar' style={{width:progress+'%'}}></div>
            </div>
        </div>

      </div>
    
      <Canvas  linear camera={{ fov:40, near:1, far:700, position:[230,0,0]}}
       shadows={true}>
        
        <Suspense fallback={null}>

            

            {/* <axesHelper args={[1000]}></axesHelper> */}
            
            <hemisphereLight color={"orange"} intensity={2} position={[0, 500, 0 ]} ></hemisphereLight>
            <ambientLight color={"white"}  intensity={1} />
            <Light></Light>
          
          {/* <directionalLight position={[0, 0, 0]} color={"white"} intensity={10} castShadow={true} ref={dirLight} /> */}
        
          {/* <Watermelon scale={[0.1,0.1,0.1]}></Watermelon> */}
          {/* <Pirate scale={[20,20,20]}></Pirate> */}
          <Cloud  zoomToView={(focusRef, _target) => {
            _setInit(false)
            setZoom(true) 
            setFocus(focusRef) 
            setTarget(_target)
          }} />

          {/* <mesh position={[0,10,0]} onClick={(e) => {
            // zoomToView(e.object.position)
          }}>
            <boxGeometry args={[5, 5, 5]} />
            <meshStandardMaterial color={'red'} />
          </mesh> */}

          <Scene></Scene>
          {/* <OrbitControls /> */}
          {/* <Island></Island> */}
          {
            !loading ? <Controls zoom={zoom} focus={focus} target={target} init={_init}/> : <></>
          }
          
         
       
          {/* <CameraPositionLogger event='click'></CameraPositionLogger> */}
          

        </Suspense>
      </Canvas>

      

    




      <div>
        <button onClick={()=>{
        
          setZoom(true)
          setFocus(new Vector3(-20.75, 1, 20))
        }}> GO to 1</button>
    

        <div>

        <div className='border w-100'>
        {focus.x === undefined ? init[0] : focus.x}
       <input className='w-100' type='range' min={-200} max={200} value={focus.x === undefined ? init[0] : focus.x} step={1} onChange={(evt)=>{
             setZoom(true)
             setFocus(new Vector3(evt.currentTarget.value, focus.y, focus.z))
        }} />
       </div>

       <div className='border w-100'>
        {focus.y === undefined ? init[1] : focus.y}
       <input className='w-100' type='range' min={-200} max={200} value={focus.y === undefined ? init[1] : focus.y} step={1} onChange={(evt)=>{
             setZoom(true)
             setFocus(new Vector3(focus.x, evt.currentTarget.value, focus.z))
        }} />
       </div>

        <div className='border w-100'>
        {focus.z === undefined ? init[2] : focus.z}
        <input className='w-100' type='range' min={-200} max={200} value={focus.z === undefined ? init[2] : focus.z} step={1} onChange={(evt)=>{
             setZoom(true)
             setFocus(new Vector3(focus.x, focus.y, evt.currentTarget.value))
        }} />
        </div>
        </div>

        



         <button onClick={()=>{
            setZoom(true)
            setFocus(new Vector3(init[0], init[1], init[2]))
            setTarget(new Vector3(0,0,))
        }}> Reset</button>
      </div>
     
    
    </div>
  );
}

export default World;
