import React, { useEffect, useState } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import "./productdetails.css";
import { useLocation, useParams } from "react-router-dom";
import { useCart } from "../../context/cart_context";
import axios from "axios";
import Renderif from "../helpers/renderif";
import Accordion from "react-bootstrap/Accordion";

const ProductDetails = () => {
  const { id } = useParams();

  const { addToCart } = useCart();

  const location = useLocation();

  const [productDetails, setProductDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProductDetails = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/products/${id}`
        );
        const data = res.data[0];
        setProductDetails(data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchProductDetails();
  }, []);

  // const [error, setError] = useState(null);

  return (
    <div className="hide-scroll">
      <Header />
      <div className="detail-page">
        <div className="container">
          <Renderif isTrue={loading}>
            <div className="h-100 container d-flex align-items-center justify-content-center mx-auto">
              <div className="spinner">
                <div className="spinner-border"></div>
              </div>
            </div>
          </Renderif>
          <Renderif isTrue={!loading}>
            <div className="row">
              <div className="col-sm-5">
                <div className="images-box">
                  <img
                    className="w-100"
                    src={`${productDetails?.thumbnail}`}
                  ></img>
                </div>
              </div>
              <div className="col-sm-7">
                <div class="pro-description">
                  <h2 className="pro-name">{productDetails?.name}</h2>
                  <h3 className="pro-price">
                    $ {productDetails?.price ? productDetails?.price : 500}
                  </h3>
                  <h3 className="head-names">Description</h3>
                  <p className="pro-desc">{productDetails?.description} </p>

                  <div className="buttn-cart">
                    <button
                      onClick={() => {
                        addToCart(productDetails);
                      }}
                      type="button"
                    >
                      Add To Cart
                    </button>
                  </div>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        What problem does it solve
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>{productDetails?.solveProblem}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>How does it solve it </Accordion.Header>
                      <Accordion.Body>
                        <p>{productDetails?.solveHow}</p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Integration ability </Accordion.Header>
                      <Accordion.Body>
                        <p>{productDetails?.integrationAbility}</p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Market Reputation</Accordion.Header>
                      <Accordion.Body>
                        <p>{productDetails?.marketReputation}</p>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        Customer Service level
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>{productDetails?.customerServiceLevel}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </Renderif>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetails;
