import { useMediaTrack, useVideoTrack } from "@daily-co/daily-react-hooks";
import "./alternateTile.css";

import React, { useEffect, useRef } from "react";
import TileVideo from "../TileVideo/TileVideo";

const AlternateTile = ({
  id,
  isScreenShare,
  isLocal,
  isAlone,
  isActive = false,
}) => {
  let containerCssClasses = "tile-video-webinar";
  let audioTrack = null;
  let audioElement = null;

  if (isActive) {
    containerCssClasses += " active-view";
  }

  if (isLocal ) {
    containerCssClasses += " self-view";
    if (isAlone) {
      containerCssClasses += " alone";
    }
    if(isScreenShare){
      containerCssClasses += " screen-share";
    }
  } else {
    // Only create audio track and element if the participant is not local.
    // audioTrack = useMediaTrack(id, isScreenShare ? 'screenAudio' : 'audio');
    // audioElement = useRef(null);
    // useEffect(() => {
    //   if (audioTrack?.state === 'playable') {
    //     if (audioElement?.current) {
    //       (audioElement.current.srcObject =
    //         audioTrack && new MediaStream([audioTrack.persistentTrack]));
    //     }
    //   }
    // }, [audioTrack]);
  }

  audioTrack = useMediaTrack(id, isScreenShare ? "screenAudio" : "audio");
  audioElement = useRef(null);

  const videoState = useVideoTrack(id);
  /* If a participant's video is muted, hide their video and
    add a different background color to their tile. */
  if (videoState.isOff) {
    containerCssClasses += " no-video";
  }

  useEffect(() => {
    if (!isLocal) {
      if (audioTrack?.state === "playable") {
        if (audioElement?.current) {
          audioElement.current.srcObject =
            audioTrack && new MediaStream([audioTrack.persistentTrack]);
        }
      }
    }
  }, [audioTrack]);

  return (
    <div className={containerCssClasses}>
      <TileVideo id={id} isScreenShare={isScreenShare} />

      {!isLocal && audioTrack && (
        <audio autoPlay playsInline ref={audioElement} />
      )}
    </div>
  );
};

export default AlternateTile;
