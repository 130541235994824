import React from "react";
import RateNumber from "./RateNumber";

const RatingSelector = ({ value, onChange }) => (
  <div className="mt-4 px-2">
    <div className="f-22 bold text-center">
      How user-friendly do you consider our app/product interface to be?
    </div>
    <div className="mt-4 d-flex justify-content-center">
      <RateNumber value={value} onChange={onChange} />
    </div>
  </div>
);

export default RatingSelector;
