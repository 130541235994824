import React, { useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../context/auth_context";

const CreateComment = ({ sessionId, refreshComments  }) => {
  const { user } = useContext(AuthContext);
  const [content, setContent] = useState(""); // State to track the comment content
  const [isLoading, setIsLoading] = useState(false); // State to track loading
  const [error, setError] = useState(""); // State to track errors

  // Handle input change
  const handleChange = (event) => {
    setContent(event.target.value);
  };

  // Handle form submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!content.trim()) {
      setError("Comment cannot be empty.");
      return;
    }

    setIsLoading(true);
    setError("");

    const _token = localStorage.getItem("token");

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/comment`,
        {
          content,
          sessionId,
          authorId: user.id,
        },
        {
          headers: {
            Authorization: `Bearer ${_token}`,
          },
        }
      );
      // console.log("Comment created:", response.data);
      setContent(""); // Clear the input field after submission
    } catch (err) {
      setError("Failed to create comment. Please try again later.");
    } finally {
      refreshComments()
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-4">
      <div className="f-18 bold mb-2">Leave a comment</div>
      <form onSubmit={handleSubmit} className="w-100">
        <div>
          <textarea
            type="text"
            value={content}
            onChange={handleChange}
            placeholder="Write your comment..."
            required
            disabled={isLoading}
            className="w-100 py-1 px-2 bg-gray-00 rounded-2 hide-scroll"
            style={{ border: "0px", outline: "0px", minHeight: "4rem" }}
          />
        </div>
        <button
          type="submit"
          className="cta-bg tag py-1 px-3 mt-2"
          disabled={isLoading}
        >
          Post Comment
        </button>
      </form>

      {error && <div style={{ color: "red" }}>{error}</div>}

      {isLoading && <div>Loading...</div>}
    </div>
  );
};

export default CreateComment;
