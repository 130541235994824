import React, { useState } from "react";
import Header from "../../../../components/layout/header";
import Footer from "../../../../components/layout/footer";
import CollaborationForm from "../../../../components/Collaboration/CollaborationForm";

const Collaboration = () => {

  return (
    <div className="hide-scroll">
      <Header />
      <CollaborationForm />
      <Footer />
    </div>
  );
};

export default Collaboration;
