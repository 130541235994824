import React, { useContext, useEffect, useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertContext } from '../../context/alert_context';
import { AuthContext } from '../../context/auth_context';
import { Service } from '../../services/api';
import Renderif from '../../components/helpers/renderif';


const GoogleSSO = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { notify } = useContext(AlertContext)
  const { setUser, setGoogleSSO } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    init()
    setLoading(false)
  }, [])

  const init = () => {
  }

  const signin = useGoogleLogin({
    onNonOAuthError: (error) => {
      setLoading(false)
    },
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } },
      );
      //trying to log in first 
      try {
        let body = {
          email: userInfo?.data?.email,
        }
        let _login = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/auth/google`, body)
        // console.log("login-sso:", _login)
        // Getting user info
        let user;
        body = {
          token: _login.data.token
        }
        let _me = await Service('POST', 'auth/me', body);
        // console.log("me:", _me)
        user = _me.data;
        user.token = _login.data.token
        setUser({ user })
        navigate('/')
        notify({ title: 'Login!', message: `Welcome! ${user.nombre} `, type: 'success' })

      } catch (err) {
        console.log("err-sso-google-login: ", err)
        // registering the user
        let body = {
          "email": userInfo?.data?.email,
          "password": `?@N!2*N?8nqPhe12QE%t${userInfo?.data?.email}`,
          "firstName": userInfo?.data?.given_name,
          "lastName": userInfo?.data?.family_name ? userInfo?.data?.family_name : ' '
        }
        setGoogleSSO({ enabled: true, data: body })
        if (location.pathname !== '/register') {
          navigate('/register')
        }
      }
    },
    onError: errorResponse => {
      console.log(errorResponse)
      setLoading(false)
      notify({ title: 'Google SSO!', message: `Something went wrong!`, type: 'error' })
    },
  });

  return (
    <>
      {/* <div className='mt-3'></div> */}
      <Renderif isTrue={loading}>
        <div><div className="spinner-border spinner--small"></div></div>
      </Renderif>
      <Renderif isTrue={!loading}>
        {/* <div className='v-cta v-cta--outline cursor-pointer' style={{ width: '100%', maxWidth: '360px' }} >
          <div onClick={(data) => {
            setLoading(true)
            signin(data)
          }} className='d-flex justify-content-center w-100'>
            <span className='h-100 me-2'>
              <svg width={26} height={26} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="LgbsSe-Bz112c"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
            </span>
            Continue with google
          </div>
        </div> */}

        <div onClick={(data) => {
            setLoading(true)
            signin(data)
          }} className='login-font-color cursor-pointer'>
          google
        </div>
      </Renderif>
    </>
  )
}


export default GoogleSSO