import React, { useContext, useEffect, useState } from 'react'
import { BsArrowLeft, BsArrowRight, BsEye, BsEyeSlash } from 'react-icons/bs'
import Renderif from '../../components/helpers/renderif'
import { FaMusic } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { LuBrain } from "react-icons/lu";
import { Login, Register, Service } from '../../services/api'
import { AlertContext } from '../../context/alert_context'
import { AuthContext } from '../../context/auth_context'
import Background from '../components/background/Background.jsx'
import GoogleSSO from '../components/google_sso.js'

export default function RegisterView() {

    const navigate = useNavigate()
    const { notify } = useContext(AlertContext)
    const { setUser, googleSSO } = useContext(AuthContext)

    const [state, setState] = useState({
        step: 0,
        loading: false,
        showPassword: true,
        userTypes: [],
        interests: [],
        industries: [],
        occupations: [],

        // username: "Tan",
        password: "",
        email: "",
        firstName: "",
        lastName: "",
        industry: "",
        occupation: "",
        userType: "",
        organization: "",
        interestsSelected: [],
        error_pass: false
    })

    useEffect(() => {
        init()
        return () => { }
    }, [])

    const groupBy = (arr, property) => {
        return arr.reduce(function (memo, x) {
            if (!memo[x[property]]) { memo[x[property]] = []; }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }

    const init = async () => {

        let _interests = await Service('GET', 'interests');
        let _occupations = await Service('GET', 'occupation');
        let _industries = await Service('GET', 'industry');
        let _usertypes = await Service('GET', 'userType');
        // console.log(_interests)

        let int = groupBy(_interests.data, 'category');
        let interests = []
        for (const property in int) {
            // console.log(`${property}: ${int[property]}`);
            interests.push({
                category: property,
                values: int[property]
            })

        }
        // console.log(interests);

        setState((prevState) => ({
            ...prevState,
            interests,
            occupations: _occupations.data,
            industries: _industries.data,
            userTypes: _usertypes.data
        }))
    }

    const getSSOInfo = () => {
        if (googleSSO.enabled) {
            setState((prevState) => ({
                ...prevState,
                password: googleSSO?.data?.password,
                email: googleSSO?.data?.email,
                firstName: googleSSO?.data?.firstName,
                lastName: googleSSO?.data?.lastName
            }))
        }
    }

    const checkPassword = (password) => {
        var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;
        return re.test(password);
    }

    const onRegister = async () => {
        getSSOInfo();
        if (
            state.firstName === "" ||
            state.lastName === "" ||
            state.email === "" ||
            state.password === "" ||
            state.occupation === "" ||
            state.industry === "") {
            notify({ title: 'Signup!', message: `Todos lo campos son obligatorios`, type: 'error' })
            return
        }

        if (!checkPassword(state.password)) {
            setState((prevState) => ({ ...prevState, error_pass: true }))
            return;
        }

        setState((prevState) => ({ ...prevState, loading: true }))

        // setTimeout(()=>{
        //     setState({...state, loading:false, step:1})
        // },3000)
        // // setState({...state, step:1})
        // return;

        let body = {
            password: state.password,
            email: state.email.toLowerCase(),
            firstName: state.firstName,
            lastName: state.lastName,
            occupation: state.occupation,
            industry: state.industry,
            verified: false,
            form_id: '',
            response_id: ''
        }
        let user;
        try {
            let _register = await Register(body)
            if (_register.response?.status >= 400) {
                notify({ title: 'Signup!', message: `${_register.response.data.message}`, type: 'error' })
                setState({ ...state, loading: false })
                return
            }
            user = _register.data
            let _login = await Login({ email: body.email, password: body.password })
            user.token = _login.data.token
            notify({ title: 'Signup!', message: `Welcome, ${body.firstName}`, type: 'success' })
            setUser({ user })
            navigate('/application')

        } catch (err) {
            console.log(err);
            notify({ title: 'Signup!', message: `Something went wrong!`, type: 'error' })
            setState({ ...state, loading: false })
        }

        // if (_register.status === 201) {
        //     user = _register.data
        //     let _login = await Login({ email: body.email, password: body.password })
        //     user.token = _login.data.token
        //     if (_login.status === 201) {
        //         notify({ title: 'Signup!', message: `Welcome, ${body.firstName}`, type: 'success' })
        //         setUser({ user })
        //         navigate('/application')
        //         // navigate('/home')
        //         // setState({...state, loading:false, step:1})
        //     } else {
        //         notify({ title: 'Signup!', message: `Something went wrong!`, type: 'error' })
        //         setState({ ...state, loading: false })
        //     }
        // } else {
        //     notify({ title: 'Signup!', message: `Something went wrong!`, type: 'error' })
        //     setState({ ...state, loading: false })
        // }

    }
    return (
        <div className='position-relative h-100 overflow-hidden' style={{backgroundImage : `url(${'/assets/images/login_bg.jpg'})`, backgroundPosition : 'center', backgroundSize : 'cover'}}>
            {/* <Background /> */}
            {/* <div className='position-absolute'>
                <img src='/assets/images/login_bg.jpg' className='image-fluid' />
            </div> */}
            <div className='login_view'>
                <div className='backdrop-login hide-scroll'>
                    <div className='container login-container my-5'>

                        <Renderif isTrue={state.step === 0}>
                            <div className='v-card d-flex flex-row opacity-transition'>
                                <div className='col-12 login-card-padding'>

                                <div className="logo-wrapper mb-4">
                                        <img src="/assets/images/logo.svg" className="image-fluid" />
                                    </div>

                                    <div className='d-flex flex-column justify-content-center w-100 p-3'>

                                        <div className={`${!googleSSO.enabled ? 'f-32' : 'f-24'}`} >
                                        <div className='f-26 login-font-color'>Elevate Your Leadership,</div>
                                        <div className='f-22 login-font-color'>Transform Your Organization</div>
                                        </div>
                                        <div className='mt-2 mb-5'>Create an account</div>

                                        <Renderif isTrue={!googleSSO.enabled}>
                                            <div className='textbox ps-0 mt-2'>
                                                <div className='f-12 bold line-1'>First name</div>
                                                <input value={state.firstName} onChange={(evt) => { setState({ ...state, firstName: evt.currentTarget.value }) }} placeholder='Jonh' className='text'></input>
                                            </div>
                                            <div className='textbox ps-0 mt-2'>
                                                <div className='f-12 bold line-1'>Last name</div>
                                                <input value={state.lastName} onChange={(evt) => { setState({ ...state, lastName: evt.currentTarget.value }) }} placeholder='Devus' className='text'></input>
                                            </div>
                                            <div className='textbox ps-0 mt-2'>
                                                <div className='f-12 bold line-1'>E-mail</div>
                                                <input value={state.email} onChange={(evt) => { setState({ ...state, email: evt.currentTarget.value }) }} placeholder='jonh@email.com' className='text'></input>
                                            </div>

                                            <div className='textbox ps-0 mt-2'>
                                                <div className='f-12 bold line-1'>Password</div>
                                                <div className='d-flex'>
                                                    <input value={state.password} onChange={(evt) => { setState({ ...state, password: evt.currentTarget.value }) }} type={state.showPassword === true ? 'password' : 'text'} placeholder='Enter at least 8 characters' className='text'></input>
                                                    <div className='bv-icon bv-icon--small cursor-pointer' onClick={() => { setState({ ...state, showPassword: !state.showPassword }) }}>
                                                        <Renderif isTrue={state.showPassword}>
                                                            <BsEye color='000'></BsEye>
                                                        </Renderif>
                                                        <Renderif isTrue={!state.showPassword}>
                                                            <BsEyeSlash color='000'></BsEyeSlash>
                                                        </Renderif>
                                                    </div>
                                                </div>
                                                <Renderif isTrue={state.error_pass}>
                                                    <div className='f-12 text-red'>Password must have at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character (@$!%*?&.)</div>
                                                </Renderif>

                                            </div>
                                        </Renderif>
                                        {/* <Renderif isTrue={googleSSO.enabled}>
                                        <div className='textbox mt-2'>
                                            <div className='f-12 bold line-1'>First name</div>
                                            <input value={googleSSO.data.firstName} onChange={(evt) => {
                                                console.log("cant change google-info")
                                            }} placeholder='Jonh' className='text'></input>
                                        </div>
                                        <div className='textbox mt-2'>
                                            <div className='f-12 bold line-1'>Last name</div>
                                            <input value={googleSSO.data.lastName} onChange={(evt) => {
                                                console.log("cant change google-info")
                                            }} placeholder='Devus' className='text'></input>
                                        </div>
                                        <div className='textbox mt-2'>
                                            <div className='f-12 bold line-1'>E-mail</div>
                                            <input value={googleSSO.data.email} onChange={(evt) => {
                                                console.log("cant change google-info")
                                            }} placeholder='jonh@email.com' className='text'></input>
                                        </div>
                                    </Renderif> */}
                                        <div className='textbox ps-0 mt-2'>
                                            <div className='f-12 bold line-1'>Occupation</div>

                                            <select value={state.occupation} onChange={(evt) => { setState({ ...state, occupation: evt.currentTarget.value }) }} className='input-select'>
                                                <option value="">Select</option>
                                                {state.occupations.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </select>
                                            {/* <input value={state.occupation} onChange={(evt)=>{setState({...state, occupation:evt.currentTarget.value})}} placeholder='jonhy' className='text'></input> */}
                                        </div>

                                        <div className='textbox ps-0 mt-2'>
                                            <div className='f-12 bold line-1'>Industry</div>
                                            <select value={state.industry} onChange={(evt) => {
                                                setState({ ...state, industry: evt.currentTarget.value })
                                                getSSOInfo();
                                            }} className='input-select'>
                                                <option value="">Select</option>
                                                {state.industries.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </select>
                                            {/* <input value={state.industry} onChange={(evt)=>{setState({...state, industry:evt.currentTarget.value})}} placeholder='jonhy' className='text'></input> */}
                                        </div>

                                        <div className='mt-3'></div>
                                        <Renderif isTrue={state.loading}>
                                            <div className='spinner'><div className='spinner-border'></div></div>
                                        </Renderif>
                                        <Renderif isTrue={!state.loading}>
                                            <div className='d-flex gap-3'>
                                            <div className='login-cta cta-bg cursor-pointer' onClick={() => {
                                                //setState({...state, step:1})
                                                onRegister()

                                            }}>
                                                <div>{!googleSSO.enabled ? 'Sign Up' : 'Submit'}</div>
                                            </div>
                                            <div className='login-cta login-cta-border cursor-pointer' onClick={() => {
                                                //setState({...state, step:1})
                                                navigate('/login')

                                            }}>
                                                <div>Login</div>
                                            </div>
                                            </div>
                                        </Renderif>
                                        <Renderif isTrue={!googleSSO.enabled}>
                                        <div className='d-flex gap-3 f-13 bold mt-5'>
                                            <div>
                                                Continue with
                                            </div>

                                            <GoogleSSO />
                                        </div>
                                        </Renderif>
                                        {/* <div className='mt-3'>
                                            <div className='text-dark-green text-decoration-underline cursor-pointer' onClick={() => {
                                                navigate('/login')
                                            }}>Already have an account</div>
                                        </div> */}

                                    </div>
                                </div>
                                {/* <div className='col-5 d-flex justify-content-center align-items-center position-relative'>
                                <img className='img-fluid object-fit-cover h-100 w-100' src='/assets/images/loginbg.jpeg'></img>
                                <div className='loginbg_footer'>
                                    <div className='f-18 bold text-center'>Visualization Center</div>
                                    <div className='f-14 text-center text-gray line-1'>Mexico City</div>
                                </div>
                            </div> */}
                            </div>
                        </Renderif>
                        <Renderif isTrue={state.step === 2}>
                            <div className='v-card d-flex flex-row'>
                                <div className='col-12 col-md-6'>

                                    <div className='d-flex flex-column justify-content-center align-items-center w-100 h-100 px-5'>

                                        <div className='f-32 bolder text-black'>Welcome!</div>
                                        <div className='f-14 line-1 text-gray'>As part of getting started, we need to understand your goals and tailor the experience to best meet your needs. Please choose the type of user that best describes you</div>
                                        {
                                            state.userTypes?.map((item) => {
                                                return (
                                                    <div key={item.id} className={`tile-opt mt-3 ${state.userType === item.id ? 'tile-opt--active' : ''}`} onClick={() => {
                                                        setState({ ...state, userType: item.id })
                                                    }}>
                                                        <div className='me-2'>
                                                            <div className='avatar'></div>
                                                        </div>

                                                        <div className='col'>
                                                            <div className='f-16 text-black'>{item.name}</div>
                                                        </div>
                                                        <div>
                                                            <div className='bv-icon bv-icon--mid'>
                                                                <BsArrowRight size={30} color='#294C53'></BsArrowRight>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }


                                        {/* <div className='tile-opt mt-3'>
                                    <div className='me-2'>
                                        <div className='avatar'></div>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 text-black'>Speaker</div>
                                    </div>
                                    <div>
                                        <div className='bv-icon bv-icon--mid'>
                                            <BsArrowRight size={30} color='#294C53'></BsArrowRight>
                                        </div>
                                    </div>
                                </div>

                                <div className='tile-opt mt-3'>
                                    <div className='me-2'>
                                        <div className='avatar'></div>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 text-black'>Visionary/Innovator</div>
                                    </div>
                                    <div>
                                        <div className='bv-icon bv-icon--mid'>
                                            <BsArrowRight size={30} color='#294C53'></BsArrowRight>
                                        </div>
                                    </div>
                                </div>

                                <div className='tile-opt mt-3'>
                                    <div className='me-2'>
                                        <div className='avatar'></div>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 text-black'>Job Seeker</div>
                                    </div>
                                    <div>
                                        <div className='bv-icon bv-icon--mid'>
                                            <BsArrowRight size={30} color='#294C53'></BsArrowRight>
                                        </div>
                                    </div>
                                </div> */}

                                        {/* <div className='tile-opt mt-3'>
                                    <div className='me-2'>
                                        <div className='avatar'></div>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 text-black'>Text</div>
                                    </div>
                                    <div>
                                        <div className='bv-icon bv-icon--mid'>
                                            <BsArrowRight size={30} color='#294C53'></BsArrowRight>
                                        </div>
                                    </div>
                                </div> */}

                                        <div className='d-flex align-items-center w-100 mt-3'>

                                            <div className=''>
                                                <div className='v-cta v-cta--light cursor-pointer rounded-3  px-3' style={{ width: 'fit-content' }} onClick={() => {
                                                    setState({ ...state, step: 0 })
                                                }}>
                                                    <div className='bv-icon bv-icon--mid'>
                                                        <BsArrowLeft size={20} color='#000'></BsArrowLeft>
                                                    </div>
                                                    <div className='text-black'>Back</div>
                                                </div>
                                            </div>

                                            <div className='col'></div>

                                            <div className=''>
                                                <div className='v-cta v-cta--primary cursor-pointer rounded-3' onClick={() => {
                                                    setState({ ...state, step: 2 })
                                                }}>
                                                    <div className='ps-3 f-14'>Next</div>
                                                    <div className='bv-icon bv-icon--mid'>
                                                        <BsArrowRight></BsArrowRight>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>



                                </div>
                                <div className='col-6 d-flex justify-content-center align-items-center bg-light'>
                                    <img className='img-fluid py-5 px-5' src='/assets/images/Image_60.png'></img>
                                </div>
                            </div>
                        </Renderif>
                        <Renderif isTrue={state.step === 3}>



                            <div className='v-card d-flex flex-row' style={{ paddingBottom: '4rem', maxHeight: '80vh' }}>

                                <div className='col-12 col-md-6 d-none d-md-flex flex-column justify-content-center align-items-center bg-light px-4'>

                                    <div className='d-flex flex-column justify-content-lg-start align-items-start'>
                                        <div className='f-32 bolder text-black line-1'>Help Us Personalize<br></br>Your Experience!</div>
                                        <div className='f-14 text-gray'>Let us know what topics and domains you're passionate <br></br> about so we can provide content that truly matters to you</div>
                                    </div>
                                    <img width="70%" className='img-fluid py-5 px-5' src='/assets/images/Image_62.png'></img>
                                </div>


                                <div className='col-12 col-md-6'>

                                    <div className='d-flex flex-column  w-100 h-100 px-4 overflow-auto hide-scroll' >

                                        <div className='d-flex d-md-none flex-column justify-content-lg-start align-items-start pt-3'>
                                            <div className='f-32 bolder text-black line-1'>Help Us Personalize<br></br>Your Experience!</div>
                                            <div className='f-14 text-gray'>Let us know what topics and domains you're passionate <br></br> about so we can provide content that truly matters to you</div>
                                        </div>

                                        {
                                            state.interests.map((obj) => {
                                                return (
                                                    <div key={obj.id} className='d-flex flex-column sh r-5 py-3 px-2 mt-2'>
                                                        <div className='d-flex justify-content-start align-items-center'>
                                                            <div className='bv-icon bv-icon--mid'>
                                                                <LuBrain></LuBrain>
                                                            </div>
                                                            <div className='f-14 bold'>{obj.category}</div>
                                                        </div>

                                                        <div className='d-flex flex-wrap py-3'>
                                                            {
                                                                obj.values.map((item, j) => {
                                                                    return (
                                                                        <div key={item.id} className={`v-tag ${state.interestsSelected.includes(item.id) ? 'v-tag--active' : ''}`} onClick={() => {

                                                                            if (state.interestsSelected.includes(item.id)) {
                                                                                state.interestsSelected = state.interestsSelected.filter((interest) => interest !== item.id)
                                                                            } else {
                                                                                state.interestsSelected.push(item.id)
                                                                            }
                                                                            console.log(state.interestsSelected)


                                                                            setState({ ...state })
                                                                        }}>{item.interest}</div>
                                                                    )
                                                                })
                                                            }



                                                            {/* <div className='v-tag'>Enhances Customer Engagement</div>

                                                    <div className='v-tag'>Operational Efficiency</div>
                                                    <div className='v-tag'>Content Generation</div>
                                                    <div className='v-tag'>Data Analysis</div>
                                                    <div className='v-tag'>Human collaboration</div> */}
                                                            {/* <div className='v-tag'>Fraud Detection</div>
                                                    <div className='v-tag'>Robotics Process Automation (RPA)</div>
                                                    <div className='v-tag'>Virtual Assistants</div>
                                                    <div className='v-tag'>Medical Diagnosis and Treatment</div> */}

                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }




                                        {/* <div className='d-flex flex-column sh r-5 py-3 px-2 mt-2'>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <div className='bv-icon bv-icon--mid'>
                                            <LuBrain></LuBrain>
                                        </div>
                                        <div className='f-14 bold'>AI Ethics and Governance</div>
                                    </div>
 
                                    <div className='d-flex flex-wrap py-3'>
                                     
                                        <div className='v-tag'>Intelectual Property</div>
                                        <div className='v-tag'>Data Privacy</div>
                                        <div className='v-tag'>Bias and Fairness</div>
                                        <div className='v-tag'>Security</div>
                                        <div className='v-tag'>Transparency</div>
                     
                                   
                                    </div>
                                </div>


                                <div className='d-flex flex-column sh r-5 py-3 px-2 mt-2'>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <div className='bv-icon bv-icon--mid'>
                                            <LuBrain></LuBrain>
                                        </div>
                                        <div className='f-14 bold'>AI Applications Per Industry</div>
                                    </div>
 
                                    <div className='d-flex flex-wrap py-3'>
                                     
                                        <div className='v-tag'>   AI in Finance</div>
                                        <div className='v-tag'>AI in Healthcare</div>
                                        <div className='v-tag'>AI in Retail</div>
                                        <div className='v-tag'>AI in Manufacturing</div>
                                        <div className='v-tag'>AI in Real Estate</div>
                                        <div className='v-tag'>AI in Education</div>
                                        <div className='v-tag'>AI in Entertainment</div>
                                        <div className='v-tag'>AI in Transportation and Logistics</div>
                                        <div className='v-tag'>AI in Agriculture</div>
                                        <div className='v-tag'>AI in Legal Services</div>
                                   
                                    </div>
                                </div>
                                 */}
                                        <div className='my-5'></div>


                                    </div>



                                </div>

                                <div className='step-controls'>

                                    <div className='d-flex align-items-center w-100'>

                                        <Renderif isTrue={!state.loading}>
                                            <div className='v-cta v-cta--light cursor-pointer rounded-3  px-3' style={{ width: 'fit-content' }} onClick={() => {
                                                setState({ ...state, step: 1 })
                                            }}>
                                                <div className='bv-icon bv-icon--mid'>
                                                    <BsArrowLeft size={20} color='#000'></BsArrowLeft>
                                                </div>
                                                <div className='text-black'>Back</div>
                                            </div>
                                        </Renderif>

                                        <div className='col'></div>

                                        {/* <div className='cursor-pointer me-3' onClick={()=>{
                                    navigate('/home')
                                }}>Skip</div> */}

                                        <Renderif isTrue={state.loading}>
                                            <div className='spinner'><div className='spinner-border spinner-small'></div></div>
                                        </Renderif>

                                        <Renderif isTrue={!state.loading}>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <div className='v-cta v-cta--primary cursor-pointer rounded-3' onClick={() => {

                                                    onRegister()
                                                    // navigate('/home')
                                                }}>
                                                    <div className='ps-3 f-14'>Next</div>
                                                    <div className='bv-icon bv-icon--mid'>
                                                        <BsArrowRight></BsArrowRight>
                                                    </div>
                                                </div>
                                            </div>
                                        </Renderif>
                                    </div>

                                </div>

                            </div>
                        </Renderif>


                    </div>

                </div>

            </div>
        </div >
    )
}
