import axios from 'axios';
import React, { createContext, useReducer, useContext, useEffect, useState, useCallback } from 'react';
import { AlertContext } from './alert_context';
import { AuthContext } from './auth_context';
import { useNavigate } from 'react-router-dom';


const initialState = {
    cart: JSON.parse(localStorage.getItem('cart')) || [],
}

// Action Types
const actionTypes = {
    ADD_TO_CART: 'ADD_TO_CART',
    REMOVE_FROM_CART: 'REMOVE_FROM_CART',
    CLEAR_CART: 'CLEAR_CART',
    UPDATE_PRICES: 'UPDATE_PRICES',
    SET_CART_ITEMS: 'SET_CART_ITEMS',
    MERGE_CART_ITEMS: 'MERGE_CART_ITEMS'
}

// Reducer Function
const cartReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.ADD_TO_CART:
            // Check if the product already exists in the cart
            const isProductInCart = state.cart.some((item) => item.id === action.payload.id);
            // If the product is already in the cart, return the existing state
            if (isProductInCart) {
                return state;
            }
            return {
                ...state,
                cart: [...state.cart, action.payload]
            }
        case actionTypes.REMOVE_FROM_CART:
            return {
                ...state,
                cart: state.cart.filter((item) => item.id !== action.payload.id)
            }
        case actionTypes.CLEAR_CART:
            return {
                ...state,
                cart: []
            }
        case actionTypes.UPDATE_PRICES:
            // Update the cart items with the latest prices from the action payload which will contain the list of items with updated prices.
            return {
                ...state,
                cart: state.cart.map((item) => {
                    if (action.payload) {
                        const updatedItem = action.payload.find((p) => p.id === item.id);
                        return updatedItem ? { ...item, price: updatedItem.price } : item;
                    } else {
                        return item
                    }
                }),
            };
        case actionTypes.SET_CART_ITEMS:
            if (!action.payload) return
            // Replace the current cart with another array of cart items
            return {
                ...state,
                cart: [...action.payload] // Replaces the cart with the new array
            };
        case actionTypes.MERGE_CART_ITEMS:
            // Merge the new cart items with the existing cart, avoiding duplicates based on product id
            const mergedCart = [...state.cart];
            if (!action.payload) return
            action.payload.forEach((newItem) => {
                const existingItemIndex = mergedCart.findIndex((item) => item.id === newItem.id);
                if (existingItemIndex > -1) {
                    // If the item exists, update its quantity or other fields
                    mergedCart[existingItemIndex] = {
                        ...newItem
                    };
                } else {
                    // Otherwise, add the new item to the cart
                    mergedCart.push(newItem);
                }
            });
            return {
                ...state,
                cart: mergedCart
            };

        default:
            return state;
    }
};

// Create Cart Context
const CartContext = createContext({
    cart: [],
    addToCart: () => { },
    removeFromCart: () => { },
    clearCart: () => { },
    getTotalCartValue: () => 0,
    getTotalItems: () => 0
});

// Cart Provider Component
export const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, initialState);
    const [loading, setLoading] = useState(false);
    const { notify } = useContext(AlertContext);
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    const fetchPrices = async () => {
        if (state.cart.length === 0) return
        setLoading(true);
        try {
            // Fetching prices
            const productIds = state.cart.map((item) => item.id)

            // axios fetch data
            let res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/products`, {
                products: productIds
            })
            const data = res.data;
            dispatch({ type: actionTypes.UPDATE_PRICES, payload: data })
        } catch (err) {
            console.log('Error fetching prices:', err)
        } finally {
            setLoading(false);
        }

    }

    const fetchCart = useCallback(async () => {
        if (!user) return
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/cart/`, {
                headers: {
                    Authorization: `Bearer ${user.token}`
                }
            })
            const { products } = res.data
            if (!products) return //if no products field in cart i.e newly created return
            if (state.cart.length === 0) {
                dispatch({ type: actionTypes.SET_CART_ITEMS, payload: products });
            } else {
                mergeCart(products);
            }
        } catch (err) {
            console.log(err);
        }
    }, [user])

    // Fetching updated prices whenever cart_context is initialized
    useEffect(() => {
        fetchPrices();
    }, [])

    // Fetching the cart from backend
    useEffect(() => {
        fetchCart();
    }, [user, fetchCart])

    const saveCart = async () => {
        if (!user) return
        try {
            let cart = localStorage.getItem('cart')
            cart = JSON.parse(cart);
            const products = cart.map((item) => item.id)
            await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/cart/`, {
                products
            }, {
                headers: {
                    Authorization: `Bearer ${user.token}`
                }
            })
        } catch (err) {
            console.log(err)
        }
    }
    // Save cart to localStorage whenever cart changes
    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(state.cart));

        const cartSync = setTimeout(() => {
            saveCart();
        }, 500)
        

        return () => {
            localStorage.setItem('cart', JSON.stringify(state.cart));
            clearInterval(cartSync);
        }
    }, [state.cart]);



    const addToCart = (product) => {
        notify({ title: 'added', message: `${product.name} added to cart`, type: 'success' })
        dispatch({ type: actionTypes.ADD_TO_CART, payload: product })
    }

    const mergeCart = (newProductArray) => {
        if (newProductArray.length === 0) return
        dispatch({ type: actionTypes.MERGE_CART_ITEMS, payload: newProductArray });
    }

    const removeFromCart = (product) => {
        dispatch({ type: actionTypes.REMOVE_FROM_CART, payload: product });
    }

    const clearCart = () => {
        dispatch({ type: actionTypes.CLEAR_CART });
    }

    const getTotalCartValue = () => {
        return state.cart.reduce((total, product) => total + (parseFloat(product.price) || 0), 0);
    };

    const getTotalItems = () => {
        return state.cart.length
    }

    const checkOut = async () => {
        if(!user){
            navigate('/login')
            return
        }

        // Dont proceed if cart is empty
        if(state.cart.length === 0) return

        setLoading(true);
        try{
            await saveCart()
        }catch(err){
            console.log(err)
        }

        
        // Move with checkout flow

        // Creating an order on our backend
        const _token = localStorage.getItem('token')
        try {
            let res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/orders`, {}, {
                headers: {
                    Authorization: `Bearer ${_token}`
                }
            })
            console.log("order sccessfully created")
            const { orderId, products, userId } = res.data

            // creating a checkout session
            res = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/payment`, {
                orderId,
                userId,
                products: products.map((product) => {
                    return {
                        id: product.id,
                        price: product.price,
                        thumbnail : product.thumbnail,
                        quantity : 1,
                        name : product.name
                    }
                })
            }, {
                headers: {
                    Authorization: `Bearer ${_token}`
                }
            })
            const {url} = res.data
            setLoading(false)
            window.location.href = url            
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <CartContext.Provider value={{ cart: state.cart, addToCart, removeFromCart, clearCart, getTotalCartValue, getTotalItems, loading, saveCart, checkOut }}>
            {children}
        </CartContext.Provider>
    )
}

export const useCart = () => {
    return useContext(CartContext);
}