import React, { useEffect, useState } from 'react'
import { createContext } from "react";
export const AlertContext = createContext();

export default function AlertContextProvider({children}) {

    const [state, setState] = useState({
        notifications:[]
    })

    const notify = ({title,message,type}) => {
        state.notifications.push({title,message,type})
        setState({...state})

        setTimeout(()=>{
            state.notifications.shift()
            setState({...state})

        },4000)
    }



  return (
    <AlertContext.Provider value={
      {
        ...state,
        notify
      }
    }>

        {children}

    </AlertContext.Provider>
  )
}
