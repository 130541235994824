import React, { useCallback, useEffect, useRef, useState } from "react";
import Renderif from "../helpers/renderif";
import { GrSend } from "react-icons/gr";
import { FaMicrophone } from "react-icons/fa";
import { AiFillPicture } from "react-icons/ai";
import axios from "axios";
import "./chatai.css";

const ChatAI = ({ onClose = () => {} }) => {
  let _token = localStorage.getItem("token");
  const [state, setState] = useState({
    messages: [],
    loading: false,
    query: "",
    assistant_id: "",
    assistant_name: "",
    bots: [],
  });


  const _onClose = () => {
    onClose();
  };

  const handleChange = (event) => {
    setState({ ...state, query: event.target.value });
  };

  const submitQuery = async (e) => {
    e.preventDefault();
    if (state.query === "" || state.loading) return;
    setState({ ...state, loading: true });

    try {
      let res = await axios.post(
        "https://visionllm.coaching2100.com/post/assistant/message/",
        {
          assistant_id: state.assistant_id,
          query_input: state.query,
        },
        {
          headers: {
            Authorization: _token,
          },
        }
      );
      res = res.data;
      const { query_res, query_input, query_output_id, query_input_id } =
        res.data;
      const newMessages = [
        {
          message: query_input,
          role: "user",
          id: query_input_id,
        },
        {
          message: query_res,
          id: query_output_id,
          role: "assistant",
        },
      ];
      setState({
        ...state,
        query: "",
        loading: false,
        messages: [...state.messages, ...newMessages],
      });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchbots = useCallback(async () => {
    try {
      const res = await axios.get(
        "https://visionllm.coaching2100.com/assistants-list/"
      );
      const botList = res?.data?.data;
      setState((prevState) => ({
        ...prevState,
        bots: botList,
        assistant_id: botList[0].assistant_id,
        assistant_name: botList[0].assistant_name,
      }));
    } catch (err) {
      console.log(err);
    }
  }, [state.assistant_id]);

  // Chat history implementation
  const storeMessages = useCallback(
    (messages) => {
      if (!messages) return;
      const formattedMessages = [];
      messages.forEach((item) => {
        const { query_res, query_input, query_output_id, query_input_id } =
          item;
        const newMessages = [
          {
            message: query_input,
            role: "user",
            id: query_input_id,
          },
          {
            message: query_res,
            id: query_output_id,
            role: "assistant",
          },
        ];
        formattedMessages.push(...newMessages);
      });
      setState({ ...state, messages: [...formattedMessages] });
    },
    [state.assistant_id]
  );

  const fetchChatHistory = async (token) => {
    setState({ ...state, loading: true });
    try {
      const res = await axios.post(
        "https://visionllm.coaching2100.com/chat-history/",
        {
          assistant_id: state.assistant_id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const messages = res?.data?.data;
      storeMessages(messages);
    } catch (err) {
      console.log("error fetching chat history", err);
      setState((prevState) => ({ ...prevState, messages: [] }));
    }
    setState((prevState) => ({ ...prevState, loading: false }));
  };

  useEffect(() => {
    fetchbots();
  }, []);

  // Debouncing the request to fetch chat history.
  useEffect(() => {
    const getData = setTimeout(() => {
      fetchChatHistory(_token);
    }, 1000);

    return () => clearTimeout(getData);
  }, [state.assistant_id]);

  // Scroll to bottom implementation
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [state.messages]);

  return (
    <div
      className="h-100 w-100 position-relative d-flex flex-column"
    >
      <div className="d-flex bot-list hide-scroll me-2">
        {state.bots.map((item) => {
          return (
            <div
              key={item.assistant_id}
              className="avatar-container p-2 cursor-pointer"
              onClick={(e) => {
                setState({
                  ...state,
                  assistant_id: item.assistant_id,
                  assistant_name: item.assistant_name,
                });
              }}
            >
              <div
                className={`avatar ${
                  state.assistant_id === item.assistant_id
                    ? "avatar--active"
                    : ""
                }`}
                style={{}}
              >
                <div className="d-flex align-items-center justify-content-center chatai-avatar">
                  {item.assistant_name
                    ? item.assistant_name.split(" ")[1].slice(0, 1)
                    : "N"}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {state.assistant_id && <hr style={{ margin: "0rem 0" }}></hr>}

      <div className="d-flex justify-content-center align-items-center mt-2 ">
        {state.assistant_name && (
          <div
            className="avatar"
            style={{ backgroundImage: 'url("assets/images/dcgrant.jpeg")' }}
          ></div>
        )}
        {state.assistant_name && (
          <div className="f-16 bolder text-dark-green">
            {state.assistant_name ? state.assistant_name : "No Name"}
          </div>
        )}
      </div>
      <div className="hide-scroll flex-grow-1">
        {state.assistant_id && <div className="overflow-hidden">
          <iframe
            title="frecuence"
            key={"frequence"}
            height={70}
            className="overflow-hidden"
            width={"100%"}
            src="./pages/frecuence.html"
          ></iframe>
        </div>}
        <div className="col" ref={containerRef}>
          {/* <div className='msg-response'>Message bot</div> */}

          {/* <div className='msg-self'>MesLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</div> */}
          {/* <div className='d-flex'>
                        <div className='col'></div>
                        <div className='msg-self'>lorem100</div>
                    </div>
                    <div className='msg-response'>Message bot</div>

                    <div className='d-flex'>
                        <div className='col'></div>
                        <div className='msg-self'>Message user</div>
                    </div> */}
          <Renderif isTrue={!state.loading}>
            {state.messages.map((msg, index) => {
              if (msg.role === "user") {
                return (
                  <div className="d-flex" key={msg.id + index}>
                    <div className="col"></div>
                    <div className="msg-self">{msg.message}</div>
                  </div>
                );
              } else if (msg.role === "assistant") {
                return (
                  <div key={msg.id} className="msg-response w-75">
                    {msg.message}
                  </div>
                );
              } else {
                return <></>;
              }
            })}
          </Renderif>
          <Renderif isTrue={state.loading}>
            <div className="spinner f-22 mt-1 w-100 d-flex flex-column justify-content-center align-items-center">
              <div className="spinner-border"></div>
            </div>
          </Renderif>
        </div>
      </div>

      <div className="d-flex px-4 py-3">
        <div className="bv-icon bv-icon--md p-0 m-0 pe-2 cursor-ponter">
          <AiFillPicture size={22} color="#294C53"></AiFillPicture>
        </div>

        <form className="d-flex w-full" onSubmit={submitQuery}>
          <div className="col">
            <div className="v-input">
              <input
                className="input"
                placeholder="Message"
                value={state.query}
                onChange={handleChange}
              ></input>
              <div className="bv-icon bv-icon--md p-0 m-0 cursor-ponter">
                <FaMicrophone size={22} color="#294C53"></FaMicrophone>
              </div>
            </div>
          </div>
          <button
            type="submit"
            disabled={state.loading}
            className="bv-icon bv-icon--md p-0 m-0 ms-2"
          >
            <Renderif isTrue={state.loading}>
              <div className="spinner f-22 mt-1">
                <div className="spinner-border spinner--small"></div>
              </div>
            </Renderif>
            <Renderif isTrue={!state.loading}>
              <GrSend size={22} color="#294C53"></GrSend>
            </Renderif>
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatAI;
