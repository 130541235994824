import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import Header from "../../../../components/layout/header";
import Footer from "../../../../components/layout/footer";
import { Service } from "../../../../services/api";
import Banner from "../../../../components/Banner/banner";
import FooterBanner from "../../../../components/FooterBanner/FooterBanner";
import axios from "axios";
import Renderif from "../../../../components/helpers/renderif";
import SessionCard from "../../../../components/SessionCard/SessionCard";

const EventsPage = () => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: true,
    cursos: [],
    show_chatia: false,
  });

  useEffect(() => {
    init();
    return () => {};
  }, []);

  // function to jump straight to room.
  async function joinRoom(session) {
    let activity = session.activities[0].activity;
    if (activity?.webinars?.length > 0) {
      let room_name = activity.webinars[0].webinarUrlRoom.split("/")[3];
      let body = {
        roomId: room_name,
        webinarId: activity?.webinars[0].id,
      };
      let _token = await Service("POST", `dailyvideo/generate-token`, body);
      navigate(`/room?roomUrl=${activity?.webinars[0].webinarUrlRoom}`, {
        state: { sessionData: session, token: _token.data.token },
      });
    } else {
      // console.log(activity?.webinars);
    }
  }
  const init = async () => {
    // let _cursos = await Service('GET', `course`)
    const id = "7c3497c1-6b37-400c-a008-0bfa20049954";
    // instead of gettin courses, we are going to fetch only a specific course for live events
    try {
      const token = localStorage.getItem("token");
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/course/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setState({ ...state, cursos: [res.data] });
    } catch (err) {
      console.log(err);
      return navigate("/");
    } finally {
      setState((prevState) => {
        return { ...prevState, loading: false };
      });
    }
    // if (_cursos.status === 200) {
    //     state.cursos = _cursos.data
    // }
  };

  return (
    <div className="container-fluid d-flex flex-column overflow-y-auto hide-scroll h-100">
      <Header />
      {/* <Hero /> */}

      {/* <div className="container my-5 text-center f-26 text-gray opacity-50">
                Visualization Center
            </div> */}

      <div className="mt-4 container">
        <Renderif isTrue={state.loading}>
          <div className="d-flex justify-content-center align-items-center text-primary">
            <div className="spinner">
              <div className="spinner-border"></div>
            </div>
          </div>
        </Renderif>

        <Renderif isTrue={!state.loading}>
          {state.cursos &&
            state.cursos.map((curso, i) => {
              return (
                <div key={curso.id} className="row">
                  <div className="mb-4 ps-1">
                    <div className="f-14 bold text-center login-font-color">
                      {curso?.name}
                    </div>
                    {/* <div className="f-16 text-gray line-1">{curso?.description}</div> */}
                  </div>

                  {curso?.sessions?.map((session) => {
                    return (
                      <SessionCard
                        key={session.id}
                        session={session}
                        curso={curso}
                      />
                    );
                  })}

                  <div className="my-4"></div>
                </div>
              );
            })}
          <Renderif isTrue={state.cursoe && !state.cursos[0].sessions.length}>
            <div className="d-flex justify-content-center">No Upcoming events</div>
          </Renderif>
        </Renderif>
      </div>

      <div className="my-5 container">
        <div className="row px-0">
          <div className="col-md-7 position-relative mt-2">
            <div
              className="position-absolute m-5  bottom-0 start-0 text-white"
              style={{ zIndex: 2 }}
            >
              <div className="text-light-gray f-12">Recent</div>
              <div className="f-22">Visionary Voices:</div>
              <div className="f-22">Shaping Tomorrow</div>
            </div>
            <Banner
              height={350}
              radius={3}
              gradient={"down"}
              imageSrc={"/assets/images/login_bg.png"}
            />
            <div
              className="tag cta-bg position-absolute end-0 bottom-0 m-5 px-3 py-1 cursor-pointer f-14"
              style={{ zIndex: 2 }}
            >
              Join Live Session
            </div>
          </div>
          <div className="col-md-5 position-relative mt-2">
            <div
              className="position-absolute top-0 m-5 text-white"
              style={{ zIndex: 2 }}
            >
              <div className="f-12">Recent</div>
              <div className="f-22">The new AI assets</div>
              <div className="f-22">for finance</div>
            </div>
            <Banner
              height={350}
              radius={3}
              gradient={"up"}
              imageSrc={"/assets/images/login_bg.png"}
            />
            <div
              className="tag cta-bg position-absolute start-0 bottom-0 m-5 px-3 py-1 cursor-pointer f-14"
              style={{ zIndex: 2 }}
            >
              Join Live Session
            </div>
          </div>
        </div>
      </div>
      <FooterBanner />
      <Footer></Footer>

      {/* <Popupchat />
            <Appfooter />  */}
    </div>
  );
};

export default EventsPage;
