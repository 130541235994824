import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const PATHS = {
    'Visualization Center': '',
    'Marketplace': 'marketplace',
    'Human Collaboration' : 'human-collaboration',
    'Intelligence & Insights' : 'intelligence-insights'
}
const LIST = ['Visualization Center', 'Marketplace', 'Human Collaboration', 'Intelligence & Insights']


const SubHeader = () => {
    const location  = useLocation();
    const path = location.pathname.split('/')[1]
    const navigate = useNavigate()

    return (
        <div className='w-100'>
            <div className='cta-bg py-1 text-center w-100 f-18 px-1'>Visionary Voices is NOW ONLINE! Join for exclusive insights</div>
            <div className='d-flex my-3 gap-4 container hide-scroll px-2' style={{maxWidth : 'fit-content'}}>
                {LIST.map((item) => {
                    return (
                        <div key={item} onClick={() => {navigate(`/${PATHS[item]}`)}} className={`${PATHS[item] === path ? 'cta-bg': ''} cursor-pointer f-18 bold tag py-1 px-3`} style={{whiteSpace: 'nowrap'}}>{item}</div>
                    )
                })
                }
            </div>
        </div>
    )
}

export default SubHeader