import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { FiUser } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </button>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
export const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <ul className="list-unstyled mb-0" >
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value),
                    )}
                </ul>
            </div>
        );
    },
);



const Avatar = ({userData}) => {
    const navigate = useNavigate();
    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <div>
                    <div className='avatar avatar--bg-transparent'>
                        <FiUser className='h-75 w-75'/>
                    </div>
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu} className=''>
            <Dropdown.Header>{userData.nombre}</Dropdown.Header>
                <Dropdown.Item eventKey="1" onClick={(e) => {
                    navigate('/orders')
                }}>My Orders</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default Avatar