/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 public/models/island/scene.gltf 
Author: Vertex - Egypt (https://sketchfab.com/Vertex-Egypt)
License: SKETCHFAB Standard (https://sketchfab.com/licenses)
Source: https://sketchfab.com/3d-models/0077-future-city-island-01-vol-01-073c21074c834205875cd966c60eb993
Title: 0077 - Future City Island 01 - Vol 01
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Island(props) {
  const { nodes, materials } = useGLTF('/models/island/scene.gltf')
  return (
    <group {...props} dispose={null}>
      <group position={[-43.68, 0, -8.217]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, 1.254]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_67_Vertex_-_Floor_Map_0'].geometry} material={materials['Vertex_-_Floor_Map']} position={[-4.791, 13.504, 0]} />
      </group>
      <group position={[-79.693, 0, -142.836]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_27_Vertex_-_Turko_Map_0'].geometry} material={materials['Vertex_-_Turko_Map']} position={[-0.005, 0.374, 0]} />
      </group>
      <group position={[-79.675, 0, -143.821]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, 1.019]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_65_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[0.18, -0.154, -0.15]} />
      </group>
      <group position={[10.478, 0, 36.939]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_255_Vertex_-_Floor_Map_0'].geometry} material={materials['Vertex_-_Floor_Map']} position={[0.295, -0.318, 0.05]} />
      </group>
      <group position={[-128.299, 0.078, -61.172]} rotation={[-Math.PI / 2, 0, 0.082]} scale={[0.844, 0.844, 1.164]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_68_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[79.168, -45.025, -0.1]} />
      </group>
      <group position={[-120.245, 11.132, 13.783]} rotation={[-Math.PI / 2, 0, 2.191]} scale={[-0.893, 0.893, 0.893]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_79_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[4.601, -17.924, -6.122]} />
      </group>
      <group position={[-129.818, 15.631, 23.726]} rotation={[-Math.PI / 2, 0, 3.139]} scale={0.893}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_13_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[1.932, 1.432, -5]} />
      </group>
      <group position={[-45.46, 0, -8.713]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, 1.254]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_69_Vertex_-_Map_Grass_01_0'].geometry} material={materials['Vertex_-_Map_Grass_01']} position={[-4.192, 14.057, 0]} />
      </group>
      <group position={[-79.675, 0, -143.821]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, 1.019]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_323_Vertex_-_Map_Water_0'].geometry} material={materials['Vertex_-_Map_Water']} position={[0.18, -0.154, -0.15]} />
      </group>
      <group position={[-63.111, 12.85, 25.188]} rotation={[-Math.PI / 2, 0, 0.987]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_81_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-0.035, 0.018, -13.418]} rotation={[0, 0, 0.368]} scale={0.893} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_419_Vertex_-_Map_02_0'].geometry} material={materials['Vertex_-_Map_02']} position={[-0.035, 0.018, -13.418]} rotation={[0, 0, 0.368]} scale={0.893} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_414_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-0.035, 0.018, -13.418]} rotation={[0, 0, 0.368]} scale={0.893} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_445_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-0.035, 0.018, 5.324]} scale={[1.88, 1.88, 0.516]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_324_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-0.035, 0.018, -13.418]} rotation={[0, 0, 0.368]} scale={0.893} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_84_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-0.035, 0.018, -3.196]} rotation={[0, 0, -0.008]} scale={0.893} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_83_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-0.035, 0.018, -13.418]} rotation={[0, 0, 0.368]} scale={0.893} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_420_Vertex_-_Map_02_0'].geometry} material={materials['Vertex_-_Map_02']} position={[-0.035, 0.018, -13.418]} rotation={[0, 0, 0.368]} scale={0.893} />
      </group>
      <group position={[-42.191, 6.511, 38.421]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_47_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[0, 0, 0.632]} rotation={[0, 0, -0.743]} scale={[0.755, 0.755, 0.863]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_46_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[0, 0, -0.143]} rotation={[0, 0, -0.743]} scale={[0.874, 0.874, 1]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_48_Vertex_-_Steel_0'].geometry} material={materials['Vertex_-_Steel']} position={[0, 0, -0.07]} rotation={[0, 0, -0.743]} scale={[0.755, 0.755, 0.863]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_49_Vertex_-_Steel_0'].geometry} material={materials['Vertex_-_Steel']} position={[0, 0, -0.143]} rotation={[0, 0, -0.743]} scale={[0.874, 0.874, 1]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_45_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[0, 0, -0.805]} rotation={[0, 0, -0.743]} />
      </group>
      <group position={[-13.064, 4, 62.32]} rotation={[-Math.PI / 2, 0, -0.029]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_329_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[40.721, -4.244, -4]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_339_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[40.721, -4.244, -4]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_422_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[40.721, -4.244, -4]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_423_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[40.721, -4.244, -4]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_325_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[40.721, -4.244, -4]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_326_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[40.721, -4.244, -4]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_327_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[40.721, -4.244, -4]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_328_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[40.721, -4.244, -4]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_341_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[40.721, -4.244, -4]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_330_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[40.721, -4.244, -4]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_331_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[40.721, -4.244, -4]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_332_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[40.721, -4.244, -4]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_334_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[40.721, -4.244, -4]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_335_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[40.721, -4.244, -4]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_337_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[40.721, -4.244, -4]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_421_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[40.721, -4.244, -4]} />
      </group>
      <group position={[-109.368, 0, -37.152]} rotation={[-Math.PI / 2, 0, -1.385]} scale={[0.916, 0.916, 0.969]}>
        <group position={[2.306, 0, 17.538]} scale={[1, 1.326, 1]}>
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_343_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[24.795, 0.159, -1]} />
        </group>
        <group position={[2.306, 0, 17.538]} scale={[1, 1.326, 1]}>
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_426_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[24.795, 0.159, -1]} />
        </group>
        <group position={[2.306, 0, 17.538]} scale={[1, 1.326, 1]}>
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_07_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[24.795, 0.159, -1]} />
        </group>
        <group position={[2.306, 0, 17.538]} scale={[1.38, 1.632, 1]}>
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_06_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[24.795, 0.159, -1]} />
        </group>
        <group position={[2.306, 0, 17.538]} scale={[1.057, 1.326, 1]}>
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_05_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[24.795, 0.159, -1]} />
        </group>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_85_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[2.202, 0.069, -0.466]} rotation={[0, 0, -Math.PI / 2]} scale={[1, 1.583, 1]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_268_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[2.464, 23.844, -0.466]} rotation={[0, 0, Math.PI]} scale={[-1, 1, 1]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_267_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[2.464, -24.167, -0.466]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_89_Vertex_-_Metal_map_0'].geometry} material={materials['Vertex_-_Metal_map']} position={[2.202, 0.069, -0.466]} rotation={[0, 0, -Math.PI / 2]} scale={[1, 1.583, 1]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_447_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[2.286, 0.027, 17.536]} scale={[1.501, 1, 0.203]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_88_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[2.202, 0.069, 14.417]} rotation={[0, 0, -Math.PI / 2]} scale={[1.055, 1.583, 1]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_87_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[2.202, 0.069, 8.307]} rotation={[0, 0, -Math.PI / 2]} scale={[1, 1.583, 1]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_86_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[2.202, 0.069, 8.307]} rotation={[0, 0, -Math.PI / 2]} scale={[1, 1.583, 1]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_424_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[2.286, 0.027, 17.536]} scale={[1.501, 1, 0.203]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_425_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[2.202, 0.069, 8.307]} rotation={[0, 0, -Math.PI / 2]} scale={[1, 1.583, 1]} />
      </group>
      <group position={[-41.962, 20.518, 15.449]} rotation={[-Math.PI / 2, 0, 0]}>
        <group position={[-2.288, 15.077, -0.372]} rotation={[0, 0, 0.369]} scale={[0.868, 0.868, 0.894]}>
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_15_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[1.932, 1.432, -5]} />
        </group>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_402_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[3.921, 10.161, -0.37]} rotation={[0, 0, 0.369]} scale={[0.868, 0.868, 0.894]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_406_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[10.219, 3.188, -0.372]} rotation={[0, 0, 0.369]} scale={[0.868, 0.868, 0.894]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_407_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[16.713, -8.551, -0.37]} rotation={[0, 0, 0.369]} scale={[0.868, 0.868, 0.894]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_403_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[18.908, 0.425, -0.524]} rotation={[0, 0, 0.369]} scale={[0.868, 0.868, 0.894]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_404_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-10.853, 17.998, -0.37]} rotation={[0, 0, 0.369]} scale={[0.868, 0.868, 0.894]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_405_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-24.194, 19.253, -0.37]} rotation={[0, 0, 0.369]} scale={[0.868, 0.868, 0.894]} />
      </group>
      <group position={[-104.57, 26.308, 8.129]} rotation={[-Math.PI / 2, 0, 0]} scale={0.972}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_442_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-4.254, -3.512, 20.51]} rotation={[0.125, -0.169, 0.966]} scale={0.821} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_19_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-4.79, -1.244, 0.929]} rotation={[0.125, -0.169, -1.367]} scale={0.646} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_18_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-4.79, -1.244, 0.929]} rotation={[0.125, -0.169, 0.966]} scale={0.646} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_20_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-4.79, -1.244, 0.929]} rotation={[0.125, -0.169, -0.147]} scale={0.646} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_17_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-1.65, 0.413, -7.013]} rotation={[-0.894, 0.804, 1.796]} scale={0.482} />
      </group>
      <group position={[-80.797, 8.222, -12.752]} rotation={[-Math.PI / 2, 0, 0]} scale={0.937}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_50_Vertex_-_Steel_0'].geometry} material={materials['Vertex_-_Steel']} position={[0, 0, -0.07]} rotation={[0, 0, -0.743]} scale={[0.755, 0.755, 0.863]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_51_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[0, 0, 0.632]} rotation={[0, 0, -0.743]} scale={[0.755, 0.755, 0.863]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_52_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[0, 0, -0.143]} rotation={[0, 0, -0.743]} scale={[0.874, 0.874, 1]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_53_Vertex_-_Steel_0'].geometry} material={materials['Vertex_-_Steel']} position={[0, 0, -0.143]} rotation={[0, 0, -0.743]} scale={[0.874, 0.874, 1]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_54_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[0, 0, -0.805]} rotation={[0, 0, -0.743]} />
      </group>
      <group position={[-29.718, 4.754, -1.262]} rotation={[-Math.PI / 2, 0, 1.245]} scale={0.937}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_55_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[0, 0, -0.805]} rotation={[0, 0, -0.743]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_56_Vertex_-_Steel_0'].geometry} material={materials['Vertex_-_Steel']} position={[0, 0, -0.07]} rotation={[0, 0, -0.743]} scale={[0.755, 0.755, 0.863]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_57_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[0, 0, 0.632]} rotation={[0, 0, -0.743]} scale={[0.755, 0.755, 0.863]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_58_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[0, 0, -0.143]} rotation={[0, 0, -0.743]} scale={[0.874, 0.874, 1]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_59_Vertex_-_Steel_0'].geometry} material={materials['Vertex_-_Steel']} position={[0, 0, -0.143]} rotation={[0, 0, -0.743]} scale={[0.874, 0.874, 1]} />
      </group>
      <group position={[-129.818, 15.454, 23.726]} rotation={[-Math.PI / 2, 0, 3.139]} scale={0.928}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_353_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[1.932, 1.432, -5]} />
      </group>
      <group position={[-83.419, -1.284, 34.154]} rotation={[Math.PI, -1.043, Math.PI]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_94_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[3.507, 1.284, -35.329]} />
      </group>
      <group position={[-83.419, -1.284, 34.154]} rotation={[Math.PI, -1.043, Math.PI]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_363_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[3.507, 1.284, -35.329]} />
      </group>
      <group position={[-83.419, -1.284, 34.154]} rotation={[Math.PI, -1.043, Math.PI]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_364_Vertex_-_Steel_0'].geometry} material={materials['Vertex_-_Steel']} position={[3.507, 1.284, -35.329]} />
      </group>
      <group position={[-83.419, -1.284, 34.154]} rotation={[Math.PI, -1.043, Math.PI]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_365_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[3.507, 1.284, -35.329]} />
      </group>
      <group position={[-83.419, -1.284, 34.154]} rotation={[Math.PI, -1.043, Math.PI]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_366_Vertex_-_Steel_0'].geometry} material={materials['Vertex_-_Steel']} position={[3.507, 1.284, -35.329]} />
      </group>
      <group position={[-44.231, 0, -15.897]} rotation={[-Math.PI / 2, 0, 0]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_63_Vertex_-_Floor_Map_0'].geometry} material={materials['Vertex_-_Floor_Map']} position={[62.004, 44.219, 0]} />
      </group>
      <group position={[-125.347, 0, 68.515]} rotation={[-Math.PI / 2, 0, 0.086]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_431_Vertex_-_Floor_Map_0'].geometry} material={materials['Vertex_-_Floor_Map']} position={[-35.687, 12.818, 0]} />
      </group>
      <group position={[-86.438, 0, -58.838]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, 1.254]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_70_Vertex_-_Map_Road_0'].geometry} material={materials['Vertex_-_Map_Road']} position={[36.786, -36.067, 0]} />
      </group>
      <group position={[-45.46, 0, -8.713]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, 1.254]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_71_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[-4.192, 14.057, 0]} />
      </group>
      <group position={[-45.46, 0, -8.713]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, 1.254]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_72_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[-4.192, 14.057, 0]} />
      </group>
      <group position={[-0.901, 31.938, 70.518]} rotation={[-Math.PI / 2, 0, -0.073]}>
        <group position={[2.006, -27.572, -26.577]} rotation={[0, 0, -2.314]} scale={0.555}>
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_14_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[1.932, 1.432, -5]} />
        </group>
        <group position={[25.094, 5.454, -26.577]} rotation={[0, 0, -2.055]} scale={[-0.555, 0.555, 0.555]}>
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_21_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[1.932, 1.432, -5]} />
        </group>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_315_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[9.445, -8.249, -32.024]} rotation={[0, 0, 0.275]} scale={[0.591, 0.586, 0.396]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_316_Vertex_-_Map_Road_0'].geometry} material={materials['Vertex_-_Map_Road']} position={[9.445, -8.249, -32.024]} rotation={[0, 0, 0.275]} scale={[0.591, 0.586, 0.396]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_317_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[9.445, -8.249, -32.024]} rotation={[0, 0, 0.275]} scale={[0.591, 0.586, 0.396]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_318_Vertex_-_Map_Road_0'].geometry} material={materials['Vertex_-_Map_Road']} position={[9.512, -8.231, -32.024]} rotation={[0, 0, 0.275]} scale={[0.591, 0.586, 0.396]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_319_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[9.455, -8.26, -31.978]} rotation={[0, 0, 0.275]} scale={[0.591, 0.586, 0.396]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_320_Vertex_-_Map_Road_0'].geometry} material={materials['Vertex_-_Map_Road']} position={[9.455, -8.26, -31.978]} rotation={[0, 0, 0.275]} scale={[0.591, 0.586, 0.396]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_78_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[9.447, -8.247, -32.024]} rotation={[0, 0, 0.275]} scale={[0.591, 0.586, 0.396]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_400_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-8.533, -24.3, -26.577]} rotation={[0, 0, -2.314]} scale={0.555} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_80_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[5.381, -8.986, -31.992]} rotation={[0, 0, 0.596]} scale={0.893} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_397_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-14.297, -25.179, -26.575]} rotation={[0, 0, -2.314]} scale={0.555} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_396_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-2.947, -26.513, -26.575]} rotation={[0, 0, -2.314]} scale={0.555} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_398_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[7.744, -26.819, -26.575]} rotation={[0, 0, -2.314]} scale={0.555} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_401_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-15.583, -19.411, -26.575]} rotation={[0, 0, -2.314]} scale={0.555} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_399_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[15.766, -23.774, -26.575]} rotation={[0, 0, -2.314]} scale={0.555} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_408_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[26.158, -8.78, -26.575]} rotation={[0, 0, -2.055]} scale={[-0.555, 0.555, 0.555]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_409_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[26.32, -0.201, -26.575]} rotation={[0, 0, -2.055]} scale={[-0.555, 0.555, 0.555]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_410_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[17.345, 19.997, -26.575]} rotation={[0, 0, -2.055]} scale={[-0.555, 0.555, 0.555]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_411_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[22.428, 9.76, -26.575]} rotation={[0, 0, -2.055]} scale={[-0.555, 0.555, 0.555]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_412_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[18.461, 14.273, -26.577]} rotation={[0, 0, -2.055]} scale={[-0.555, 0.555, 0.555]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_413_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[11.482, 19.262, -26.575]} rotation={[0, 0, -2.055]} scale={[-0.555, 0.555, 0.555]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_82_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[8.734, -3.989, -31.992]} rotation={[0, 0, 1.318]} scale={[-0.893, 0.893, 0.893]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_256_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[9.454, -8.264, -27.477]} rotation={[0, 0, 0.275]} scale={[0.893, 0.893, 1.024]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_254_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[9.454, -8.264, -32.033]} rotation={[0, 0, 0.275]} scale={[0.893, 0.893, 1.024]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_259_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[11.674, 1.576, -27.877]} rotation={[0, 0, 0.275]} scale={[0.893, 0.893, 1.024]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_261_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[20.449, -8.045, -27.877]} rotation={[0, 0, 0.275]} scale={[0.893, 0.893, 1.024]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_263_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[19.877, -10.621, -27.877]} rotation={[0, 0, 0.275]} scale={[0.893, 0.893, 1.024]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_265_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[19.302, -13.197, -27.877]} rotation={[0, 0, 0.275]} scale={[0.893, 0.893, 1.024]} />
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_258_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[7.283, -18.115, -27.877]} rotation={[0, 0, 0.275]} scale={[0.893, 0.893, 1.024]} />
      </group>
      <group position={[-49.051, -0.157, -23.151]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, 0.053]}>
        <mesh geometry={nodes['Future_City_Island_-_01_-_Model_75_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[-0.601, -0.38, 0.22]} />
      </group>
      <group position={[45.865, -0.168, 20.874]} rotation={[-Math.PI / 2, 0, 0]} scale={0.505}>
        <group scale={1.203}>
          <group position={[0, 0, -13.036]}>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_443_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} />
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_443_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} />
          </group>
        </group>
        <group scale={1.203}>
          <group position={[0, 0, -13.036]}>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_449_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} />
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_449_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} />
          </group>
        </group>
        <group position={[0, 0, 47.865]} scale={[0.749, 0.749, 1.042]}>
          <group position={[0, 0, -47.534]}>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_451_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} />
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_451_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} />
          </group>
        </group>
        <group position={[0, 0, 47.865]} scale={[0.749, 0.749, 1.042]}>
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_452_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[0, 0, -47.534]} />
        </group>
        <group position={[0, 0, 26.178]}>
          <group position={[0, 0, -26.178]} rotation={[0.026, -0.046, -1.047]} scale={0.845}>
            <group position={[0, 0.074, 1.408]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_291_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 86.721, 57.229]} />
            </group>
          </group>
          <group rotation={[0, 0, 2.094]}>
            <group position={[0, 0, -0.185]} rotation={[2.142, 0, 0]} scale={0.684}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_278_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 34.988, 57.785]} />
            </group>
          </group>
          <group position={[0, 1.07, 0]} rotation={[0, 0, -Math.PI]}>
            <group position={[0, 0, -0.185]} rotation={[2.142, 0, 0]} scale={0.684}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_279_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 34.988, 57.785]} />
            </group>
          </group>
          <group rotation={[0, 0, -2.094]}>
            <group position={[0, 0, -0.185]} rotation={[2.142, 0, 0]} scale={0.684}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_280_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 34.988, 57.785]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[0, 0.049, Math.PI / 2]}>
            <group position={[0, 0.076, 1.571]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_282_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 75.866, 44.624]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[-0.042, 0.024, 2.619]}>
            <group position={[0, 0.076, 1.571]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_283_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 75.866, 44.624]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[-0.042, -0.024, -2.619]}>
            <group position={[0, 0.076, 1.571]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_284_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 75.866, 44.624]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[0, -0.049, -Math.PI / 2]}>
            <group position={[0, 0.076, 1.571]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_285_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 75.866, 44.624]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[-0.026, -0.046, -2.095]} scale={0.845}>
            <group position={[0, 0.074, 1.408]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_290_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 86.721, 57.229]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[0.026, 0.046, 1.047]} scale={0.845}>
            <group position={[0, 0.074, 1.408]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_287_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 86.721, 57.229]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[-0.026, 0.046, 2.095]} scale={0.845}>
            <group position={[0, 0.074, 1.408]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_288_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 86.721, 57.229]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[-0.053, 0, -Math.PI]} scale={0.845}>
            <group position={[0, 0.074, 1.408]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_289_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 86.721, 57.229]} />
            </group>
          </group>
          <group position={[1.107, -0.639, 0]} rotation={[0, 0, Math.PI / 3]}>
            <group position={[0, 0, -0.185]} rotation={[2.142, 0, 0]} scale={0.684}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_277_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 34.988, 57.785]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[0.053, 0, 0]} scale={0.845}>
            <group position={[0, 0.074, 1.408]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_276_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 86.721, 57.229]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[0.042, 0.024, 0.523]}>
            <group position={[0, 0.076, 1.571]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_275_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 75.866, 44.624]} />
            </group>
          </group>
          <group position={[-1.125, -0.65, 0]} rotation={[0, 0, -Math.PI / 3]}>
            <group position={[0, 0, -0.185]} rotation={[2.142, 0, 0]} scale={0.684}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_281_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 34.988, 57.785]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[0.042, -0.024, -0.523]}>
            <group position={[0, 0.076, 1.571]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_286_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 75.866, 44.624]} />
            </group>
          </group>
          <group position={[0, 0, -0.185]} rotation={[2.142, 0, 0]} scale={0.684}>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_274_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 34.988, 57.785]} />
          </group>
        </group>
      </group>
      <group position={[-73.051, 0.234, -93.953]} rotation={[-Math.PI / 2, 0, 0]} scale={0.422}>
        <group position={[0, 0, 26.178]}>
          <group position={[0, 0, -26.178]} rotation={[0, 0.417, Math.PI / 2]}>
            <group position={[0, 5.437, 12.283]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_292_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 79.886, 46.934]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[-0.567, 0, -Math.PI]} scale={0.845}>
            <group position={[0, 11.915, 18.705]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_293_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 96.319, 60.962]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[0.371, 0, 0]}>
            <group position={[0, 12.931, 33.191]} rotation={[2.142, 0, 0]} scale={0.684}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_294_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 48.683, 67.734]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[-0.308, -0.484, -2.171]} scale={0.845}>
            <group position={[0, 11.915, 18.705]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_295_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 96.319, 60.962]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[0.308, -0.484, -0.971]} scale={0.845}>
            <group position={[0, 11.915, 18.705]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_296_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 96.319, 60.962]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[0.567, 0, 0]} scale={0.845}>
            <group position={[0, 11.915, 18.705]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_297_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 96.319, 60.962]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[0.308, 0.484, 0.971]} scale={0.845}>
            <group position={[0, 11.915, 18.705]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_298_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 96.319, 60.962]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[-0.308, 0.484, 2.171]} scale={0.845}>
            <group position={[0, 11.915, 18.705]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_299_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 96.319, 60.962]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[-0.366, 0.204, 2.656]}>
            <group position={[0, 5.437, 12.283]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_300_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 79.886, 46.934]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[-0.366, -0.204, -2.656]}>
            <group position={[0, 5.437, 12.283]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_301_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 79.886, 46.934]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[0, -0.417, -Math.PI / 2]}>
            <group position={[0, 5.437, 12.283]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_302_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 79.886, 46.934]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[0.366, -0.204, -0.486]}>
            <group position={[0, 5.437, 12.283]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_303_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 79.886, 46.934]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[0.366, 0.204, 0.486]}>
            <group position={[0, 5.437, 12.283]} rotation={[2.203, 0, 0]} scale={0.602}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_304_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 79.886, 46.934]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[0.192, 0.32, 1.016]}>
            <group position={[0, 12.931, 33.191]} rotation={[2.142, 0, 0]} scale={0.684}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_305_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 48.683, 67.734]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[-0.192, 0.32, 2.126]}>
            <group position={[0, 12.931, 33.191]} rotation={[2.142, 0, 0]} scale={0.684}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_306_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 48.683, 67.734]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[-0.371, 0, -Math.PI]}>
            <group position={[0, 12.931, 33.191]} rotation={[2.142, 0, 0]} scale={0.684}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_307_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 48.683, 67.734]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[-0.192, -0.32, -2.126]}>
            <group position={[0, 12.931, 33.191]} rotation={[2.142, 0, 0]} scale={0.684}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_308_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 48.683, 67.734]} />
            </group>
          </group>
          <group position={[0, 0, -26.178]} rotation={[0.192, -0.32, -1.016]}>
            <group position={[0, 12.931, 33.191]} rotation={[2.142, 0, 0]} scale={0.684}>
              <mesh geometry={nodes['Future_City_Island_-_01_-_Model_309_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_2']} position={[0, 48.683, 67.734]} />
            </group>
          </group>
        </group>
        <group scale={1.203}>
          <group position={[0, 0, -13.036]}>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_453_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} />
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_453_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} />
          </group>
        </group>
        <group scale={1.203}>
          <group position={[0, 0, -13.036]}>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_454_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} />
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_454_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} />
          </group>
        </group>
        <group position={[0, 0, 47.865]} scale={[0.749, 0.749, 1.042]}>
          <group position={[0, 0, -47.534]}>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_455_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} />
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_455_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} />
          </group>
        </group>
        <group position={[0, 0, 47.865]} scale={[0.749, 0.749, 1.042]}>
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_456_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[0, 0, -47.534]} />
        </group>
      </group>
      <group position={[34.53, 10.739, -27.047]} rotation={[-Math.PI / 2, 0, 1.114]} scale={1.277}>
        <group position={[0.036, -12.306, -13.368]} rotation={[0, 0, -2.768]} scale={[5.422, 6.526, 4.009]}>
          <group position={[-13.67, -8.862, 3.15]}>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_314_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_3']} />
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_314_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} />
          </group>
        </group>
        <group position={[0.28, 12.313, -13.368]} rotation={[0, 0, -2.855]} scale={[5.422, 6.526, 4.009]}>
          <group position={[-13.67, -8.862, 3.15]}>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_375_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_3']} />
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_375_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} />
          </group>
        </group>
        <group position={[0, -0.122, 0]} rotation={[0, 0, -0.021]}>
          <group position={[0, 0.017, -0.246]}>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_418_Vertex_-_Metal__0'].geometry} material={materials['Vertex_-_Metal']} position={[0.068, -0.104, -7.857]} />
          </group>
          <group position={[0, 0.017, -0.246]}>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_417_Vertex_-_Metal__0'].geometry} material={materials['Vertex_-_Metal']} position={[0.068, -0.104, -7.857]} />
          </group>
          <group position={[0.153, 0.002, 11.033]}>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_271_Vertex_-_Metal__0'].geometry} material={materials['Vertex_-_Metal']} position={[6.583, -7.983, 2.992]} />
          </group>
          <group position={[0, 0.017, -0.246]}>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_416_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[0.068, -0.104, -7.857]} />
          </group>
          <group position={[0, 0.017, -0.213]}>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_173_Vertex_-_Map_Floor_02_0'].geometry} material={materials['Vertex_-_Map_Floor_02_1']} position={[0.068, -0.104, -7.857]} />
          </group>
          <group position={[0, 0.017, -0.213]}>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_311_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_4']} position={[0.068, -0.104, -7.857]} />
          </group>
          <group position={[-0.001, 0.028, -1.557]} scale={[35.287, 35.287, 34.838]}>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_333_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-2.336, -3.128, 0]} />
          </group>
          <group position={[0, 0.017, -8.368]} scale={0.508}>
            <group position={[0, 0, 13.978]}>
              <group position={[-0.001, 0.021, -0.584]} scale={[69.4, 69.4, 68.516]}>
                <group position={[-2.336, -3.128, 0]}>
                  <mesh geometry={nodes['Future_City_Island_-_01_-_Model_98_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_5']} />
                  <mesh geometry={nodes['Future_City_Island_-_01_-_Model_98_Vertex_-_Map_04_0_1'].geometry} material={materials['Vertex_-_Map_04_3']} />
                  <mesh geometry={nodes['Future_City_Island_-_01_-_Model_98_Vertex_-_Map_04_0_2'].geometry} material={materials['Vertex_-_Map_04_3']} />
                  <mesh geometry={nodes['Future_City_Island_-_01_-_Model_98_Vertex_-_Map_04_0_3'].geometry} material={materials['Vertex_-_Map_04_3']} />
                  <mesh geometry={nodes['Future_City_Island_-_01_-_Model_98_Vertex_-_Map_04_0_4'].geometry} material={materials['Vertex_-_Map_04_3']} />
                </group>
              </group>
              <group scale={2.62}>
                <group position={[0, 0, -9.042]}>
                  <mesh geometry={nodes['Future_City_Island_-_01_-_Model_97_Vertex_-_Map_Wall_0'].geometry} material={materials['Vertex_-_Map_Wall']} />
                  <mesh geometry={nodes['Future_City_Island_-_01_-_Model_97_Vertex_-_Map_Wall_0_1'].geometry} material={materials['Vertex_-_Map_Wall']} />
                  <mesh geometry={nodes['Future_City_Island_-_01_-_Model_97_Vertex_-_Map_Wall_0_2'].geometry} material={materials['Vertex_-_Map_Wall']} />
                  <mesh geometry={nodes['Future_City_Island_-_01_-_Model_97_Vertex_-_Map_Wall_0_3'].geometry} material={materials['Vertex_-_Map_Wall']} />
                  <mesh geometry={nodes['Future_City_Island_-_01_-_Model_97_Vertex_-_Map_Wall_0_4'].geometry} material={materials['Vertex_-_Map_Wall']} />
                  <mesh geometry={nodes['Future_City_Island_-_01_-_Model_97_Vertex_-_Map_Wall_0_5'].geometry} material={materials['Vertex_-_Map_Wall']} />
                </group>
              </group>
            </group>
            <mesh geometry={nodes['Future_City_Island_-_01_-_Model_95_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_3']} scale={[0.935, 0.935, 0.816]} />
          </group>
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_96_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_3']} position={[-0.001, 0.028, -8.133]} />
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_444_Vertex_-_Metal__0'].geometry} material={materials['Vertex_-_Metal']} position={[8.988, 12.318, 13.187]} rotation={[-0.08, 0.166, -0.022]} scale={1.082} />
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_446_Vertex_-_Metal__0'].geometry} material={materials['Vertex_-_Metal']} position={[-8.711, 12.552, 13.168]} rotation={[-0.08, -0.166, 0.022]} scale={[-1.082, 1.082, 1.082]} />
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_448_Vertex_-_Metal__0'].geometry} material={materials['Vertex_-_Metal']} position={[9.046, -12.556, 13.16]} rotation={[0.08, 0.166, -3.12]} scale={[-1.082, 1.082, 1.082]} />
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_450_Vertex_-_Metal__0'].geometry} material={materials['Vertex_-_Metal']} position={[-8.722, -12.557, 13.172]} rotation={[0.08, -0.166, 3.12]} scale={1.082} />
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_16_Vertex_-_Metal__0'].geometry} material={materials['Vertex_-_Metal']} position={[4.577, -10.32, -13.368]} rotation={[0.085, 0.165, -1.043]} scale={[-0.897, 0.897, 0.897]} />
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_22_Vertex_-_Metal__0'].geometry} material={materials['Vertex_-_Metal']} position={[-4.288, -10.32, -13.368]} rotation={[0.085, -0.165, 1.043]} scale={0.897} />
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_23_Vertex_-_Metal__0'].geometry} material={materials['Vertex_-_Metal']} position={[4.577, 10.069, -13.368]} rotation={[-0.085, 0.165, -2.098]} scale={0.897} />
          <mesh geometry={nodes['Future_City_Island_-_01_-_Model_24_Vertex_-_Metal__0'].geometry} material={materials['Vertex_-_Metal']} position={[-4.288, 10.32, -13.368]} rotation={[-0.085, -0.165, 2.098]} scale={[-0.897, 0.897, 0.897]} />
        </group>
      </group>
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_64_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[-49.652, 0.078, -22.771]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_415_Vertex_-_Grass_Map_0'].geometry} material={materials['Vertex_-_Grass_Map']} position={[-49.652, 0, -22.77]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_25_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[-79.699, 0, -143.209]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_26_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[-34.313, 0, 57.907]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_28_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[-74.018, 0, -93.746]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_29_Vertex_-_Floor_Map_0'].geometry} material={materials['Vertex_-_Floor_Map']} position={[-74.018, 0, -93.746]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_30_Vertex_-_Map_Floor_02_0'].geometry} material={materials['Vertex_-_Map_Floor_02']} position={[-34.313, 0, 57.907]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_31_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[45.596, 0, 20.529]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_32_Vertex_-_Map_Floor_02_0'].geometry} material={materials['Vertex_-_Map_Floor_02']} position={[45.596, 0, 20.529]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_33_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[34.74, 0, -26.995]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_34_Vertex_-_Map_Floor_06_0'].geometry} material={materials['Vertex_-_Map_Floor_06']} position={[34.74, 0, -26.995]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_00__0'].geometry} material={materials['Future_City_Island_-_01_-_Model_00__0']} position={[-73.977, 0.078, -93.769]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_257_Vertex_-_Floor_Map_0'].geometry} material={materials['Vertex_-_Floor_Map']} position={[-26.661, 0, 98.672]} rotation={[-Math.PI / 2, 0, 1.307]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_260_Vertex_-_Floor_Map_0'].geometry} material={materials['Vertex_-_Floor_Map']} position={[-109.17, 0.128, -65.125]} rotation={[-Math.PI / 2, 0, 1.307]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_262_Vertex_-_Floor_Map_0'].geometry} material={materials['Vertex_-_Floor_Map']} position={[-37.311, 0, -14.663]} rotation={[-Math.PI / 2, 0, 1.307]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_01_Vertex_-_Floor_Map_0'].geometry} material={materials['Vertex_-_Floor_Map']} position={[-87.31, 0, -4.964]} rotation={[-Math.PI / 2, 0, 0.135]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_02_Vertex_-_Floor_Map_0'].geometry} material={materials['Vertex_-_Floor_Map']} position={[-25.726, 0, 7.654]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_264_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[-126.513, 0.078, -84.471]} rotation={[-Math.PI / 2, 0, 0.082]} scale={[0.844, 0.844, 0.643]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_39_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[28.914, 0, -8.59]} rotation={[-Math.PI / 2, 0, 0]} scale={0.904} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_40_Vertex_-_Map_Floor_02_0'].geometry} material={materials['Vertex_-_Map_Floor_02']} position={[28.914, 0, -8.59]} rotation={[-Math.PI / 2, 0, 0]} scale={0.904} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_41_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[-93.558, 0.013, -73.779]} rotation={[-Math.PI / 2, 0, 0]} scale={0.643} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_42_Vertex_-_Floor_Map_0'].geometry} material={materials['Vertex_-_Floor_Map']} position={[-93.558, 0.013, -73.779]} rotation={[-Math.PI / 2, 0, 0]} scale={0.643} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_43_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[-111.318, 0.005, -73.689]} rotation={[-Math.PI / 2, 0, 0]} scale={0.73} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_44_Vertex_-_Floor_Map_0'].geometry} material={materials['Vertex_-_Floor_Map']} position={[-111.318, 0.005, -73.689]} rotation={[-Math.PI / 2, 0, 0]} scale={0.73} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_03_Vertex_-_Floor_Map_0'].geometry} material={materials['Vertex_-_Floor_Map']} position={[-93.215, 0.078, -74.45]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_66_Vertex_-_Floor_Map_0'].geometry} material={materials['Vertex_-_Floor_Map']} position={[-79.495, 0, -143.668]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, 0.835]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_384_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-120.595, 8.289, -90.943]} rotation={[-Math.PI / 2, 0, 0.368]} scale={[0.893, 0.893, 0.158]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_77_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-101.665, 15.843, 6.381]} rotation={[-Math.PI / 2, 0, 0.63]} scale={[0.653, 0.653, 0.338]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_385_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-130.258, -0.084, 41.205]} rotation={[-Math.PI / 2, 0, 0.368]} scale={0.893} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_312_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-120.595, 8.289, -90.943]} rotation={[-Math.PI / 2, 0, 0.368]} scale={[0.893, 0.893, 0.158]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_313_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[-120.595, 8.289, -90.943]} rotation={[-Math.PI / 2, 0, 0.368]} scale={[0.893, 0.893, 0.158]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_387_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[-18.85, -0.343, 18.989]} rotation={[-Math.PI / 2, 0, -0.136]} scale={[0.687, 0.687, 0.51]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_389_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[-26.522, -0.343, 22.358]} rotation={[-Math.PI / 2, 0, -0.279]} scale={[0.687, 0.687, 0.443]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_388_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[-22.053, -0.343, 14.907]} rotation={[-Math.PI / 2, 0, 0.108]} scale={[0.893, 0.893, 0.647]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_321_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-22.053, -0.343, 14.907]} rotation={[-Math.PI / 2, 0, 0.108]} scale={[0.893, 0.893, 0.647]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_322_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-18.85, -0.343, 18.989]} rotation={[-Math.PI / 2, 0, -0.136]} scale={[0.687, 0.687, 0.51]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_310_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-26.522, -0.343, 22.358]} rotation={[-Math.PI / 2, 0, -0.279]} scale={[0.687, 0.687, 0.443]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_390_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-139.209, 15.633, -6.026]} rotation={[-Math.PI / 2, 0, 3.139]} scale={0.893} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_391_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-144.667, 15.633, 1.76]} rotation={[-Math.PI / 2, 0, 3.139]} scale={0.893} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_392_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-122.698, 15.633, 29.726]} rotation={[-Math.PI / 2, 0, 3.139]} scale={0.893} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_393_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-110.376, 15.633, 35.952]} rotation={[-Math.PI / 2, 0, 3.139]} scale={0.893} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_394_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-133.935, 15.633, 16.693]} rotation={[-Math.PI / 2, 0, 3.139]} scale={0.893} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_395_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_1']} position={[-137.368, 15.631, 7.656]} rotation={[-Math.PI / 2, 0, 3.139]} scale={0.893} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_11_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-121.097, 0.45, 54.3]} rotation={[-Math.PI / 2, 0, -0.382]} scale={[1.009, 0.952, 1.162]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_12__0'].geometry} material={materials['Future_City_Island_-_01_-_Model_00__0']} position={[-121.097, 0.45, 54.3]} rotation={[-Math.PI / 2, 0, -0.382]} scale={[1.009, 0.952, 1.162]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_266_Vertex_-_Map_Road_0'].geometry} material={materials['Vertex_-_Map_Road']} position={[-52.006, 0, -31.837]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_04_Vertex_-_Map_Road_0'].geometry} material={materials['Vertex_-_Map_Road']} position={[-39.346, 0, -46.201]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_08_Vertex_-_Steel_0'].geometry} material={materials['Vertex_-_Steel']} position={[-89.02, 7.825, -3.347]} rotation={[-Math.PI / 2, 0, 0.135]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_09_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-89.02, 7.825, -3.347]} rotation={[-Math.PI / 2, 0, 0.135]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_90_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[-79.387, 0.252, -13.961]} rotation={[-Math.PI / 2, 0, 0.052]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_344_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-79.387, 0.252, -13.961]} rotation={[-Math.PI / 2, 0, 0.052]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_428_Vertex_-_Steel_0'].geometry} material={materials['Vertex_-_Steel']} position={[-79.387, 0.252, -13.961]} rotation={[-Math.PI / 2, 0, 0.052]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_345_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-63.146, 18.175, 25.207]} rotation={[-Math.PI / 2, 0, 0.987]} scale={[1.88, 1.88, 0.516]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_346_Vertex_-_Steel_0'].geometry} material={materials['Vertex_-_Steel']} position={[-63.146, 18.175, 25.207]} rotation={[-Math.PI / 2, 0, 0.987]} scale={[1.88, 1.88, 0.516]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_347_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-120.595, 8.289, -90.943]} rotation={[-Math.PI / 2, 0, 0.368]} scale={[0.893, 0.893, 0.158]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_429_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[-120.595, 8.289, -90.943]} rotation={[-Math.PI / 2, 0, 0.368]} scale={[0.893, 0.893, 0.158]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_91_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[-29.711, 0.252, -1.174]} rotation={[-Math.PI / 2, 0, 1.428]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_348_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-29.711, 0.252, -1.174]} rotation={[-Math.PI / 2, 0, 1.428]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_430_Vertex_-_Steel_0'].geometry} material={materials['Vertex_-_Steel']} position={[-29.711, 0.252, -1.174]} rotation={[-Math.PI / 2, 0, 1.428]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_349_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-79.387, 0.252, -13.961]} rotation={[-Math.PI / 2, 0, 0.052]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_350_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-137.368, 15.573, 7.656]} rotation={[-Math.PI / 2, 0, 3.139]} scale={0.893} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_351_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-139.209, 15.589, -6.026]} rotation={[-Math.PI / 2, 0, 3.139]} scale={0.893} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_352_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-133.935, 15.49, 16.693]} rotation={[-Math.PI / 2, 0, 3.139]} scale={0.893} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_354_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-122.698, 15.546, 29.726]} rotation={[-Math.PI / 2, 0, 3.139]} scale={[0.923, 0.923, 0.893]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_92_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[-58.158, 0, 102.201]} rotation={[Math.PI, -1.043, Math.PI]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_93_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04_0']} position={[-49.247, 0, 79.181]} rotation={[Math.PI, -1.043, Math.PI]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_355_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-58.158, 0, 102.201]} rotation={[Math.PI, -1.043, Math.PI]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_356_Vertex_-_Steel_0'].geometry} material={materials['Vertex_-_Steel']} position={[-58.158, 0, 102.201]} rotation={[Math.PI, -1.043, Math.PI]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_357_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-58.158, 0, 102.201]} rotation={[Math.PI, -1.043, Math.PI]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_358_Vertex_-_Steel_0'].geometry} material={materials['Vertex_-_Steel']} position={[-58.158, 0, 102.201]} rotation={[Math.PI, -1.043, Math.PI]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_359_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-49.247, 0, 79.181]} rotation={[Math.PI, -1.043, Math.PI]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_360_Vertex_-_Steel_0'].geometry} material={materials['Vertex_-_Steel']} position={[-49.247, 0, 79.181]} rotation={[Math.PI, -1.043, Math.PI]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_361_Vertex_-_Map_05_0'].geometry} material={materials['Vertex_-_Map_05']} position={[-49.247, 0, 79.181]} rotation={[Math.PI, -1.043, Math.PI]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_362_Vertex_-_Steel_0'].geometry} material={materials['Vertex_-_Steel']} position={[-49.247, 0, 79.181]} rotation={[Math.PI, -1.043, Math.PI]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_35_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[7.887, 0.078, 79.332]} rotation={[-Math.PI / 2, 0, 0.136]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_38_Vertex_-_Map_Floor_02_0'].geometry} material={materials['Vertex_-_Map_Floor_02_0']} position={[25.03, 0, 91.965]} rotation={[-Math.PI / 2, 0, 0.136]} scale={[1.385, 1.385, 1]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_37_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[25.03, 0, 91.965]} rotation={[-Math.PI / 2, 0, 0.136]} scale={[1.385, 1.385, 1]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_36_Vertex_-_Map_Floor_02_0'].geometry} material={materials['Vertex_-_Map_Floor_02']} position={[7.887, 0.078, 79.332]} rotation={[-Math.PI / 2, 0, 0.136]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_60_Vertex_-_Floor_Map_0'].geometry} material={materials['Vertex_-_Floor_Map']} position={[-26.902, 0.126, 48.551]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_61_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[-26.902, 0.126, 48.551]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_10_Vertex_-_Map_04_0'].geometry} material={materials['Vertex_-_Map_04']} position={[-39.346, 0, -46.201]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_62_Vertex_-_Map_Floor_02_0'].geometry} material={materials['Vertex_-_Map_Floor_02']} position={[-73.843, 0, -93.646]} rotation={[-Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_73_Vertex_-_Map_Roaddd_0'].geometry} material={materials['Vertex_-_Map_Roaddd']} position={[-49.652, 0.078, -22.771]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_74_Vertex_-_Map_Ground_0'].geometry} material={materials['Vertex_-_Map_Ground']} position={[-49.652, 0.078, -22.771]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, 0]} />
      <mesh geometry={nodes['Vertex_-_Ocean__0'].geometry} material={materials['Future_City_Island_-_01_-_Model_00__0']} position={[-19.512, 0.013, -51.089]} rotation={[-Math.PI / 2, 0, 0]} scale={[1.435, 1.435, 1.501]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_76_Vertex_-_Map_Ground_0'].geometry} material={materials['Vertex_-_Map_Ground']} position={[-49.652, 0.078, -22.771]} rotation={[-Math.PI / 2, 0, 0]} scale={[1, 1, 0]} />
      <mesh geometry={nodes['Future_City_Island_-_01_-_Model_380_Material_#22_0'].geometry} material={materials.Material_22} position={[-127.143, -0.11, 9.416]} rotation={[-Math.PI / 2, 0, 0.368]} scale={[0.893, 0.893, 1.881]} />
    </group>
  )
}

useGLTF.preload('/models/island/scene.gltf')
