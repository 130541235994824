import React from 'react'
import { FaFacebook, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa'
import './footer.css'

export default function Footer() {
    const QUICK_LINKS = ['Ai Innovation Blog', 'Futuristic White Papers', 'Case Studies', 'Media Outreach', 'Privacy Policy', 'Terms & Conditions', 'Contact Us', 'Company Brand', 'Registrations & Partners', 'Company']
    const TEAM = ['Philip C. Roark', 'Karla Ladron de Guevara', 'Ignacio Rojas', 'Ronnie Milikowsky', 'Roger Mahoney', 'Alejandro Bravo']
    const PARTNERSHIP = ['European Leadership', 'Doubleklick Designs', 'Multiplica', 'Hogan', 'Nearsure']

    return (
        <>
            <div className='col mt-4'></div>
            <div className='contianer-fluid bg-white py-5'>
                <div className='container'>
                    <div className='row footer-content'>
                        <div className='col-12 col-md-3 my-4 mt-md-0 px-3 px-md-0 my-2'>
                            <div className="logo-wrapper w-100 px-5">
                                <img src="/assets/images/logo.svg" className="image-fluid" />
                            </div>
                        </div>

                        <div className='col-12 col-md-3 ps-3'>

                            <div className='d-flex flex-column'>
                                <div className='f-18 mb-2 bolder text-black'>Quick Links</div>
                                {QUICK_LINKS.map(link =>
                                    <div key={link} className='f-16 text-gray cursor-pointer'>{link}</div>
                                )}
                            </div>

                        </div>
                        <div className='col-12 col-md-3'>
                            <div className='d-flex flex-column'>
                                <div className='f-18 mb-2 bolder text-black'>Team</div>
                                {TEAM.map(member =>
                                    <div key={member} className='f-16 text-gray cursor-pointer'>{member}</div>
                                )}
                            </div>
                        </div>
                        <div className='col-12 col-md-3'>
                            <div className='d-flex flex-column'>
                                <div className='f-18 mb-2 bolder text-black'>Partnership</div>
                                {PARTNERSHIP.map(partner =>
                                    <div key={partner} className='f-16 text-gray cursor-pointer'>{partner}</div>
                                )}
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        </>
    )
}
