import React from "react";
import Header from "../../../../components/layout/header";
import Footer from "../../../../components/layout/footer";
import Advertisement from "../../../../components/Advertisement/Advertisement";

const ApplicationSuccess = () => {
  return (
    <div className="hide-scroll">
      <div className="vh-100 d-flex flex-column">
        <Header />
        <div className="container flex-grow-1">
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <div className="f-32">Thank You for applying</div>
            <div className="f-24">We are reviewing your application</div>
          </div>
        </div>
      </div>
      <Advertisement />
      <Footer />
    </div>
  );
};

export default ApplicationSuccess;
