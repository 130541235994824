import { useState } from 'react';
import { useAccordionButton } from 'react-bootstrap';
import { GoPlus } from "react-icons/go";
import './customtoggle.css';

function CustomToggle({ children, eventKey }) {
  const [isOpen, setIsOpen] = useState(false);
  
  const decoratedOnClick = useAccordionButton(eventKey, () => setIsOpen(!isOpen));

  return (
    <div
      onClick={decoratedOnClick}
      style={{
        background: 'none',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '3rem',
        padding: '10px',
      }}
      className={`bg-light w-100 mb-3 cursor-pointer f-16 bold`}
    >
      <div>{children}</div>
      <div style={{ marginRight: '8px' }} className={`toggle-icon ${isOpen ? 'rotate' : ''} f-20`}>
        <GoPlus />
      </div>
    </div>
  );
}

export default CustomToggle;
